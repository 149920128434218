import getApiClient from "common/utils/api2/getApiClient";
import React, { useEffect, useMemo, useState } from "react";

export type Absence = {
  id?: number;
  absent_person_id: number;
  substitute_person_id: number;
  begin_date: string;
  end_date: string;
  description?: string;
  created_at?: string;
  updated_at?: string;
  active?: boolean;
};

// Context to store absences
const AbsencesContext = React.createContext<{
  loading: boolean;
  absences?: Absence[];
  setAbsences: React.Dispatch<React.SetStateAction<Absence[] | undefined>>;
}>({
  loading: false,
  absences: undefined,
  setAbsences: () => {},
});

// Provider for the absences context
export const AbsencesProvider: React.FC = ({ children }) => {
  const [absences, setAbsences] = useState<Absence[]>();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAbsences = async () => {
      setLoading(true);
      const response = await getApiClient().listAbsences().get();
      setAbsences(response);
      setLoading(false);
    };

    fetchAbsences();
  }, []);

  return (
    <AbsencesContext.Provider value={{ loading, absences, setAbsences }}>
      {children}
    </AbsencesContext.Provider>
  );
};

// Hook to access absences
const useAbsences = () => {
  const context = React.useContext(AbsencesContext);

  if (context === undefined) {
    throw new Error("useAbsences must be used within a AbsencesProvider");
  }

  const insertAbsence = (absence: Absence) => {
    context.setAbsences(absences => [absence, ...(absences || [])]);
  };

  const updateAbsence = (absence: Absence) => {
    context.setAbsences(prevVal =>
      prevVal?.map(a => (a.id === absence.id ? absence : a)),
    );
  };

  const removeAbsence = (id: number) => {
    context.setAbsences(prevVal => prevVal?.filter(a => a.id !== id));
  };

  return {
    absences: context.absences,
    loading: context.loading,
    insertAbsence,
    updateAbsence,
    removeAbsence,
  };
};

// Hook to access a single absence
export const useAbsence = (id: string) => {
  const { absences } = useAbsences();
  const absence = useMemo(
    () => absences?.find(a => a.id === +id),
    [absences, id],
  );

  return absence;
};

export default useAbsences;
