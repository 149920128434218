import React from "react";

import { FieldInputProps } from "react-final-form";
import { filterItems, getSiteDomains } from "./helpers";
import { FormattedMessage } from "react-intl";
import { DomainCheckbox } from "./DomainCheckbox";
import {
  DomainCheckboxContainer,
  DomainListSelectedHeader,
  DomainListSeparator,
  Placeholder,
} from "./ManageDomainsModal.styles";
import { splitBy } from "common/utils/general";
import { Option } from "common/models/domain";
import { User } from "common/models/user";

export type DomainListProps = {
  name: string;
  searchText: string;
  input: FieldInputProps<string[]>;
  user: Partial<User>;
};

type CheckboxListProps = {
  options: Option[];
  input: FieldInputProps<string[]>;
  name: string;
  user: Partial<User>;
};
const CheckboxList = ({ options, input, name, user }: CheckboxListProps) => (
  <>
    {options?.map(option => (
      <DomainCheckbox
        key={option.value}
        input={input}
        name={name}
        option={option}
        user={user}
      />
    ))}
  </>
);

export const DomainList = ({
  input,
  searchText = "",
  name,
  user,
}: DomainListProps) => {
  const siteDomains = getSiteDomains();
  const filteredItems = filterItems(siteDomains, searchText);
  const isChecked = (option: Option) => input.value.includes(option.value);
  const [checkedBoxes, unCheckedBoxes] = splitBy(filteredItems, isChecked);

  return (
    <>
      <DomainListSelectedHeader>
        <FormattedMessage
          id="people.selected_amount"
          values={{
            amount: input.value.length,
          }}
        />
      </DomainListSelectedHeader>
      <DomainCheckboxContainer>
        {filteredItems.length ? (
          <>
            <CheckboxList
              options={checkedBoxes}
              input={input}
              name={name}
              user={user}
            />
            <DomainListSeparator />
            <CheckboxList
              options={unCheckedBoxes}
              input={input}
              name={name}
              user={user}
            />
          </>
        ) : (
          <Placeholder>
            <FormattedMessage id="people.no_domains_found" />
          </Placeholder>
        )}
      </DomainCheckboxContainer>
    </>
  );
};
