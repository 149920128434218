import { isFunction } from "lodash";
import React, { RefObject } from "react";
import { Spinner } from "@netmedi/design-system";
import cn from "classnames";
import { FormattedMessage } from "react-intl";
import StyledDataTable, {
  NoData,
  HeaderContent,
  SortArrows,
} from "./DataTable.styles";

const renderCell = (
  row: Props["data"][number],
  col: Props["columns"][number],
  rowHrefFunc: Props["rowHref"],
) => {
  const val = isFunction(col.value) ? col.value(row) : col.value;
  return rowHrefFunc ? <a href={rowHrefFunc(row)}>{val}</a> : val;
};

/** DataTable is a component used for displaying tabular data or listing objects such as
users. */
function DataTable(props: Props) {
  const {
    id,
    columns,
    data,
    classes,
    loading,
    pageLoading = false,
    emptyMessage,
    hideColumnNames,
    onClick,
    rowHref,
    onHeaderClick,
    className,
    sortArrows,
    excludeSort,
    ref,
    separateBorders = false,
    keyFunc,
    ...rest
  } = props;

  if (loading) return <Spinner />;

  if (!data.length) {
    return (
      <NoData>
        {emptyMessage && <FormattedMessage id={emptyMessage as string} />}
      </NoData>
    );
  }

  return (
    <StyledDataTable
      {...rest}
      ref={ref as RefObject<HTMLTableElement>}
      id={id}
      className={cn(className)}
      pageLoading={!!pageLoading}
      separateBorders={separateBorders}
    >
      {!hideColumnNames && (
        <thead>
          <tr>
            {columns.map((col, i) => (
              <th
                key={i}
                onClick={e =>
                  onHeaderClick && onHeaderClick(e, col.name as string, col)
                }
              >
                <HeaderContent>
                  {col.format === false ? (
                    <span>{col.name}</span>
                  ) : (
                    <FormattedMessage
                      id={col.name as string}
                      tagName="strong"
                    />
                  )}
                  {sortArrows &&
                    !excludeSort?.includes(col.name.toString()) && (
                      <SortArrows
                        sortArrows={sortArrows as "both" | "down" | "up"}
                        sortDirection={col.sort}
                      />
                    )}
                </HeaderContent>
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody>
        {data.map((row, i) => (
          <tr
            key={keyFunc ? keyFunc(row) : row.id ?? i}
            className={cn(isFunction(classes) && classes(row))}
            onClick={() => onClick && onClick(row)}
          >
            {columns.map((col, j) => (
              <td key={`${row.id || i}_${j}`}>
                {renderCell(row, col, rowHref)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledDataTable>
  );
}

export type Column = {
  name: string;
  key?: string; // Used for sorting
  value?: string | number | React.ReactNode | ((row: any) => string) | null;
  /** Whether the name of the column is a localisation key for a formatted message */
  format?: boolean;
  sort?: "asc" | "desc";
};
export type ColumnWithJsxName = Omit<Column, "name"> & {
  name: string | JSX.Element;
};

export type Props = JSX.IntrinsicElements["table"] & {
  /** Includes column names (name) and way to access wanted data (value) */
  columns: Array<ColumnWithJsxName>;
  /** Data to be shown in the table */
  data: Array<any>;
  className?: string;
  classes?: (row: any) => string;
  onClick?: (row: any) => void;
  onHeaderClick?: (
    e: React.MouseEvent<HTMLTableCellElement>,
    name: string,
    element: ColumnWithJsxName,
  ) => void;
  /** Can be used instead of onClick-prop to achieve link-alike functionality. Should be a function returning URL as a string. */
  rowHref?: (row: any) => string;
  /** Whether to show the loading spinner */
  loading?: boolean;
  /** Whether to show the table as loading (grey) */
  pageLoading?: boolean;
  emptyMessage?: string;
  /** Whether to hide the column names */
  hideColumnNames?: boolean;
  /** Sort arrows next to the headers */
  sortArrows?: "up" | "down" | "both";
  excludeSort?: Array<string>;
  separateBorders?: boolean;
  /** To generate key values for individual rows */
  keyFunc?: (row: any) => string | number;
};

export default DataTable;
