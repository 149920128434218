import React from "react";
import { Spinner } from "@netmedi/design-system";
import { ModalLoader } from "./Modal.styles";

export type LoaderProps = {
  type: "loader";
  title?: string | React.ReactElement;
  className?: string;
};

const Loader = ({ title, className }: Omit<LoaderProps, "type">) => (
  <ModalLoader className={className} onClick={(e: any) => e.stopPropagation()}>
    {title && <p>{title}</p>}
    <Spinner noPadding={true} />
  </ModalLoader>
);

export default Loader;
