import { addFlash } from "common/actions/flash";
import { addInfoBox, dismissInfoBox } from "shared/actions/infoBox";
import { dismissModal, openModal } from "common/actions/modal";
import { SetUserAction, updateUser } from "common/actions/user";
import introductionArticle from "shared/components/InfoBox/introductionArticle";
import mobileAppSms, {
  mobileAppSmsFlash,
} from "shared/components/InfoBox/mobileAppSms";
import { get, isNil, uniqueId } from "lodash";
import { ActionsObservable, ofType } from "redux-observable";
import { EMPTY, merge, Observable, of, Subject } from "rxjs";
import { filter, mergeMap, take } from "rxjs/operators";
import { isClient } from "common/utils/user";
import { User } from "common/models/user";
import mobileAppDeviceGeneratedData from "shared/components/InfoBox/mobileAppDeviceGeneratedData";
import {
  showMobileAppDeviceGeneratedDataNotification,
  showMobileAppPanel,
} from "common/utils/mobile";
import { InfoBox } from "shared/reducers/infoBox";
import { FlashType } from "common/components/Flash";
import { MakeRequired } from "common/utils/types";
import addCaregiver from "shared/components/InfoBox/addCaregiver";
import { SiteSettings } from "common/utils/holvikaari";

enum FlipperUINotificationType {
  //FLASH = "FLASH",
  MODAL = "MODAL",
  INFO_BOX = "INFO_BOX",
}

type FlashNotification = {
  readonly closed: {
    readonly message: string;
    readonly type: FlashType;
  };
  readonly action?: {
    readonly message: string;
    readonly type: FlashType;
    readonly messageFunction: (user: any, message: any) => any;
  };
};

// Maximum amount of notifications shown with one page load (requires hard refresh to reload)
const MAX_NOTIFICATIONS = 2;

type getParams<T = any> = (
  id: string,
  dismiss: (closed?: boolean) => void,
  user: User,
) => Array<T>;

export type getInfoBoxParams = getParams<InfoBox>;

export type FlipperUINotification<T = any> = {
  readonly getParams: getParams<T>;
  /**
   * Type of the notification
   */
  readonly type: FlipperUINotificationType;
  /**
   * Name of flipper feature to query if this flash is enabled. Missing => always enabled
   */
  readonly enable_feature?: string;
  /**
   * Name of flipper feature to toggle on, when dismissed
   */
  readonly seen_feature?: string;
  /**
   * Filter based on some feature of user
   */
  readonly filter?: (user: User) => boolean;
  /**
   * Information of flash notification
   */
  readonly flash?: FlashNotification;
  readonly dismissable?: false;
};

// TODO: change SiteSettings.enable_ccda_patient_records to caregiver specific site setting once it is available
const showAddCaregiverInfoBox = (user: User): boolean =>
  isClient(user) &&
  SiteSettings.enable_ccda_patient_records &&
  user.features?.patient_can_add_caregivers;
// {
//   getParams: fn(FlashId, fn: dismiss, user) => spread args for addFlash, openModal, etc add-function defined in actionMap[type]
//   type: flash, modal, etc. or one of the keys of actionMap
//   enable_feature:
//   seen_feature:
//   filter: fn(user) => Bool, ie isClient
// }
//
// Remember to whitelist required featured in rest/UserController!
// Keep in mind that only MAX_NOTIFICATIONS first of these are displayed at first, rest after firsts are dismissed and page reloaded.

const infoBoxNotifications: FlipperUINotification<InfoBox>[] = [
  {
    getParams: introductionArticle,
    type: FlipperUINotificationType.INFO_BOX,
    enable_feature: "introduction_article_info_box",
    seen_feature: "seen_introduction_article_info_box",
    filter: isClient,
  },
  {
    getParams: mobileAppSms,
    type: FlipperUINotificationType.INFO_BOX,
    seen_feature: "seen_mobile_app_notification",
    filter: showMobileAppPanel,
    flash: {
      closed: {
        message: "mobile_app.later",
        type: "success",
      },
      action: {
        messageFunction: mobileAppSmsFlash,
        message: "mobile_app.sms.sent",
        type: "success",
      },
    },
  },
  {
    getParams: mobileAppDeviceGeneratedData,
    type: FlipperUINotificationType.INFO_BOX,
    filter: showMobileAppDeviceGeneratedDataNotification,
    seen_feature: "seen_mobile_app_device_generated_data_notification",
  },
  {
    getParams: addCaregiver,
    type: FlipperUINotificationType.INFO_BOX,
    seen_feature: "seen_add_caregiver_info_box",
    filter: showAddCaregiverInfoBox,
  },
];

const flipperUINotificationDefinitions: FlipperUINotification[] = [
  ...infoBoxNotifications,
];

const actionMap: Record<FlipperUINotificationType, { add: any; remove: any }> =
  {
    /*[FlipperUINotificationType.FLASH]: {
    add: addFlash,
    remove: closeFlash,
  },*/
    [FlipperUINotificationType.MODAL]: {
      add: openModal,
      remove: dismissModal,
    },
    [FlipperUINotificationType.INFO_BOX]: {
      add: addInfoBox,
      remove: dismissInfoBox,
    },
  };

const flashNotification = (
  dismissStream: DismissStream,
  closed: boolean,
  flash: FlashNotification,
  user: any,
) => {
  if (closed) {
    dismissStream.next(addFlash(flash.closed.message, flash.closed.type));
  } else {
    const message = flash.action?.messageFunction(user, flash.action.message);
    dismissStream.next(addFlash(message, flash.action?.type));
  }
};

const disableFlipperUINotification = (
  flipperUINotification: FlipperUINotification,
  user: any,
  id: string,
) => {
  const dismissStream: DismissStream = new Subject();
  const seenFeature = flipperUINotification.seen_feature;
  const dismiss = (closed = true) => {
    dismissStream.next(
      updateUser(user.id, {
        features: seenFeature && { [seenFeature]: true },
      }),
    );
    if (flipperUINotification.flash) {
      flashNotification(
        dismissStream,
        closed,
        flipperUINotification.flash,
        user,
      );
    }
    dismissStream.next(actionMap[flipperUINotification.type].remove(id));
    dismissStream.complete();
  };
  return { dismiss, dismissStream };
};

const emptyStreamPair = () => ({
  notifications: EMPTY,
  dismissals: EMPTY,
});

type NotificationsStream = Observable<
  ReturnType<(typeof actionMap)[keyof typeof actionMap]["add"]>
>;
type DismissStream = Subject<
  ReturnType<
    (typeof actionMap)[keyof typeof actionMap]["remove"] | typeof updateUser
  >
>;

export const flipperUINotification = (
  action$: ActionsObservable<SetUserAction>,
) =>
  action$.pipe(
    ofType("SET_USER"),
    filter(({ data: user }) => !isNil(get(user, "id"))), // Logged in
    take(1),
    mergeMap(({ data: user, features = {} }) => {
      const { notifications, dismissals } = flipperUINotificationDefinitions
        .filter(
          notification =>
            (isNil(notification.enable_feature) ||
              features[notification.enable_feature]) &&
            !features[notification.seen_feature || ""] &&
            (!notification.filter ||
              notification.filter({
                ...(user as MakeRequired<User, "id">),
                features,
              })),
        )
        .map<{
          notifications: Observable<
            NotificationsStream extends Observable<infer T> ? T : never | never
          >;
          dismissals: Observable<
            DismissStream extends Subject<infer T> ? T : never | never
          >;
        }>(notification => {
          if (!actionMap[notification.type]) {
            // eslint-disable-next-line no-console
            console.error(
              `Unkown type '${notification.type}' in flipperUINotificationDefinitions. Ignoring.`,
            );
            return emptyStreamPair();
          }
          const id = `${notification.getParams.name}-${uniqueId()}`;
          const { dismiss, dismissStream } = disableFlipperUINotification(
            notification,
            user,
            id,
          );
          const addNotificationAction = actionMap[notification.type].add(
            ...notification.getParams(
              id,
              dismiss,
              user as MakeRequired<User, "id">,
            ),
          );
          return {
            notifications: of(addNotificationAction),
            dismissals: dismissStream,
          };
        })
        .reduce(
          (m, { notifications, dismissals }) => ({
            notifications: merge(m.notifications, notifications),
            dismissals: merge(m.dismissals, dismissals),
          }),
          emptyStreamPair(),
        );
      return merge(notifications.pipe(take(MAX_NOTIFICATIONS)), dismissals);
    }),
  );
