import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { rgba } from "polished";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import {
  label,
  treatmentProgramLabels,
  sitelessBaseColors,
} from "@netmedi/design-system/dist/styles/variables";

type Props = {
  size: "small" | "medium" | "large";
  className: string;
};

const colorize = (fgColor: string, bgColor: string) => css`
  background: ${bgColor};
  color: ${fgColor};
  border: ${rgba(fgColor, 0.2)} 1px solid;
`;

const colorizeStrongBorder = (fgColor: string, bgColor: string) => css`
  background: ${bgColor};
  color: ${fgColor};
  border: ${fgColor} 1px solid;
`;

const large = css`
  line-height: ${rem(24)};
  height: ${rem(24)};
  font-size: ${rem(16)};
  ${rtl`
    margin-left: 0.3rem;
    margin-right: 0.3rem;
  `}
`;

const medium = css`
  line-height: ${rem(16)};
  height: ${rem(19.2)};
  font-size: ${rem(13)};
  ${rtl`
    margin-left: 0.2rem;
    margin-right: 0.2rem;
  `}
`;

const small = css`
  line-height: ${rem(16)};
  height: ${rem(16)};
  font-size: ${rem(9.6)};
  padding: 0 0.1rem;
  ${rtl`
    margin-left: 0.1rem;
    margin-right: 0.1rem;
  `}
`;

const sizes = {
  large,
  medium,
  small,
};

export const LabelColors = css`
  &.research,
  &.clinical_trial,
  &.crc_medical_oncology,
  &.crc_cancer,
  &.kaiser_research {
    ${colorize(
      treatmentProgramLabels.text.green,
      treatmentProgramLabels.background.green,
    )};
  }

  &.archive {
    ${colorize(
      treatmentProgramLabels.text.grey,
      treatmentProgramLabels.background.grey,
    )};
  }

  &.immunotherapy,
  &.targeted_therapy {
    ${colorize(
      treatmentProgramLabels.text.blue,
      treatmentProgramLabels.background.blue,
    )};
  }

  &.cancer_types,
  &.chemotherapy,
  &.colorectal_cancer,
  &.basic_follow_up,
  &.gynecological_cancer,
  &.hematology,
  &.isotope,
  &.breast_cancer,
  &.lung_cancer,
  &.hormonal_therapy,
  &.surgery {
    ${colorize(
      treatmentProgramLabels.text.yellow,
      treatmentProgramLabels.background.yellow,
    )};
  }

  &.follow_up,
  &.radiotherapy_late_symptoms {
    ${colorize(
      treatmentProgramLabels.text.brown,
      treatmentProgramLabels.background.brown,
    )};
  }

  &.satisfaction_survey,
  &.knowledge_support,
  &.other,
  &.staff {
    ${colorizeStrongBorder(
      sitelessBaseColors.blue500,
      sitelessBaseColors.white,
    )};
  }
  &.cms {
    ${colorize(
      treatmentProgramLabels.text.orange,
      treatmentProgramLabels.background.orange,
    )};
  }
  &.staff {
    ${colorizeStrongBorder(
      sitelessBaseColors.blue500,
      sitelessBaseColors.white,
    )};
  }
  &.fertility {
    ${colorize(
      treatmentProgramLabels.text.pink,
      treatmentProgramLabels.background.pink,
    )};
  }

  &.radiotherapy,
  &.radiotherapy_acute_symptoms {
    ${colorize(
      treatmentProgramLabels.text.purple,
      treatmentProgramLabels.background.purple,
    )};
  }
`;

export const Wrapper = styled.span<Props>`
  box-sizing: border-box;
  display: inline-block;
  background: ${label.background};
  border-radius: ${label.borderRadius}px;
  color: ${label.color};
  text-align: center;
  letter-spacing: 0;
  font-weight: 500;
  padding: 0 0.4rem;
  vertical-align: middle;
  ${({ size }) => sizes[size]}

  ${LabelColors}
`;
