import React from "react";
import { useDispatch } from "store";
import { openModal } from "common/actions/modal";
import FileInput from "./FileInput";
import { ButtonContainer } from "./SharedDocuments.styles";

const ShareButton = (props: {
  clientId: number;
  shareDocument: any;
  disabled: boolean;
}) => {
  const dispatch = useDispatch();

  const onFilesSelection = (files: File[]) => {
    dispatch(
      openModal({
        type: "share_document",
        files,
        clientId: props.clientId,
        shareDocument: props.shareDocument,
      }),
    );
  };

  return (
    <ButtonContainer>
      <FileInput
        onFilesSelected={onFilesSelection}
        buttonMessage={"shared_documents.add"}
        buttonIcon={"add_circle_16px"}
        disabled={props.disabled}
        multiple
      />
    </ButtonContainer>
  );
};

export default ShareButton;
