import api2 from "./api2";

export type Api2 = ReturnType<typeof api2>;

const realApi = api2();

const getApiClient = (): Api2 => {
  if (process.env.NODE_ENV !== "test") return realApi;

  return (global as any).apiMock?.mock ?? realApi;
};

export default getApiClient;
