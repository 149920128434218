import React from "react";
import { Flash, FlashExtras } from "@netmedi/design-system";
import { injectIntl } from "react-intl";
import { Viewport } from "common/epics/viewport";

export type Flash = FlashExtras.FlashModel;
export type FlashType = FlashExtras.FlashType;

export const FlashWrapper = (
  props: Omit<FlashExtras.FlashProps, "iconSize">,
) => (
  <Flash
    {...props}
    iconSize={window.innerWidth < Viewport.SMALL ? "medium" : "large"}
  />
);

export default injectIntl(FlashWrapper);
