import styled from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import { colors } from "@netmedi/design-system/dist/styles/variables";
import { Icon } from "@netmedi/design-system";

export const DocumentContainer = styled.a.attrs(() => ({
  "data-testid": "document-card",
}))<{ fit_parent: boolean; unclickable?: boolean }>`
  margin: 10px 0px 10px 0px;
  padding: 16px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 22px;
  font-family: "Graphik";
  font-style: normal;
  justify-content: start;
  cursor: ${({ unclickable }) => !unclickable && "pointer"};
  box-shadow: 0px 38px 12px -30px #f2f2f2;
  text-overflow: ellipsis;
  max-width: 100%;
  white-space: break-spaces;
  word-break: break-word;
  align-items: center;
  width: ${({ fit_parent }) => (fit_parent ? "auto" : "fit-content")};
`;

export const IconBase = styled.div<{ background?: string }>`
  background: ${({ background }) => background ?? colors.blue50};
  display: flex;
  height: 50px;
  min-width: 50px;
  border-radius: 50%;
  background: #f3f1ff;
  align-items: center;
  justify-content: center;
`;

export const TextArea = styled.div`
  padding: 0px 20px 0px 20px;
  text-overflow: ellipsis;
  max-height: 150px;
  overflow-y: auto;
`;

export const IconContainer = styled.div`
  ${rtl`margin-left: auto;`}
`;

export const StyledIcon = styled(Icon)<{ name: string }>`
  color: ${({ name }) => (name === "remove" ? colors.red500 : colors.blue500)};
  cursor: pointer;
`;

export const FileCategory = styled.div`
  font-weight: 500;
  font-size: ${rem(12)};
  line-height: ${rem(24)};
  letter-spacing: 0.1em;
  text-transform: uppercase;
  color: ${colors.gray950};
`;

export const FileTitle = styled.div`
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${colors.blue700};
`;

export const FileDescription = styled.div`
  font-weight: 400;
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  letter-spacing: 0.005em;
  color: ${colors.blue500};
`;

export const FileDate = styled.div`
  font-weight: 400;
  font-size: ${rem(16)};
  line-height: ${rem(24)};
  color: ${colors.gray950};
`;
