import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonExtras } from "@netmedi/design-system";
const { LinkButton } = ButtonExtras;
import { openMobileAppDeviceGeneratedDataSettings } from "common/actions/user";
import { FeaturesList } from "./InfoBox.styles";
import { getInfoBoxParams } from "shared/epics/flipperUINotification";

// Enabled device generated data value types, e.g. steps, heart rate
const enabledTypes = ["steps"];

const mobileAppDeviceGeneratedData: getInfoBoxParams = (id, dismiss, user) => [
  {
    icon: "mobile_person_48px",
    title: (
      <FormattedMessage id="mobile_app.device_generated_data.info_box.heading" />
    ),
    body: (
      <FormattedMessage
        id="mobile_app.device_generated_data.info_box.content"
        values={{
          types: (
            <FeaturesList>
              {enabledTypes.map(type => (
                <li key={type}>
                  <FormattedMessage
                    id={`mobile_app.device_generated_data.types.${type}`}
                  />
                </li>
              ))}
            </FeaturesList>
          ),
        }}
      />
    ),
    buttons: (
      <span>
        <LinkButton
          size="small"
          className="device-generated-data-enable-link"
          onClick={e => {
            e.preventDefault();
            openMobileAppDeviceGeneratedDataSettings(user, dismiss);
          }}
          type="secondary"
        >
          <FormattedMessage id="mobile_app.device_generated_data.info_box.enable" />
        </LinkButton>
      </span>
    ),
    id,
    dismiss,
    elementId: "mobile-app-device-generated-data-infobox",
  },
];

export default mobileAppDeviceGeneratedData;
