/* eslint-disable no-irregular-whitespace */
import styled from "styled-components";
import { colors } from "@netmedi/design-system/dist/styles/variables";
import rtl from "styled-components-rtl";

const radioWidth = "22px";
const radioHeight = "22px";

export const RadioWrapper = styled.div`
  position: relative;
  width: ${radioWidth};
`;

export const RadioGroupInput = styled.input`
  border: none !important;
  padding: 0;
  outline-offset: 0 !important;
  margin: 0 !important;

  ::before {
    background-color: ${colors.white};
    border: 1px solid ${colors.gray500};
    border-radius: 50%;
    content: "";
    ${rtl`left: 0;`}
    position: absolute;
    top: 0;
    height: ${radioHeight};
    width: ${radioWidth};
  }
  ::after {
    content: "";
    background-color: ${colors.gray900};
    top: 6px;
    ${rtl`left: 6px;`}
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid;
    opacity: 0;
    position: absolute;
    transition: opacity 0.2s ease-in-out;
    cursor: pointer;
  }

  :checked::after {
    opacity: 1;
  }

  &:active::before,
  &:focus::before,
  &:hover::before {
    border-color: var(--primary-color);
  }

  &:disabled::before {
    background-color: ${colors.gray300};
    border-color: ${colors.gray500};
    color: ${colors.gray700};
  }
`;

export const RadioGroupLabel = styled.label`
  display: inline;
  margin: 0 0.5rem;
`;

export const RadioGroupOption = styled.div`
  display: flex;
  align-items: baseline;
`;

export const RadioGroupContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const RadioErrors = styled.div`
  color: ${colors.red700};
  margin-top: 0.5rem;
`;
