import { baseColors } from "@netmedi/design-system/dist/styles/variables";
import styled from "styled-components";
import { paddingLeft } from "@netmedi/design-system/dist/styles/styleHelpers";

export const PdfSettingsPanelContainer = styled.div`
  border: 1px solid ${baseColors.gray500};
  border-radius: 3px;
  max-width: 700px;
  padding: 1rem;
  margin: 0 auto;
`;

export const Checkbox = styled.input`
  -webkit-appearance: checkbox !important;
`;

export const Setting = styled.div`
  margin: 1rem 0;
  display: flex;
  align-items: center;
`;

export const SettingLabel = styled.div`
  flex: 1;
  font-weight: 500;
  text-align: right;
  margin-right: 1em;
`;
export const SettingInput = styled.div`
  flex: 2;
  ${paddingLeft("xxs")};

  label span {
    vertical-align: middle;
  }
`;
