import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonExtras } from "@netmedi/design-system";
const { LinkButton } = ButtonExtras;
import { getInfoBoxParams } from "shared/epics/flipperUINotification";
import LocalizedMarkdown from "common/components/Markdown/LocalizedMarkdown";

// TODO: Add correct path once "Add caregiver" feature is implemented
const addCaregiverPath = "/profile";
const addCaregiver: getInfoBoxParams = (id, dismiss) => {
  return [
    {
      icon: "heart_2",
      title: <FormattedMessage id="add_caregiver.heading" />,
      body: <LocalizedMarkdown id="add_caregiver.content" />,
      buttons: (
        <div>
          <LinkButton
            type="secondary"
            size="small"
            to={addCaregiverPath}
            onClick={() => dismiss()}
          >
            <FormattedMessage id="add_caregiver.add_button" />
          </LinkButton>
        </div>
      ),
      id,
      dismiss,
      dismissButtonText: "info_box.not_now",
    },
  ];
};

export default addCaregiver;
