import { closeFlash } from "common/actions/flash";
import FlashComponent from "common/components/Flash";
import { Icon, IconExtras } from "@netmedi/design-system";
type IconName = IconExtras.IconName;
import Markdown from "common/components/Markdown";
import React from "react";
import { useAutofocus } from "shared/hooks/useAutofocus";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  StyledPage,
  FlashContainer,
  Content,
  PageHeader,
  LinkBackDescription,
  Subtitle,
  LinkWrapper,
  StyledRouterLink,
  StyledLink,
  StyledIcon,
  Nav,
} from "./Page.styles";
import { RootState } from "store";

const mapStateToProps = ({ flash }: RootState) => ({ flashes: flash });

const mapDispatchToProps = { closeFlash };

type PageProps = ConnectedProps<typeof connector> & {
  children: React.ReactNode;
  className?: string;
  noWrapper?: boolean; // render content w/o the wrapper element;
  compact?: boolean;
  // whether page uses absolute positioning for its layout.
  // If true, flashes will be absolute positioned too
  absolute?: boolean;
};

function Page(props: PageProps) {
  const {
    compact = false,
    absolute = false,
    flashes,
    noWrapper,
    children,
  } = props;
  return (
    <StyledPage $compact={compact} className={props.className}>
      <FlashContainer $absolute={absolute}>
        {flashes?.map(flash => (
          <FlashComponent
            key={flash.id}
            type={flash.type}
            leaving={!!flash.leaving}
            onClose={
              flash.onClose
                ? flash.onClose
                : props.closeFlash.bind(null, flash.id)
            }
            collapsible={!!flash.message.collapsible}
          >
            {flash.message.markdown ? (
              <Markdown
                source={flash.message.source}
                options={flash.message.markdownOptions}
              />
            ) : (
              <FormattedMessage {...flash.message} />
            )}
          </FlashComponent>
        ))}
      </FlashContainer>
      {noWrapper ? children : <Content>{children}</Content>}
    </StyledPage>
  );
}

type HeaderProps = {
  values?: any;
  title?: React.ReactNode;
  subtitle?: React.ReactNode;
  translated?: boolean;
  linkBack?: string;
  linkBackRole?: string;
  linkBackIcon?: string;
  linkBackRouteOverride?: string;
  className?: string;
  children?: React.ReactNode;
  noNavigation?: boolean;
  iconSize?: "small" | "medium" | "large" | "xl" | "xxl" | "relative";
  colorTheme?: "dark" | "light";
};

export function Header({
  translated,
  title,
  subtitle,
  values,
  children,
  linkBack,
  linkBackRole,
  linkBackIcon = "arrow_left",
  linkBackRouteOverride,
  className,
  noNavigation = false,
  iconSize = "medium",
  colorTheme,
}: HeaderProps) {
  const history = useHistory();
  const headerRef = useAutofocus();

  return (
    <PageHeader
      $linkBack={linkBack}
      $noNavigation={noNavigation}
      $colorTheme={colorTheme}
      className={className}
    >
      {linkBack && (linkBackRouteOverride || history) && (
        <LinkWrapper>
          {linkBackRouteOverride ? (
            <StyledRouterLink to={linkBackRouteOverride} role={linkBackRole}>
              <StyledIcon name={linkBackIcon as IconName} size={iconSize} />
              <LinkBackDescription>
                <FormattedMessage id={linkBack} tagName="span" />
              </LinkBackDescription>
            </StyledRouterLink>
          ) : (
            <StyledLink onClick={() => history.goBack()} role={linkBackRole}>
              <Icon name={linkBackIcon as IconName} size={iconSize} />
              <LinkBackDescription>
                <FormattedMessage id={linkBack} tagName="span" />
              </LinkBackDescription>
            </StyledLink>
          )}
        </LinkWrapper>
      )}
      {(title || subtitle) && (
        <h1 ref={headerRef} tabIndex={-1}>
          {translated ? (
            title
          ) : (
            <FormattedMessage id={title as string} values={values} />
          )}
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </h1>
      )}
      {children}
    </PageHeader>
  );
}

type NavProps = {
  active?: boolean;
  tabs?: boolean;
  children: React.ReactNode;
};

export function PageNav({ children, tabs = false }: NavProps) {
  return (
    <Nav $tabs={tabs}>
      <ul>{children}</ul>
    </Nav>
  );
}

export function NavItem({ children, active, ...rest }: NavProps) {
  return (
    <li {...rest} className={active ? "active" : ""}>
      {children}
    </li>
  );
}

const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(Page);
