import React from "react";
import { Icon, IconExtras } from "@netmedi/design-system";
type IconName = IconExtras.IconName;
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { ScreenReaderOnlySpan } from "./ExternalLink.styles";

interface ILinkProps {
  id: string;
  href: string;
  textKey: string;
  iconKey: string;
  iconName: IconName;
}

const ExternalLink = ({
  id,
  href,
  textKey,
  iconName,
  iconKey,
  intl,
  ...rest
}: ILinkProps & WrappedComponentProps) => (
  <a id={id} href={href} target="_blank" {...rest}>
    <FormattedMessage id={textKey} />
    <span>&nbsp;</span>
    <span aria-hidden>
      <Icon
        name={iconName}
        alt={intl.formatMessage({
          id: iconKey,
        })}
      />
    </span>
    <ScreenReaderOnlySpan>
      <FormattedMessage id={iconKey} />
    </ScreenReaderOnlySpan>
  </a>
);

export default injectIntl(ExternalLink);
