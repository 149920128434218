import { stringify } from "qs";
import { Params, EndpointParams } from "../api2.types";
import { SiteSettings } from "../../holvikaari";
import { endpoints, get, getWithoutPageLoadError, post } from "../../api";

const ccda = ({ ccda_api_url }: Record<string, string> = {}) => {
  const baseUrl = (userId: number) =>
    ccda_api_url &&
    `${ccda_api_url}/patient_records/${SiteSettings.site}/${userId}`;
  return {
    ccdaRecord: ({ userId, id }: EndpointParams["ccdaRecord"]) => {
      const url = ccda_api_url
        ? `${baseUrl(userId)}/${id}`
        : endpoints.patient_records.display(id);
      return { get: () => get(url) };
    },
    ccdaRecords: ({ userId }: EndpointParams["ccdaRecords"]) => {
      const url = ccda_api_url
        ? `${baseUrl(userId)}`
        : endpoints.patient_records.index;
      return {
        get: (params: Params["ccdaRecords"]) =>
          getWithoutPageLoadError(`${url}?${stringify(params)}`),
      };
    },
    ccdaRecordShare: ({ userId }: EndpointParams["ccdaRecordShare"]) => {
      const url = ccda_api_url
        ? `${baseUrl(userId)}/share`
        : endpoints.patient_records.share;
      return { post: (data: Params["ccdaRecordShare"]) => post(url, data) };
    },
    docRefDownload: ({ userId, id }: any) =>
      `${baseUrl(userId)}/download/${id}`,
    ccdaDownload: ({ userId }: EndpointParams["ccdaDownload"]) => {
      const url = ccda_api_url
        ? `${baseUrl(userId)}/download`
        : endpoints.patient_records.download;
      return {
        get: ({ ids }: Params["ccdaDownload"]) =>
          fetch(`${url}?${stringify({ id: ids.join(",") })}`),
      };
    },
    ccdaActivities: ({ userId }: EndpointParams["ccdaActivities"]) => {
      const url = ccda_api_url
        ? `${baseUrl(userId)}/activities`
        : endpoints.patient_records.activities;
      return {
        get: (params: Params["ccdaActivities"]) =>
          get(`${url}?${stringify(params)}`),
      };
    },
    ccdaShareDownload: ({ path }: EndpointParams["ccdaShareDownload"]) => {
      // Currently downloading from the old endpoint since authbroker does not support unauthed requests
      const url =
        ccda_api_url && process.env.NODE_ENV === "test"
          ? `${ccda_api_url}/shared_ccda_entries/${SiteSettings.site}/${path}`
          : path;
      return {
        get: () => fetch(url),
        post: ({ accessCode }: Params["ccdaShareDownload"]) =>
          fetch(url, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ sms_code: accessCode }),
          }),
      };
    },
  };
};

export default ccda;
