/* eslint-disable max-lines */
import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import {
  choiceActiveBg,
  colors,
  radio,
  spacing,
  text,
  typography,
  mediaQueries,
} from "@netmedi/design-system/dist/styles/variables";
import { mediaMin } from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";

import { IProps } from "./Choice.types";

type ChoiceDivProps = {
  extraPadding?: boolean;
  defaultChoice?: boolean;
  highlightSelected?: boolean;
  borderSelected?: boolean;
  fieldType?: string;
  button?: boolean;
  active?: boolean;
  disabled?: boolean;
  $checked?: boolean;
} & Pick<Partial<IProps>, "direction" | "background">;

const centerVertically = (size: number) => css`
  display: inline-block;
  position: absolute;
  height: ${size}px;
  width: ${size}px;
  top: 50%;
  margin-top: calc(-0.5 * ${size}px);
`;

const labelStyle = css`
  cursor: pointer;
  margin: 0;
  transition: background 0.2s;
`;

export const Label = styled.label(rtl`
  ${labelStyle}

  display: block;
  padding: 0.5em;
  padding-left: 3.5rem;
  width: 100%;

  &::after {
    display: inline-block;
    position: absolute;
    content: "";
    border: 1px solid ${radio.borderColor};
    ${centerVertically(24)}

    left: calc(.5rem + 8px);
    border-radius: 2px;
  }

  &::before {
    display: inline-block;
    position: absolute;
    content: "";
    ${centerVertically(16)}

    left: calc(.5rem + 12px);
    transition: all 0.2s;
  }
`);

export const CheckboxLabel = styled(Label)``;
export const RadioLabel = styled(Label)``;

const imageChoiceWrapper = rtl`
  width: 32px;
  height: 32px;
  min-width: 32px;
  min-height: 32px;
  vertical-align: middle;
  margin: ${spacing.xs}px;
  margin-left: 0;

  img {
    width: 100%;
    height: 100%;
    user-select: none;
  }
`;

const horizontalCentering = ({
  center_horizontally,
}: {
  center_horizontally?: boolean;
}) =>
  center_horizontally &&
  rtl`
  ${mediaMin(mediaQueries.small)} {
    margin-left: ${spacing.xs}px;
  }`;

export const SelectedImage = styled.span<{ center_horizontally?: boolean }>`
  ${imageChoiceWrapper}
  ${horizontalCentering}
`;

export const UnSelectedImage = styled.span<{ center_horizontally?: boolean }>`
  ${imageChoiceWrapper}
  ${horizontalCentering}
`;

export const QuestionDescription = styled.div``;

export const QuestionTextWrapper = styled.span``;

export const QuestionText = styled.span``;

export const ImageLabel = styled.label(rtl`
  ${labelStyle}

  padding-left: 1rem;
  display: flex;
  align-items: center;

  & img {
    pointer-events: none;
  }

  ${QuestionText} {
    font-family: ${typography.fontFamilyBase};
    font-weight: 500;
  }
`);

const directionStyles = ({
  direction,
  extraPadding,
  defaultChoice,
}: ChoiceDivProps) => {
  if (direction !== "horizontal") {
    return "";
  }

  const extraPaddingStyle = rtl`
    max-width: 300px;
    margin: 0 ${spacing.xs}px;

    &:first-of-type {
      margin-left: 0;
    }
  `;

  const defaultChoiceStyle = css`
    ${QuestionText} {
      padding-top: 3rem;
    }
  `;

  return rtl`
    flex: 1;
    text-align: center;
    margin: 0 0 1rem 0;
    margin-right: 0.5rem;
    max-width: 150px;

    ${extraPadding && extraPaddingStyle}

    ${ImageLabel} {
      display: block;
      margin: 0;
      margin-right: 0.5rem;
      padding: ${spacing.s}px 0.75rem;
    }

    ${Label} {
      margin: 0;
      margin-right: 0.5rem;
      padding: 0.75rem;

      &::after {
        top: 1.6rem;
        left: 50%;
        margin-left: -1rem;
      }

      &::before {
        top: 1.6rem;
        left: 50%;
        margin-left: -0.75rem;
      }
    }

    ${defaultChoice && defaultChoiceStyle}

    ${QuestionText} {
      display: block;
    }
  `;
};

const hoverStyles = ({ highlightSelected }: ChoiceDivProps) =>
  highlightSelected &&
  css`
    @media (hover: hover) {
      &:hover {
        background: ${choiceActiveBg};
      }
    }

    /* Hack for IE 10 and 11 that don't support @media (hover: hover) */
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      &:hover {
        background: ${choiceActiveBg};
      }
    }
  `;

const radioStyles = ({
  fieldType,
  highlightSelected,
  $checked,
}: ChoiceDivProps) =>
  fieldType === "radio" &&
  rtl`
    ${RadioLabel}::after, ${RadioLabel}::before {
      border-radius: 50%;
    }

    &:hover ${RadioLabel}::before {
      background: ${radio.hoverColor};
    }

    ${SelectedImage} {
      display: none;
    }

    ${UnSelectedImage} {
      display: inline-block;
    }

    ${ImageLabel}:hover ${SelectedImage} {
      display: inline-block;
    }

    ${ImageLabel}:hover ${UnSelectedImage} {
      display: none;
    }

    @media (hover: none) {
      &:hover ${RadioLabel}::before {
        background: initial;
      }
    }


    ${
      $checked &&
      css`
        && {
          ${ImageLabel} ${SelectedImage} {
            display: inline-block;
          }

          ${ImageLabel} ${UnSelectedImage} {
            display: none;
          }

          ${RadioLabel}::before {
            ${highlightSelected && `background: ${radio.activeColor};`}
          }
        }
      `
    }

    label {
      display: inline-block;
    }
  `;

const checkboxStyles = ({ fieldType, $checked }: ChoiceDivProps) =>
  fieldType === "checkbox" &&
  css`
    ${CheckboxLabel}::before {
      background: transparent;
      border: transparent 3px solid;
      border-top: 0;
      ${rtl`border-right: 0;`}
      transform: rotate(-45deg);
      height: 9px;
    }

    &:hover ${CheckboxLabel}::before {
      border-color: ${radio.hoverColor};
    }

    ${ImageLabel}:hover ${SelectedImage} {
      display: inline-block;
    }

    ${ImageLabel}:hover ${UnSelectedImage} {
      display: none;
    }

    @media (hover: none) {
      &:hover ${CheckboxLabel}::before {
        border-color: transparent;
      }

      ${ImageLabel}:hover ${SelectedImage} {
        display: none;
      }

      ${ImageLabel}:hover ${UnSelectedImage} {
        display: inline-block;
      }
    }

    ${$checked &&
    css`
      && {
        ${ImageLabel} ${SelectedImage} {
          display: inline-block;
        }

        ${ImageLabel} ${UnSelectedImage} {
          display: none;
        }

        ${CheckboxLabel}::before {
          border-color: ${radio.activeColor};
        }

        &:disabled {
          ~ ${CheckboxLabel}::before {
            border-color: ${colors.gray300};
          }

          ~ ${CheckboxLabel}::after {
            background-color: rgba(0, 0, 0, ${colors.gray25Alpha});
            border-color: ${colors.gray300};
          }
        }
      }
    `}

    ${ImageLabel} {
      display: inline-flex;
      padding: 1rem;
      ${rtl`margin-left: 0;`}
      margin-bottom: ${spacing.xxs}px;
    }

    ${QuestionText} {
      vertical-align: middle;
    }

    ${QuestionDescription} {
      color: ${colors.gray950};
      margin-top: ${spacing.xs}px;
    }
  `;

const activeStyle = css`
  background: ${choiceActiveBg};
  border: 1px solid ${colors.yellow500};
`;

export const backgroundStyles = ({
  active,
  background,
  $checked,
}: ChoiceDivProps) =>
  background &&
  css`
    display: flex;
    align-items: center;
    border: 1px solid ${colors.gray300};

    ${active && activeStyle}

    ${$checked &&
    css`
      && {
        ${activeStyle}

        ${CheckboxLabel}, ${ImageLabel} {
          font-weight: 500;
        }
      }
    `}
  `;

export const ChoiceDiv = styled.div.attrs(
  ({ fieldType, active, direction, $checked }: ChoiceDivProps) => ({
    role: fieldType,
    type: fieldType,
    "aria-checked": $checked,
    "data-fieldtype": fieldType,
    "data-testid": `choice${active ? "-active" : ""}`,
    "data-direction": direction,
  }),
)<ChoiceDivProps>`
  ${directionStyles}

  ${radioStyles}
  ${checkboxStyles}

  position: relative;
  background-color: ${({ disabled }) =>
    disabled ? colors.gray500 : colors.white};
  opacity: ${({ disabled }) => (disabled ? "0.75" : "1")};
  ${rtl`margin: 0 0 1rem 0;`}
  border: 1px transparent solid;
  border-radius: 29px;

  label {
    line-height: ${rem(24)};
    font-size: ${rem(16)};
  }

  ${({ button }) => button && "width: 100%;"}

  &[data-focus-visible-added] {
    outline-width: 2px;
    outline-style: solid;
    outline-color: ${colors.blue700};
    outline-offset: 2px;
  }

  ${({ disabled }) => (disabled ? "" : hoverStyles)};

  ${({ extraPadding }) => extraPadding && rtl`margin-right: 0;`}

  .symptomCategorySelectionLabel {
    width: 100%;
    font-size: ${text.size.medium};

    ${QuestionText} {
      text-transform: none;
    }

    ${QuestionDescription} {
      margin-top: 0px;
      line-height: ${text.lineHeight.medium};
    }
  }

  ${SelectedImage} {
    display: none;
  }

  ${UnSelectedImage} {
    display: inline-block;
  }

  ${ImageLabel}:hover ${SelectedImage} {
    display: none;
  }

  ${ImageLabel}:hover ${UnSelectedImage} {
    display: inline-block;
  }

  ${backgroundStyles}
`;

export const IconContainer = styled.div<{ open?: boolean }>`
  ${rtl`margin-left: auto;`}
  ${rtl`margin-right: 20px;`}
  transition: all 0.4s;
  margin-top: 3px;
  cursor: pointer;
  color: ${colors.blue500};

  ${({ open }) => open && "transform: rotate(90deg);"}
`;
