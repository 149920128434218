/* eslint-disable max-lines-per-function */
import React, { useEffect, useMemo } from "react";
import FormField, { PlainFormField } from "common/components/Form/FormField";
import DatePicker from "common/components/Form/DatePicker";
import { Field, useForm, useFormState } from "react-final-form";
import { Select } from "common/components/Form";
import useI18nTranslate from "common/hooks/useI18nTranslate";
import { useSelector } from "react-redux";
import { selectStaff } from "common/reducers/staff";
import {
  AbsenceFieldsStyled,
  HelpText,
  HorizontalContainer,
  TextMuted,
} from "./Absences.styles";

const domainsOverlap = (a: string[], b: string[]) => {
  return a.some(domain => b.includes(domain));
};

const AbsenceFields: React.FC = () => {
  const t = useI18nTranslate();
  const staff = useSelector(selectStaff);
  const absentPersonOptions = useMemo(
    () =>
      staff.map(s => ({
        value: s.id,
        label: s.full_name,
        formattedLabel: (
          <>
            {s.full_name}
            <TextMuted> - {s.role}</TextMuted>
          </>
        ),
      })),
    [staff],
  );
  const { values } = useFormState();
  const { change } = useForm();
  const absentPerson = staff.find(s => s.id === values.absent_person_id);

  const substitutePersonOptions = staff
    .filter(
      substitute =>
        substitute.id !== values.absent_person_id &&
        domainsOverlap(substitute.domains, absentPerson?.domains ?? []),
    )
    .map(s => ({
      value: s.id,
      label: s.full_name,
      formattedLabel: (
        <>
          {s.full_name}
          <TextMuted> - {s.role}</TextMuted>
        </>
      ),
    }));

  useEffect(() => {
    if (values.substitute_person_id === values.absent_person_id) {
      change("substitute_person_id", undefined);
    }
  }, [values.absent_person_id]);

  return (
    <AbsenceFieldsStyled data-testid="AbsenceFields">
      {/* ABSENT PERSON SELECT */}
      <FormField
        name="absent_person_id"
        inactiveLabel
        labelOnTop
        showRequiredIndicator
        required
      >
        <Field name="absent_person_id">
          {({ input }) => (
            <Select
              options={absentPersonOptions}
              name="absent_person_id"
              isSearchable
              isClearable
              onChange={e => input.onChange(+e.value)}
              value={
                absentPersonOptions.find(p => p.value === input.value) ?? null
              }
              formatOptionLabel={o => o.formattedLabel}
              placeholder={t("dashboard.ccda_patient_records.headings.select")}
            />
          )}
        </Field>
      </FormField>

      {/* SUBSTITUTE PERSON SELECT */}
      <FormField
        name="substitute_person_id"
        inactiveLabel
        labelOnTop
        showRequiredIndicator
        required
      >
        <Field name="substitute_person_id">
          {({ input }) => (
            <Select
              options={substitutePersonOptions}
              name="substitute_person_id"
              isSearchable
              isClearable
              value={
                substitutePersonOptions.find(
                  p => p.value === values.substitute_person_id,
                ) ?? null
              }
              formatOptionLabel={o => o.formattedLabel}
              onChange={e => input.onChange(+e.value)}
              placeholder={t("dashboard.ccda_patient_records.headings.select")}
            />
          )}
        </Field>
      </FormField>
      <HelpText>{t("absences.substitute_help")}</HelpText>

      <HorizontalContainer>
        {/* BEGIN DATE */}
        <FormField name="begin_date" labelOnTop showRequiredIndicator required>
          <div>
            <Field name="begin_date">
              {fieldProps => (
                <DatePicker
                  fullWidth
                  {...fieldProps.input}
                  onChange={d =>
                    fieldProps.input.onChange(d.format("YYYY-MM-DD"))
                  }
                />
              )}
            </Field>
          </div>
        </FormField>

        {/* END DATE */}
        <FormField name="end_date" labelOnTop showRequiredIndicator required>
          <div>
            <Field name="end_date">
              {fieldProps => (
                <DatePicker
                  fullWidth
                  {...fieldProps.input}
                  onChange={d =>
                    fieldProps.input.onChange(d.format("YYYY-MM-DD"))
                  }
                />
              )}
            </Field>
          </div>
        </FormField>
      </HorizontalContainer>

      {/* DESCRIPTION */}
      <PlainFormField
        name="description"
        labelOnTop
        label={t("absences.description")}
      />
    </AbsenceFieldsStyled>
  );
};

export default AbsenceFields;
