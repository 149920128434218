/* eslint-disable max-lines-per-function */
import { createGlobalStyle } from "styled-components";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";

export const GlobalStyle = createGlobalStyle`
  ${({ theme }) => `
    html {
      box-sizing: border-box;
    }

    *,
    *::before,
    *::after {
        box-sizing: inherit;
    }

    html,
    body {
        margin: 0;
        padding: 0;
        height: 100%;
        width: 100%;
        overflow: hidden;
    }

    body {
        background: ${theme.scaffolding.bodyBg};
        font-family: ${theme.typography.fontFamilyBase};
        font-size: ${theme.typography.fontSizeBase};
        line-height: ${theme.typography.baseLineHeight};
        color: ${theme.typography.mainTextColor};
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    @supports (font: -apple-system-body) and (-webkit-touch-callout: none) {
        html {
            font: -apple-system-body;
        }
    }

    a {
        transition: color 0.15s;
    }

    a:link,
    a:visited {
        color: ${theme.colors.linkColor};
        text-decoration: none;
    }

    a:hover,
    a:active {
        color: ${theme.colors.linkHoverColor};
        cursor: pointer;
    }

    h1,
    h2,
    h3 {
        letter-spacing: -1px;

        &:first-child {
            margin-top: 0;
        }
    }

    h1,
    h2,
    h3,
    h4 {
        font-family: ${theme.typography.fontFamilyHeadings};
        font-weight: 400;
        color: ${theme.scaffolding.headingColor};
        margin: 0.5rem 0;
    }

    h1 {
        font-size: ${rem(32)};
    }
    h2 {
        font-size: ${rem(24)};
        margin: 1.5rem 0;
    }
    h3 {
        font-size: ${rem(20)};
    }
    h4 {
        margin: 0.25rem 0;
        font-weight: ${theme.scaffolding.bold};
    }

    b,
    strong,
    th {
        font-weight: ${theme.scaffolding.bold};
    }

    ul,
    li {
        list-style-type: none;
        margin: 0;
        padding: 0;
    }

    table {
        border-spacing: 0;
        border-collapse: collapse;
    }

    fieldset {
        border: 0;
        padding: 0;
        margin: 1em 0;
    }

    legend {
        padding: 0 0.5em;
        font-weight: 500;
    }

    textarea {
        padding: 0.5em;
        background: ${theme.forms.background};
        border: ${theme.forms.background} 1px solid;
        border-radius: ${theme.forms.borderRadius}px;
        line-height: 1;
        font-weight: normal;
        transition: border 0.2s;
        resize: none;
    }

    input,
    textarea {
        color: ${theme.forms.textColor};
        font-size: 100%;
        font-family: ${theme.scaffolding.fontFamilyBase};
        -webkit-appearance: none;

        &:focus,
        &:active {
            outline: none;
            border: ${theme.forms.borderColorActive} 1px solid;
        }
    }

    hr {
      clear: both;
      margin: 1em 0;
      background: ${theme.colors.gray300};
      border: 0;
      height: 1px;
    }

    small {
        font-size: ${theme.typography.fontSizeSmall};
    }

    // number inputs without browser up/dn buttons
    input[type="number"] {
        -moz-appearance: textfield;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
    }

    #app {
        height: 100%;
        width: 100%;
    }

    a[data-focus-visible-added], h1[data-focus-visible-added] {
        outline: 2px solid ${theme.colors.blue700};
        outline-offset: 2px;
    }

    *:focus:not([data-focus-visible-added]) {
        outline: none;
    }
  `}
`;
