import React from "react";
import Sidebar from "./Sidebar";
import Mobile from "./Mobile";
import { omit, sum, values } from "lodash";
import {
  hasArticles,
  isClient,
  caregiverViewingPatient,
  hasSharedDocuments,
} from "common/utils/user";
import { NavItem } from "./navItem";
import { User } from "common/models/user";
import { SiteSettings } from "common/utils/holvikaari";
import { IconExtras } from "@netmedi/design-system";
type IconName = IconExtras.IconName;

export default function Navigation({
  isMobile,
  user,
  messages,
  embeddableViews,
}: NavigationProps) {
  const mNav = mainNav(user, messages, embeddableViews).filter(
    link => !link.section && link.text !== "sign_out",
  );
  return isMobile ? (
    <Mobile items={mNav.slice(0, 5)} hiddenItems={mNav.slice(5)} />
  ) : (
    <Sidebar items={mainNav(user, messages, embeddableViews)} user={user} />
  );
}

export interface NavigationProps {
  isMobile: boolean;
  user: User;
  messages?: Record<string, number>;
  embeddableViews: {
    type: string;
    icon: IconName;
  }[];
}

const unreadBadge = (messages: NavigationProps["messages"]) =>
  messages && sum(values(messages));

const embeddableViewsNav = (
  embeddableViews: NavigationProps["embeddableViews"],
  user: NavigationProps["user"],
): NavItem[] =>
  !embeddableViews
    ? []
    : embeddableViews.map(view => ({
        text: view.type,
        to: `/embeddable_views/${view.type}`,
        icon: view.icon,
        hide: !view || (view.type === "gillie" && user.language !== "fi"),
      }));

const mainNav = (
  user: NavigationProps["user"],
  conversation: NavigationProps["messages"],
  embeddableViews: NavigationProps["embeddableViews"],
): NavItem[] =>
  [
    {
      text: "front_page",
      to: "/",
      icon: "home",
      exact: true,
    },
    {
      text: "care_information",
      to: "/dashboard",
      icon: "dashboard",
      hide: caregiverViewingPatient(user),
    },
    {
      text: "conversations",
      to: "/conversations",
      icon: "messages",
      badge: unreadBadge(conversation),
      hide: !user.conversations_enabled,
    },
    {
      text: "video",
      href: "/videos/new",
      icon: "phone",
      hide:
        !SiteSettings.video_conversations ||
        isClient(user) ||
        caregiverViewingPatient(user),
    },
    {
      text: "articles",
      to: "/articles",
      icon: "healthbag",
      hide:
        (!hasArticles(user) && !hasSharedDocuments(user)) ||
        caregiverViewingPatient(user),
    },
    embeddableViewsNav(embeddableViews, user),
    {
      text: "own_information",
      to: "/profile",
      icon: "user",
      spacingAfter: true,
    },
    { text: "sign_out", href: "/signout", icon: "logout" },
  ]
    .flat()
    .filter(item => !item.hide)
    .map(item => omit(item, "hide") as Omit<NavItem, "hide">);
