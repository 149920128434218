import React from "react";
import { Button } from "@netmedi/design-system";
import { FormattedMessage } from "react-intl";
import { Modal, ModalNav } from "./Modal.styles";
import { TitleAndBody, Dismiss } from "./Modal.types";

export type ConfirmProps = Partial<TitleAndBody> & {
  type: "confirm";
  /** Function to be executed when confirm-button is pressed */
  confirm: () => void;
  /** Text to be shown on the confirm-button */
  confirmText?: string;
  /** Text to be shown on the dismiss-button */
  dismissText?: string;
  hideDismiss?: boolean;
};

/** Confirm modal shows the user a text that can be confirmed or dismissed. */
const Confirm = ({
  confirm,
  dismiss,
  title,
  body,
  confirmText,
  dismissText,
  hideDismiss,
}: Omit<ConfirmProps, "type"> & Dismiss) => {
  return (
    <Modal onClick={(e: any) => e.stopPropagation()}>
      {title && <h3>{title}</h3>}
      {body}
      <ModalNav>
        {!hideDismiss && (
          <Button size="small" type="accent" onClick={dismiss}>
            <FormattedMessage id={dismissText || "modal.dismiss"} />
          </Button>
        )}
        <Button
          size="small"
          onClick={() => {
            dismiss();
            confirm();
          }}
        >
          <FormattedMessage id={confirmText || "modal.confirm"} />
        </Button>
      </ModalNav>
    </Modal>
  );
};

export default Confirm;
