/* eslint-disable max-lines */
import { uniqBy } from "lodash";
import dayjs from "dayjs";
import {
  CanvasElement,
  Content,
  ContentCanvas,
  ContentImage,
  ContentStack,
  ContentTable,
  ContentText,
  TDocumentDefinitions,
} from "pdfmake/interfaces";
import { IntlShape } from "react-intl";
import { i18nTranslate } from "shared/utils/translation";
import store from "store";
import theme from "@netmedi/design-system/dist/themes/baseTheme";
import { FieldData } from "../ValuePage/ValuePage";

const QUESTION_COLUMN_WIDTH = 120;

export interface SymptomComparisonPdfData {
  symptomChartData: SymptomChartData;
  valueChartData: ValueChartData;
  comparisonTableData: ComparisonTableData;
}

export interface SymptomChartData {
  images: string[]; // As dataUrl strings
}
export interface ValueChartData {
  valueData: FieldData;
  images: { [fieldId: string]: string }; // As dataUrl strings
}

export interface ComparisonTableData {
  questions: InputFormQuestions;
  answers: InputFormAnswerGroup;
}

export interface InputFormAnswer {
  created_at: string;
  data_points: {
    field_id: string;
    choice?: number;
    text?: string;
    number?: number;
    datetime?: string;
  }[];
  input_form: {
    external_id: string;
  };
}
export interface Question {
  type: string;
  name: string;
  grades?: {
    definition: {
      [grade: number]: string;
    };
  };
  first_positive?: boolean;
}
export interface InputFormQuestions {
  [formExternalId: string]: {
    title: string;
    questions: {
      [questionId: string]: Question;
    };
  };
}
export interface InputFormAnswerGroup {
  [id: number]: InputFormAnswer[];
}

const ellipse = (color: string): CanvasElement => ({
  type: "ellipse",
  x: 6,
  y: 6,
  r1: 6,
  r2: 6,
  color,
});

const scoreMarkerCircle = (
  question: Question,
  choice?: number,
): ContentCanvas => {
  if (!question.grades?.definition || choice === undefined) {
    return {
      canvas: [ellipse(theme.colors.gray500)],
    };
  }
  const symptomColours = [
    theme.colors.jade300,
    theme.colors.green300,
    theme.colors.yellow300,
    theme.colors.red300,
    theme.colors.brick300,
  ];

  if (question.first_positive === false) {
    symptomColours.reverse();
  }

  const choices = Object.keys(question.grades.definition);
  const choiceIndex = choices.indexOf(choice?.toString() ?? -1);
  const choiceCount = choices.length;
  const normalizedChoice = Math.ceil(
    (choiceIndex / (choiceCount - 1)) * symptomColours.length,
  );

  return {
    canvas: [
      ellipse(
        symptomColours[normalizedChoice] ||
          symptomColours[symptomColours.length - 1],
      ),
    ],
  };
};

class SymptomComparisonPdf {
  intl: IntlShape;
  symptomChartData: SymptomChartData;
  valueChartData: ValueChartData;
  comparisonTableData: ComparisonTableData;

  constructor(
    intl: IntlShape,
    {
      symptomChartData,
      valueChartData,
      comparisonTableData,
    }: SymptomComparisonPdfData,
  ) {
    this.intl = intl;
    this.symptomChartData = symptomChartData;
    this.valueChartData = valueChartData;
    this.comparisonTableData = comparisonTableData;
  }

  // Main definition for the PDF
  definition(): TDocumentDefinitions {
    const valueCount = Object.keys(this.valueChartData.valueData || []).length;

    return {
      pageSize: "A4",
      content: [
        ...this.symptomCharts(),
        ...(valueCount > 0 ? this.valueCharts() : []),
        this.comparisonTableHeader(),
        ...this.comparisonTables(),
      ],
      styles: {
        h1: {
          fontSize: 18,
          bold: true,
          margin: [0, 10, 0, 10], // L, T, R, B
        },
        h2: {
          fontSize: 14,
          bold: true,
          margin: [0, 7, 0, 7], // L, T, R, B
        },
        tableStyles: {
          margin: [0, 5, 0, 15], // L, T, R, B
        },
        tableHeader: {
          bold: true,
          fontSize: 13,
          color: theme.colors.black,
        },
        chapter: {
          margin: [0, 0, 0, 7], // L, T, R, B
        },
        bodyFaint: {
          fontSize: 10,
          color: theme.colors.gray800,
        },
      },
      defaultStyle: {
        font: "Arial",
        preserveLeadingSpaces: true, // don't want to strip white space in text
      },
      pageMargins: [20, 20, 20, 20], // L, T, R, B
    };
  }

  // Returns symptom chart page definitions.
  symptomCharts() {
    const t = i18nTranslate(this.intl);
    const state = store.getState();
    const { firstName, lastName } = state.client.basic_details;
    const title = `${lastName}, ${firstName} - ${t(
      "pdf_export.symptoms.symptom_overview_title",
    )}`;
    const { images } = this.symptomChartData;
    const symptomPageNo = (page: number) =>
      images.length > 1 ? `(${page}/${images.length})` : "";

    return images.flatMap((imgData, i): Content[] => [
      {
        text: `${title} ${symptomPageNo(i + 1)}`,
        style: "h1",
        pageBreak: i > 0 ? "before" : undefined,
      } as ContentText,
      {
        image: imgData,
        width: 550,
      } as ContentImage,
    ]);
  }

  // Returns value chart page definitions.
  valueCharts() {
    const t = i18nTranslate(this.intl);
    const { images, valueData } = this.valueChartData;

    return [
      {
        testId: "value-page", // Content doesn't include the testId field but added to help testing.
        text: t("charts.values"),
        style: "h1",
        pageBreak: "before",
      } as ContentText,
      ...Object.keys(valueData).flatMap((fieldId): Content[] => [
        {
          testId: "value-chart",
          unbreakable: true,
          stack: [
            {
              text: valueData[fieldId].title,
              style: "h2",
            } as ContentText,
            {
              text: valueData[fieldId].description,
              style: "bodyFaint",
            } as ContentText,
            {
              image: images[fieldId],
              width: 550,
            } as ContentImage,
          ],
        } as ContentStack,
      ]),
    ];
  }

  comparisonTableHeader() {
    const t = i18nTranslate(this.intl);
    const title = t("pdf_export.symptoms.symptom_questionnaires_title");
    const infoMessage = t("pdf_export.symptoms.symptom_questionnaires_info");

    return [
      {
        text: title,
        style: "h1",
        pageBreak: "before",
      },
      {
        text: infoMessage,
        style: "chapter",
      },
    ] as ContentText[];
  }

  // returns definition for all comparison tables
  comparisonTables(): Content[][] {
    const { answers: answersByInputForm } = this.comparisonTableData;

    return Object.keys(answersByInputForm).map((formId, i) => {
      const answers = answersByInputForm[+formId] as InputFormAnswer[];
      return [
        // Add page break between tables
        ...((i > 0
          ? [{ text: "", pageBreak: "before" }]
          : []) as ContentText[]),
        ...this.comparisonTableDefinition(answers),
      ];
    });
  }

  // returns definition for a single comparison table
  comparisonTableDefinition(answers: InputFormAnswer[]) {
    const t = i18nTranslate(this.intl);
    const { questions } = this.comparisonTableData;
    const form_external_id = answers[0].input_form.external_id;
    const formData = questions[form_external_id];
    const titles = [
      "",
      ...answers.map(a => dayjs(a.created_at).format(t("local_format.date"))),
    ].map(c => ({
      text: c,
      bold: true,
      fillColor: theme.colors.gray500,
    }));

    const rows = this.formatComparisonRows(answers, formData);
    const answerColumnWidth = 395 / (titles.length - 1);

    return [
      {
        text: t("pdf_export.symptoms.questionnaire_title", {
          name: formData.title,
        }),
        style: "h2", // Style defined in top level at comparisonPdfDefinition
      } as ContentText,
      {
        style: "tableStyles",
        table: {
          widths: [
            QUESTION_COLUMN_WIDTH,
            ...Array(titles.length - 1).fill(answerColumnWidth),
          ],
          headerRows: 1,
          body: [
            titles,
            // Rows
            ...rows,
          ],
        },
        layout: {
          defaultBorder: false,
        },
      } as ContentTable,
    ] as Content[];
  }

  formatComparisonRows(
    answers: InputFormAnswer[],
    formData: { title: string; questions: { [questionId: string]: Question } },
  ): Content[] {
    const allAnswers = uniqBy(
      answers.flatMap(a => a.data_points),
      "field_id",
    );

    const rows: { [fieldId: string]: (ContentText | ContentStack)[] } = {};
    allAnswers
      .filter(
        ans =>
          formData.questions[ans.field_id] &&
          formData.questions[ans.field_id].name !== "" &&
          formData.questions[ans.field_id].type !== "MultipleValue",
      )
      .forEach((a, rowIndex) => {
        const question = formData.questions[a.field_id] as Question;
        const fillColor =
          rowIndex % 2 === 0 ? theme.colors.gray300 : theme.colors.gray50;
        const cellMargin = [2, 6]; // Horizontal, Vertical

        const row = [
          // Question cell
          {
            text: question?.name,
            margin: cellMargin,
            bold: true,
            fillColor,
          } as ContentText,

          // Answer cells
          ...answers.map((t, colIndex) => {
            const dataPoint = t.data_points.find(
              dp => dp.field_id === a.field_id,
            );
            const choice = dataPoint?.choice as number;
            const number = dataPoint?.number;
            const text = dataPoint?.text;

            const cellText = (
              question?.grades?.definition?.[choice] ??
              number ??
              text ??
              "-"
            )
              .toString()
              // Add zero width spaces to long words to allow breaking words
              // Otherwise the cells may grow when the text contains long words
              .replace(/(\w{20})/g, "$1\u200b");

            const formatCellText = (text: string) => `    ${text}`;
            const fillOpacity = colIndex % 2 === 0 ? 0.7 : 1;

            return {
              fillColor,
              fillOpacity,
              stack: [
                scoreMarkerCircle(question, choice),
                {
                  text: formatCellText(cellText),
                  margin: [2, -12, 0, 8], // L, T, R, B
                } as ContentText,
              ],
              margin: cellMargin,
            } as ContentStack;
          }, {}),
        ];

        rows[a.field_id] = row;
      });

    // iterate over questions to get the order from the questions
    return Object.keys(formData.questions)
      .map(q => rows[q])
      .filter(a => a);
  }
}

export default SymptomComparisonPdf;
