import React from "react";
import { ValidInterval } from "./SymptomTable";
import { Button } from "@netmedi/design-system";
import { ControlButton, Controls } from "./SymptomTable.styles";
import { FormattedMessage } from "react-intl";
import dayjs, { Dayjs } from "dayjs";

interface IProps {
  onChange(params: { ending: Dayjs }): void;
  ending: Dayjs;
  interval: ValidInterval;
  firstItem: Dayjs;
  lastItem: Dayjs;
  itemsWidth: number;
}

const SymptomTableControls = (props: IProps) => {
  const { onChange, ending, interval, firstItem, lastItem, itemsWidth } = props;
  const prev = dayjs(ending.clone()).subtract(1, interval);
  const next = dayjs(ending.clone()).add(1, interval);

  return (
    <FormattedMessage id={`symptom_table.date_intervals.${interval}`}>
      {interval_name => (
        <Controls style={{ width: (itemsWidth + 1) * 62 }}>
          <ControlButton $left>
            <FormattedMessage id="modal.images_modal.previous">
              {previous_label => (
                <Button
                  size="small"
                  disabled={!prev.isAfter(dayjs(firstItem).startOf(interval))}
                  onClick={() => onChange({ ending: prev })}
                  aria-label={`${previous_label} ${interval_name}`}
                >
                  {"<"}
                </Button>
              )}
            </FormattedMessage>
          </ControlButton>
          <ControlButton $right>
            <FormattedMessage id="modal.images_modal.next">
              {next_label => (
                <Button
                  size="small"
                  disabled={next.isAfter(dayjs(lastItem).endOf(interval))}
                  onClick={() => onChange({ ending: next })}
                  aria-label={`${next_label} ${interval_name}`}
                >
                  {">"}
                </Button>
              )}
            </FormattedMessage>
          </ControlButton>
        </Controls>
      )}
    </FormattedMessage>
  );
};

export default SymptomTableControls;
