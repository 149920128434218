import styled from "styled-components";
import rtl from "styled-components-rtl";
import { Header } from "common/components/Page";
import { Badge, Icon } from "@netmedi/design-system";
import DataTable from "../../../common/components/DataTable";
import { Sources } from "common/components/SymptomGradeCircle";
import {
  colors,
  scaffolding,
  typography,
  spacing,
  mediaQueries,
} from "@netmedi/design-system/dist/styles/variables";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import {
  mediaLandscape,
  mediaMax,
} from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";

export const PopulationDashboardBadge = styled(Badge)`
  position: absolute;
  top: 0;
  ${rtl`
    right: 0;
    transform: translate(90%, -35%);
  `}
`;

export const DashboardContainer = styled.div`
  display: block;
  overflow: auto;
  width: 100%;
  overflow-x: scroll;
  ${mediaLandscape} {
    ${mediaMax(mediaQueries.medium)} {
      min-height: 100vh;
    }
  }

  /* No need to show scrollbars with tablets or mobile devices */
  @media (min-width: ${mediaQueries.large}px) {
    /* OSX by default hides scrollbars when not in use, so manually making the horizontal one show here below. */
    /* This doesn't work for Firefox on OSX (since not possible to force it there), but think that is acceptable. */
    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 8px;
      border: 2px solid white;
      background-color: rgba(0, 0, 0, 0.5);
    }
    &::-webkit-scrollbar-track {
      background-color: #fff;
      border-radius: 8px;
    }
  }
`;

export const PopulationDashboardDataTable = styled(DataTable)`
  margin-top: 0;
  border-bottom: 1px solid ${colors.gray300};
  th {
    text-align: center;
    font-weight: ${scaffolding.bold};
    font-size: ${typography.fontSizeSmall};
    line-height: ${typography.fontSizeSmall};
    cursor: pointer;
    z-index: 1;
    max-width: 160px;
    hyphens: auto;
    &:hover {
      background-color: ${colors.gray300};
    }
  }

  th,
  tr:nth-child(even) {
    background-color: ${colors.gray50};
  }

  td {
    border-top: none;
    text-align: center;
  }
`;

export const DropDown = styled.div.attrs(() => ({
  "data-testid": "pd-dropdown",
}))`
  width: 350px;
  padding: 2px 10px;
  @media (max-width: 470px) {
    max-width: 200px;
    flex-grow: 1;
  }
`;

export const ControlPanel = styled.div`
  width: 100%;
  min-height: 42px;
  background-color: ${colors.gray50};
  border-top: 1px solid ${colors.gray300};
  border-bottom: 1px solid ${colors.gray300};
  padding: ${spacing.xxs}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const DashboardHeader = styled(Header)`
  border: 0;
  margin-bottom: 0;
  ${rtl`margin-left: ${spacing.xxs}px;`}
`;

export const PersonColumn = styled.div`
  ${rtl`text-align: left;`}
`;

export const SymptomsTooltip = styled.div.attrs(() => ({
  "data-testid": "other-symptoms-tooltip",
}))`
  padding-top: 10px;
  max-height: 40vh;
  overflow: auto;
`;

export const OtherWrapper = styled.div.attrs(() => ({
  "data-testid": "pd-other-wrapper",
}))`
  position: relative;
  display: inline-block;
`;

export const SymptomIcon = styled(Icon)`
  ${rtl`margin-right: 8px;`}
`;

export const StyledSources = styled(Sources)`
  margin-bottom: 5px;
  h5 {
    font-size: ${rem(15)};
    margin-top: 0;
  }

  hr {
    display: block;
    overflow: hidden;
    border: 0.5px solid white;
    margin-bottom: 10px;
  }
`;

export const SymptomTooltipSources = styled(StyledSources)`
  margin-bottom: 40px;
`;

export const Left = styled.div.attrs(() => ({
  "data-testid": "pd-left",
}))`
  display: flex;
  flex-wrap: wrap;
  > * {
    ${rtl`margin-right: ${spacing.xs}px;`}
  }
`;

export const Right = styled.div.attrs(() => ({
  "data-testid": "pd-right",
}))`
  display: flex;
  flex-wrap: wrap;
`;

export const Field = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  input[type="radio"] {
    margin: 0 10px;
  }

  label {
    background: none;
    ${rtl`margin-right: 10px;`}
  }

  .choices {
    display: flex;
    flex-wrap: wrap;
  }
`;

export const PopulationDashboardFeedbackButton = styled.div`
  ${rtl`float: right;`}
  margin: 1.5rem;
  a {
    border-radius: 20px;
    text-transform: none;
    font-size: ${typography.fontSizeSmall};
  }
  @media (max-width: ${mediaQueries.small}px) {
    float: initial;
    margin: 0 ${spacing.xs}px;
    a {
      font-size: ${typography.fontSizeXsmall};
    }
  }
`;

export const NewTabIcon = styled(Icon)`
  ${rtl`margin-left: ${spacing.xxs}px;`}
  margin-top: 2px;
`;

export const UserInfoContainer = styled.div`
  flex-basis: 100%;
`;

export const InfoWithLabelsContainer = styled.div`
  flex-wrap: wrap;
  display: block;

  > a,
  .emailContainer {
    display: inline-block;
    vertical-align: middle;
    ${rtl`margin-right: 0.5rem;`}
  }
`;

export const TpDropdownItem = styled.div`
  span:nth-child(1) {
    ${rtl`margin-right: ${spacing.xxs}px;`}
  }

  span:nth-child(2) {
    margin-bottom: 3px;
  }
`;
