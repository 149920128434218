import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import {
  Button,
  mediaQueryMixins,
  styleVariables,
} from "@netmedi/design-system";
import { PhoneInput } from "common/components/Form";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import { FormFieldInput } from "common/components/Form/FormField.styles";

const { sitelessBaseColors, spacing, mediaQueries, baseColors } =
  styleVariables;
const { mediaMax } = mediaQueryMixins;

export const Container = styled.div`
  display: flex;
  flex-direction: row;

  ${mediaMax(mediaQueries.small)} {
    flex-direction: column;
  }
`;

export const Content = styled.div<{ alignSelf?: string }>`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-self: ${({ alignSelf }) => alignSelf || "start"};
`;

export const Heading = styled.h1`
  font-size: ${rem(18)};
  font-weight: 500;
  margin-bottom: 28px;
`;

export const WordBetweenLines = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 16px;
  font-weight: 500;
  align-self: stretch;

  ${mediaMax(mediaQueries.small)} {
    flex-direction: row;
    justify-content: space-evenly;
    padding: 0;
  }

  p {
    margin: 0.5em;
    padding: 0;
  }
`;

export const Line = styled.hr`
  width: 1px;
  display: inline-block;
  height: 100%;
  margin: 0;

  ${mediaMax(mediaQueries.small)} {
    width: 100%;
    height: 1px;
    margin: 0;
  }
`;

export const QRCodeContainer = styled.div`
  border: 1px solid ${sitelessBaseColors.blue300};
  border-radius: 4px;
  align-self: center;
  justify-self: center;
  justify-content: center;
  align-items: center;
  padding: ${spacing.xs}px;
  display: flex;
`;

export const SpeechBubble = styled.div`
  position: relative;
  padding: 5px;
  background: ${sitelessBaseColors.yellow300};
  border-radius: 4px;
  width: 140px;
  text-align: center;
  align-self: center;
  margin-bottom: 20px;
  font-size: ${rem(12)};

  &:after {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    border-width: 10px;
    border-style: solid;
    border-color: ${sitelessBaseColors.yellow300} transparent transparent
      transparent;
    bottom: -20px;
    ${rtl`left: 60px;`}
  }
`;

export const Label = styled.label`
  margin-bottom: ${spacing.xs}px;
`;

export const LinkDescription = styled.span`
  margin: 0 ${spacing.xxs}px;
  font-weight: 500;
  font-size: ${rem(18)};
`;

export const BottomDescription = styled.p`
  color: ${baseColors.gray900};
  font-size: ${rem(14)};
  margin-top: ${spacing.s}px;
`;

export const Description = styled.p`
  font-size: ${rem(16)};
  margin: 0;
  margin-bottom: 24px;
`;

export const EmailInput = styled(FormFieldInput).attrs(() => ({
  type: "email",
  id: "email",
}))<{ borderColor?: string }>`
  border: 1px solid
    ${({ borderColor }) => borderColor || sitelessBaseColors.gray700};
  border-radius: 4px;
  height: 42px;
  padding: 0 ${spacing.xxs}px;
`;

export const PhoneInputStyled = styled(PhoneInput).attrs(
  ({ readOnly }: { readOnly: boolean }) => ({
    readOnly: readOnly || false,
  }),
)<{
  borderColor?: string;
}>`
  border: 1px solid
    ${({ borderColor }) => borderColor || sitelessBaseColors.gray700};
  border-radius: 4px;
`;

export const SendStatusContainer = styled.div<{
  color: string;
}>`
  align-self: center;
  margin: 8px 0;

  ${({ color }) => `
    color: ${color};
  `}
`;

type CustomButtonProps = {
  position?: string;
  top?: number;
  right?: number;
  display?: string;
  alignItems?: string;
  alignSelf?: string;
  margin?: number;
  padding?: number;
  marginBottom?: number;
  marginTop?: number;
  width?: string;
  color?: string;
};

const isNumber = (value: any) => typeof value === "number";

export const CustomButton = styled(Button)<CustomButtonProps>`
  ${props => css`
    position: ${props.position || "static"};
    top: ${props.top || "0"}px;
    ${rtl`right: ${props.right || "0"}px;`}
    color: ${props.color};
    display: ${props.display || "block"};
    align-items: ${props.alignItems};
    align-self: ${props.alignSelf};

    ${isNumber(props.margin) ? `margin: ${props.margin}px;` : ""}
    ${isNumber(props.padding) ? `padding: ${props.padding}px;` : ""}
    ${isNumber(props.marginBottom)
      ? `margin-bottom: ${props.marginBottom}px;`
      : ""}
    ${isNumber(props.marginTop) ? `margin-top: ${props.marginTop}px;` : ""}
    ${props.width ? `width: ${props.width};` : ""}
  `}
`;

export const EmptyInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const EmptyInputMessage = styled.p`
  font-size: ${rem(14)};
  color: ${sitelessBaseColors.red700};
  margin: 0 4px;
`;
