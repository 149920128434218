import React from "react";
import ReactDOMServer from "react-dom/server";
import { Icon } from "@netmedi/design-system";
import MarkdownIt from "markdown-it";
import { RuleInline } from "markdown-it/lib/parser_inline";
import { RenderRule } from "markdown-it/lib/renderer";
import StateInline from "markdown-it/lib/rules_inline/state_inline";

const chars = [
  0x21 /* ! */, 105 /* i */, 99 /* c */, 111 /* o */, 110 /* n */, 0x5b /* [ */,
];

const isBeforeLabel = (state: StateInline) => {
  for (let i = 0; i < chars.length; i++) {
    if (state.src.charCodeAt(state.pos + i) !== chars[i]) {
      return true;
    }
  }
  return false;
};

// Get boundaries of the label (icon_id) in !icon[icon_id]
const getLabelBoundaries = (state: StateInline) => {
  const labelStart = state.pos + chars.length; // Start position of icon_id in !icon[icon_id]
  const labelEnd = state.md.helpers.parseLinkLabel(
    state,
    state.pos + chars.length - 1,
    false,
  ); // End position of icon_id in !icon[icon_id]

  return [labelStart, labelEnd];
};

// Process !icon[icon_id] markdown to markdown-it token
const parseIcon: RuleInline = (state, silent) => {
  if (isBeforeLabel(state)) return false;

  const [labelStart, labelEnd] = getLabelBoundaries(state);

  if (labelEnd < 0) return false; // parser failed to find ']', so it's not a valid icon

  // Create token object
  // silent -mode is used internally by markdown-it to verify whether the tag exists and
  // we should not output anything if it's enabled
  if (!silent) {
    const label = state.src.slice(labelStart, labelEnd);
    const token = state.push("reactIcon", "", 0);
    token.attrs = [["name", label]];
  }

  state.pos = labelEnd + 1;
  return true;
};

// Convert markdown-it token to react component and render it to html
const renderReactIcon: RenderRule = (tokens, idx) => {
  const token = tokens[idx];
  const component = (
    <Icon name={token.attrGet("name") as any} size="relative" />
  );
  return ReactDOMServer.renderToString(component);
};

const icon = (md: MarkdownIt) => {
  md.inline.ruler.push("icon", parseIcon);
  md.renderer.rules.reactIcon = renderReactIcon;
};

export default icon;
