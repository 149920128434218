import { Icon } from "@netmedi/design-system";
import React, { useState } from "react";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { NavItem } from "./navItem";
import {
  MobileFullNav,
  MobileNav,
  MobileNavList,
  MobileNavItem,
  StyledNavLink,
  NavItemLink,
  MobileMoreLink,
  MobileOverlay,
} from "./Mobile.styles";

const renderLink = (
  onClick: () => void,
  { badge, text, icon, to, href, spacingAfter: _ignore, ...linkProps }: NavItem,
  items: ReadonlyArray<NavItem>,
  navLink = false,
  intl: any,
  open: boolean,
) => {
  const textId = `app.${text}`;
  const commonProps = {
    ...linkProps,
    onClick,
    "aria-label": intl.formatMessage({ id: textId }),
  };

  const children = () => (
    <>
      {icon && <Icon name={icon} badge={badge} size="medium" />}
      {!navLink && <FormattedMessage tagName="label" id={textId} />}
    </>
  );

  return (
    <MobileNavItem key={text} navLink={navLink} itemsLength={items.length}>
      {to && (
        <StyledNavLink
          {...commonProps}
          to={to}
          className={(navLink && "navigation").toString()}
          activeClassName={open ? "" : "Mobile_active"}
        >
          {children()}
        </StyledNavLink>
      )}
      {!to && href && (
        <NavItemLink {...commonProps} href={href}>
          {children()}
        </NavItemLink>
      )}
    </MobileNavItem>
  );
};

type MobileProps = {
  readonly items: ReadonlyArray<NavItem>;
  readonly hiddenItems: ReadonlyArray<NavItem>;
} & WrappedComponentProps;

const Mobile = ({ items, hiddenItems, intl }: MobileProps) => {
  const [open, setOpen] = useState(false);

  const toggle = () => setOpen(!open);

  /**
   * Put rest of the navigation links behind menu button
   * (if there are any in 'hiddenItems')
   */
  const showRest = () => {
    if (hiddenItems.length > 0) {
      return (
        <div>
          <MobileMoreLink
            onClick={toggle}
            aria-label={intl.formatMessage({ id: "app.mobile_more" })}
            $open={open}
          >
            <Icon material name="kebab" size="medium" />
          </MobileMoreLink>
          {open && <MobileOverlay onClick={toggle} />}
          {open && (
            <MobileFullNav>
              <ul>
                {hiddenItems.map(
                  (item: NavItem, index: number, items: readonly NavItem[]) =>
                    renderLink(toggle, item, items, false, intl, false),
                )}
              </ul>
            </MobileFullNav>
          )}
        </div>
      );
    }
  };

  return (
    <MobileNav>
      <MobileNavList hiddenItems={hiddenItems.length === 0}>
        {items.map((item: NavItem, index: number, items: readonly NavItem[]) =>
          renderLink(() => setOpen(false), item, items, true, intl, open),
        )}
      </MobileNavList>
      {showRest()}
    </MobileNav>
  );
};

export default injectIntl(Mobile);
