import React from "react";
import {
  RemoveIcon,
  StarIcon,
  UserAvatar,
  UserContainer,
  InfoField,
  AdditionalNotes,
  NameAndLabels,
  LabelContainer,
  AvatarAndInfo,
  DomainSectionContainer,
  DomainTooltipContainer,
} from "./Person.styles";
import {
  FormattedMessage,
  WrappedComponentProps,
  injectIntl,
  IntlShape,
} from "react-intl";
import { TreatmentProgram } from "hcp/models/treatmentProgram";
import { Icon } from "@netmedi/design-system";
import { Typography } from "../Typography/Typography.styles";
import { TreatmentProgramLabel } from "common/components/UserInfo/TreatmentProgramLabels";
import { tooltip, rmTooltip } from "common/utils/tooltip";
import { User } from "common/models/user";
import { StaffMember } from "hcp/components/MonitoringAndCareTeam/MonitoringAndCareTeam.types";

export type PersonProps = {
  full_name: string;
  type?: string;
  id: string | number;
  avatar?: string | null;
  domains?: string[];
  person_id?: string | null;
  role?: string;
  status?: string | number;
  active?: boolean;
  birthdate?: any;
  own?: boolean | null;
  absence?: { end_date: string };
  substitute?: { full_name: string };
  inactivation_reason?: string;
};

type PersonComponentProps = {
  person: PersonProps; //person displayed
  showDomains: boolean; //true/false are domains displayed
  user: Pick<User | StaffMember, "id" | "type">; //current user
  compact?: boolean; //true/false compact mode without avatar
  treatmentPrograms?: TreatmentProgram[]; //treatmentPrograms for displaying labels
  onRemove?: (u: PersonProps) => void; //function called when removing user
  onClick?: () => void; //function to call when clicking the Person
  linkHref?: string; //url to navigate to when clicking the name
  linkTo?: () => void; //react-router route to navigate to when clicking the name
  userLink?: React.ReactNode;
} & WrappedComponentProps;

export const setLabelTooltip = (e: React.MouseEvent, title: string) =>
  tooltip({
    title,
    target: e.target,
    position: "top",
  });

export const setDomainTooltip = (
  e: React.MouseEvent,
  title: string,
  content: JSX.Element,
) =>
  tooltip({
    title,
    target: e.target,
    position: "top",
    content: content,
    autoWidth: true,
  });

const renderDomainList = (
  intl: IntlShape,
  domains: string[] | undefined,
  id: string,
) => {
  const domainList = domains?.map(d =>
    domains.indexOf(d) === 0
      ? intl.formatMessage({ id: `clients.domains.${d}` })
      : ", " + intl.formatMessage({ id: `clients.domains.${d}` }),
  );

  return (
    <DomainSectionContainer
      id={`domains-${id}`}
      onClick={e =>
        setDomainTooltip(
          e,
          intl.formatMessage({ id: `people.fields.domains` }),
          domainContent(domains, intl),
        )
      }
    >
      {domainList}
    </DomainSectionContainer>
  );
};

const domainContent = (domains: string[] | undefined, intl: IntlShape) => {
  return (
    <DomainTooltipContainer>
      <ul>
        {domains?.map(d => (
          <li key={d}>
            <div title={intl.formatMessage({ id: `clients.domains.${d}` })}>
              {intl.formatMessage({ id: `clients.domains.${d}` })}
            </div>
          </li>
        ))}
      </ul>
    </DomainTooltipContainer>
  );
};

const treatmentProgramLabels = (treatmentPrograms: TreatmentProgram[]) =>
  treatmentPrograms.length !== 0 &&
  treatmentPrograms.map(tp => {
    if (tp.type !== "PersonalTreatmentProgram") {
      return (
        <TreatmentProgramLabel
          key={tp.id}
          treatment_program={tp}
          setTooltip={e => setLabelTooltip(e, tp.name)}
          clearTooltip={rmTooltip}
        />
      );
    }
  });

const absentDisclaimer = (name?: string, date?: string) => {
  return (
    <AdditionalNotes>
      <Icon id="icon" name="access_time_16px" size="small" />
      <div id="inactive-text">
        {date && name ? (
          <FormattedMessage
            id="person.substitute"
            values={{
              name: name,
              date: date,
              br: <br />,
            }}
          />
        ) : (
          <FormattedMessage id="client.staff.on_vacation" />
        )}
      </div>
    </AdditionalNotes>
  );
};

const inactiveDisclaimer = (reason?: string) => (
  <AdditionalNotes>
    <span id="inactive">
      <Icon id="icon" name="subtract_circle_16px" size="small" />
      {reason ? (
        <FormattedMessage
          id="person.inactive_with_reason"
          values={{ reason: reason }}
        />
      ) : (
        <FormattedMessage id="person.inactive" />
      )}
    </span>
  </AdditionalNotes>
);

const BasicInfoSection = (props: PersonComponentProps) => {
  const { person, treatmentPrograms, linkHref, userLink } = props;
  return (
    <span>
      <InfoField>
        <NameAndLabels>
          {nameAndLabels(person, linkHref, userLink)}
          <LabelContainer>
            {treatmentPrograms && treatmentProgramLabels(treatmentPrograms)}
          </LabelContainer>
        </NameAndLabels>
        <AdditionalInfoSection {...props} />
      </InfoField>
    </span>
  );
};

const nameAndLabels = (
  person: PersonProps,
  linkHref?: string,
  userLink?: React.ReactNode,
) => (
  <span>
    <div data-testid={"person-component-info"}>
      {userLink ? (
        userLink
      ) : linkHref ? (
        <a href={linkHref}>
          <strong>{person.full_name}</strong>
        </a>
      ) : (
        <strong>{person.full_name}</strong>
      )}
    </div>
    <div data-testid={"person-label"}>
      {person.type === "Client"
        ? person.person_id
          ? person.person_id
          : person.birthdate
            ? person.birthdate
            : person.role
        : person.role}
    </div>
  </span>
);

const AdditionalInfoSection = (props: PersonComponentProps) => {
  const { showDomains, person } = props;
  const { substitute, absence } = person;
  return (
    <div>
      {showDomains &&
        renderDomainList(props.intl, person.domains, String(person.id))}
      {substitute && absentDisclaimer(substitute?.full_name, absence?.end_date)}
      {person.status
        ? person.status === "inactive" &&
          inactiveDisclaimer(person.inactivation_reason)
        : person.active !== undefined &&
          !person.active &&
          inactiveDisclaimer(person.inactivation_reason)}
    </div>
  );
};

const PersonForStaff = (props: PersonComponentProps) => {
  const { person, onRemove, onClick } = props;
  return (
    <Typography bodyText="small">
      <UserContainer onClick={() => (onClick ? onClick() : {})}>
        <AvatarAndInfo>
          {person.own && <StarIcon name="star" />}
          <UserAvatar user={{ ...person }} size="large" />
          <BasicInfoSection {...props} />
        </AvatarAndInfo>
        {onRemove && (
          <div
            data-testid="remove-person-icon"
            onClick={() => onRemove && onRemove(person)}
          >
            <RemoveIcon name="remove_16px" />
          </div>
        )}
      </UserContainer>
    </Typography>
  );
};

const PersonForPatient = (props: PersonComponentProps) => {
  const { person, onClick, compact } = props;
  const { substitute, absence } = person;

  return (
    <Typography bodyText="medium">
      <UserContainer onClick={() => (onClick ? onClick() : {})}>
        <AvatarAndInfo>
          {!compact && <UserAvatar user={person} size="large" />}
          <InfoField>
            <div>
              <strong>{person.full_name}</strong>
            </div>
            {substitute ? (
              absentDisclaimer(substitute.full_name, absence?.end_date)
            ) : (
              <div id="role">{person.role}</div>
            )}
          </InfoField>
        </AvatarAndInfo>
      </UserContainer>
    </Typography>
  );
};
const Person = (props: PersonComponentProps) => {
  const { user, compact } = props;

  return !(user && user.type === "Client") && !compact
    ? PersonForStaff({ ...props })
    : PersonForPatient({ ...props });
};

export default injectIntl(Person);
