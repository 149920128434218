import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { connect, ConnectedProps } from "react-redux";
import Navigation from "client/components/Navigation";
import ModalProvider from "shared/components/Modal";
import TooltipProvider from "common/components/Tooltip";
import GlobalFontsProvider from "common/components/GlobalFontsProvider";
import { ErrorBoundary } from "common/utils/errorBoundary";
import { getEmbeddableViews } from "client/actions/client";
import { RootState } from "store";
import { GlobalStyle } from "./Global.styles";
import {
  StyledApp,
  StyledToMainLink,
  StyledRightContainer,
} from "./App.styles";
import { mediaQueries } from "@netmedi/design-system/dist/styles/variables";
import { ProfileSelector } from "../ProfileSelector/ProfileSelector";
import { showProfileSelectorToUser } from "../ProfileSelector/helpers";

const mapStateToProps = ({ app, user, chat, client }: RootState) => ({
  app,
  user,
  messages: chat?.conversations.unread,
  embeddable_views: client.embeddable_views,
});
const mapDispatchToProps = { getEmbeddableViews };

export type AppProps = ConnectedProps<typeof connector> & {
  children?: React.ReactElement;
};

export const App = ({
  app,
  user,
  messages,
  embeddable_views,
  children,
  getEmbeddableViews,
}: AppProps) => {
  useEffect(() => {
    getEmbeddableViews();
  }, []);

  const useMobileNav = window.innerWidth < mediaQueries.medium;
  const { show_mobile_nav, hide_navigation } = app;

  const showNavigation = (!useMobileNav || show_mobile_nav) && !hide_navigation;

  if (showProfileSelectorToUser(user)) {
    return (
      <StyledApp>
        <GlobalStyle />
        <ErrorBoundary>
          <ProfileSelector />
        </ErrorBoundary>
        <GlobalFontsProvider />
      </StyledApp>
    );
  }

  return (
    <StyledApp>
      <GlobalStyle />
      {!hide_navigation && (
        <StyledToMainLink href="#content">
          <FormattedMessage id="app.bypass" />
        </StyledToMainLink>
      )}

      {showNavigation && (
        <Navigation
          isMobile={useMobileNav}
          user={user}
          messages={messages}
          embeddableViews={embeddable_views}
        />
      )}
      <StyledRightContainer
        desktop={!useMobileNav}
        desktopNavHidden={!!hide_navigation}
        mobileNavHidden={!showNavigation}
        id="content"
        role="main"
        aria-live="polite"
      >
        <ErrorBoundary>{children}</ErrorBoundary>
      </StyledRightContainer>
      <ModalProvider />
      <TooltipProvider />
      <GlobalFontsProvider />
    </StyledApp>
  );
};

const connector = connect(mapStateToProps, mapDispatchToProps);
export default connector(App);
