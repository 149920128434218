import { flatten } from "lodash";
import cn from "classnames";
import React from "react";
import { Field } from "react-final-form";
import { FormattedMessage } from "react-intl";

import {
  ErrorMsg,
  TopContent,
  Wrapper,
  FormFieldLabel,
  FormFieldInput,
  FORMFIELD_CHILD_CLASS,
  FORMFIELD_TOPLABEL_CLASS,
  FieldRequired,
} from "./FormField.styles";

export const PlainFormField = (props: PlainFormFieldProps) => {
  const { ...input } = props.input || {};
  return (
    <Field name={props.name}>
      {fieldProps => (
        <FormField {...fieldProps} {...props}>
          <FormFieldInput
            {...input}
            submitFailed={props?.meta?.submitFailed}
            aria-describedby={props?.ariaDescribedby}
            touched={props?.meta?.touched}
            disabled={props.disabled}
            autoComplete={props.autoComplete}
            inputMode={props.inputMode}
            type={props.type}
            passwordNeeded={props.passwordNeeded}
          />
        </FormField>
      )}
    </Field>
  );
};

/**
 * Wrapper component for form fields. The contained component should be passed as a child.
 */
// eslint-disable-next-line max-statements
const FormField = (props: FormFieldProps) => {
  const { label, ...input } = props.input || {};
  const LabelEl = input.inactiveLabel || props.inactiveLabel ? "div" : "label"; // can't use label for some field types like the phone input
  const child = input.children || props.children;
  const labelTranslationId =
    label || props.label || `attributes.${props.name || input.name}`;
  const hideLabel = props.hideLabel;
  const showRequiredIndicator = props.showRequiredIndicator;
  const contentOnTopPlacing = props.contentOnTopPlacing ?? "aboveLabel";
  const name = props.name || input.name;
  const errorsId = `${name}_errors`;

  const error = props.meta?.error;
  const touched = props.meta?.touched;
  const submitError = props.meta?.submitError;
  const submitFailed = props.meta?.submitFailed;
  const errors = [touched && error, submitError].filter(Boolean);

  const fieldEl = child
    ? React.cloneElement(child, {
        className: cn(child.props?.className, FORMFIELD_CHILD_CLASS, {
          [FORMFIELD_TOPLABEL_CLASS]: props.labelOnTop,
        }),
        "aria-required": props.required,
        "aria-describedby": errorsId,
        "aria-invalid": errors.length ? true : undefined,
        ...input,
      })
    : undefined;

  return (
    <Wrapper
      id={`FormField_${name}`}
      error={error || submitError}
      submitFailed={submitFailed}
      touched={touched}
      wrapTopLabel={props.labelOnTop}
      labelWidth={props.labelWidth}
    >
      {props.labelOnTop && contentOnTopPlacing === "aboveLabel" && (
        <TopContent>{props.contentOnTop}</TopContent>
      )}
      <FormFieldLabel as={LabelEl} labelTopLabel={props.labelOnTop}>
        {props.required && showRequiredIndicator && (
          <FieldRequired aria-hidden>*&nbsp;</FieldRequired>
        )}
        {!hideLabel &&
          (label ? (
            label
          ) : (
            <FormattedMessage id={labelTranslationId}>
              {txt => <span data-form-field-label>{txt}</span>}
            </FormattedMessage>
          ))}
        {props.labelOnTop && contentOnTopPlacing === "belowLabel" && (
          <TopContent>{props.contentOnTop}</TopContent>
        )}
        {fieldEl}
      </FormFieldLabel>
      {!!errors.length && (
        <ErrorMsg id={errorsId}>
          {flatten([errors]).map((e, i) => (
            <div key={i}>{e}</div>
          ))}
        </ErrorMsg>
      )}
    </Wrapper>
  );
};

type PlainFormFieldProps = {
  name: string;
  type?: string;
  label?: React.ReactChild;
  hideLabel?: boolean;
  /** Whether the label is on top-left of the field instead of the left side. */
  labelOnTop?: boolean;
  /** Whether the field is disabled or not. */
  disabled?: boolean;
  meta?: any;
  input?: any;
  required?: boolean;
  showRequiredIndicator?: boolean;
  className?: string;
  autoComplete?: string;
  inputMode?: string;
  /** Content like text or icons that is added to the top-right of the field. Can be added only if the field has a label on top. */
  contentOnTop?: React.ReactNode;
  ariaDescribedby?: string;
  /** Whether the field is locked if password not given. We want these fields to look different from normal disabled fields */
  passwordNeeded?: boolean;
};

export type FormFieldProps = {
  name?: string;
  type?: string;
  label?: React.ReactChild;
  inactiveLabel?: boolean;
  /** Whether the label is on top-left of the field instead of the left side. */
  labelOnTop?: boolean;
  labelWidth?: number; // in %, only when labelOnTop is false
  /** Content like text or icons that is added to the top-right of the field. Can be added only if the field has a label on top. */
  contentOnTop?: React.ReactNode;
  hideLabel?: boolean;
  children: React.ReactElement;
  /** Whether the field is disabled or not. */
  disabled?: boolean;
  meta?: any;
  input?: any;
  required?: boolean;
  showRequiredIndicator?: boolean;
  className?: string;
  autoComplete?: string;
  contentOnTopPlacing?: "aboveLabel" | "belowLabel";
};

export default FormField;
