import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonExtras } from "@netmedi/design-system";
const { LinkButton } = ButtonExtras;
import { getMessageContent } from "common/actions/flash";
import { setShowAppDownloadModal } from "shared/actions/app";
import { openMobileAppDeepLink } from "common/actions/user";
import { User } from "common/models/user";
import LocalizedMarkdown from "common/components/Markdown/LocalizedMarkdown";
import { SiteSettings } from "common/utils/holvikaari";
import { getInfoBoxParams } from "shared/epics/flipperUINotification";
import store from "store";

export const mobileAppSmsFlash = (user: User, message: any) => ({
  values: { phone_number: user.phone },
  ...getMessageContent(message),
});

const mobileAppSms: getInfoBoxParams = (id, dismiss, user: User) => [
  {
    icon: "mobile_person_48px",
    title: <FormattedMessage id="mobile_app.sms.heading" />,
    body: (
      <LocalizedMarkdown
        id={`mobile_app.sms.${
          SiteSettings.product_name !== "Kaiku Health"
            ? "customized"
            : "content"
        }`}
        values={{
          product_name: SiteSettings.product_name,
        }}
      />
    ),
    buttons: (
      <div>
        <LinkButton
          type="secondary"
          onClick={() => store.dispatch(setShowAppDownloadModal(true))}
          size="small"
        >
          <FormattedMessage id="mobile_app.send_link_modal.send_button" />
        </LinkButton>
        <LinkButton
          size="small"
          type="accent"
          onClick={() => openMobileAppDeepLink(user)}
        >
          <FormattedMessage id="mobile_app.link" />
        </LinkButton>
      </div>
    ),
    id,
    dismiss,
  },
];

export default mobileAppSms;
