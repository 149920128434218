/* eslint-disable max-lines */
import {
  omit,
  includes,
  forEach,
  isNil,
  get as _get,
  isError,
  merge,
  noop,
} from "lodash";
import { redirect } from "./holvikaari";
import { parseJson } from "./json_api";
import store from "../../store";
import React from "react";
import Bugsnag from "bugsnag";
import { SiteSettings } from "common/utils/holvikaari";
import { addFlash } from "common/actions/flash";
import { AppAction } from "shared/actions/app";
import { endpoints } from "./endpoints";
import { hasSessionChanged } from "common/hooks/useSessionHandler";

export { endpoints } from "./endpoints";

export type RequestOptions = {
  // Should page unload abort the request?
  abortOnUnload?: boolean;
  // Custom abort controller
  controller?: AbortController;
  // Custom response handler. Set to null to use the default json handler.
  // Return true if the response was handled. Return false to propagate
  // to the default handler.
  responseHandler?: (res: Response) => boolean;
  // Instead of dispatching store actions for errors, throw them instead
  // and let the caller handle them.
  // TODO: this is a temporary measure, delete once error boundary coverage is complete
  // In the future handling of the error should always be on the responsibility
  // of the caller, since the api util does not have sufficient context to handle errors.
  throwErrors?: boolean;
};

const token =
  _get(document.getElementsByName("csrf-token"), "[0].content") || null;
const jwtToken =
  _get(document.getElementsByName("jwt-token"), "[0].content") || null;

const defaults = {
  credentials: "same-origin" as RequestCredentials,
  headers: {
    Accept: "application/json",
    "X-CSRF-Token": token,
    "X-Requested-With": "XMLHttpRequest",
    "Content-Type": "application/json",
    ...(jwtToken ? { Authorization: `Bearer ${jwtToken}` } : {}),
  },
};

export const csrfToken = (
  <input
    type="hidden"
    name="authenticity_token"
    value={token ?? ""}
    key={token}
  />
);

export const demoEndpoints = {
  reset_answers: "/rest/input_form_answers/reset_demo_data",
};

const updateOnlineStatus = (online: boolean, forceRefresh = false) =>
  store.dispatch({ type: "OFFLINE", data: !online, forceRefresh } as AppAction);

window.addEventListener("online", () => updateOnlineStatus(true));
window.addEventListener("offline", () => updateOnlineStatus(false));

const initialSession = { id: "" };

const request = (
  url: string,
  params: any,
  ignoreTimeouts = false,
  reqOptions?: RequestOptions,
) => {
  const controller = reqOptions?.controller ?? new window.AbortController();
  const signal = controller.signal;
  const abortRequest = () => controller.abort();

  const options = {
    abortOnUnload: true,
    responseHandler: null,
    ...(reqOptions || {}),
  };

  if (options.abortOnUnload) {
    window.addEventListener("beforeunload", abortRequest);
  }

  if (hasSessionChanged(initialSession)) {
    abortRequest();
    redirect("/", true);
  }

  return fetch(url, Object.assign({ signal }, defaults, params))
    .then((res: Response) => {
      if (options.responseHandler && options.responseHandler(res)) {
        return noop();
      } else if (options.throwErrors && !res.ok) {
        return Promise.reject({ message: "Request failed", response: res });
      } else {
        return getJson(res);
      }
    })
    .catch(e => {
      if (e.name === "AbortError") {
        if (process.env.NODE_ENV === "development") {
          console.log("Abort!"); // eslint-disable-line no-console
        }
        return Promise.reject({ data: e, shouldBeIgnored: true });
      }

      // network errors return TypeError (https://fetch.spec.whatwg.org/)
      if (!ignoreTimeouts && e instanceof TypeError) {
        updateOnlineStatus(false, true);
      }
      const wrappedError = e instanceof Error ? { data: e } : e;
      return Promise.reject(wrappedError);
    })
    .finally(() => {
      // Clean-up the event listener once the request is complete
      window.removeEventListener("beforeunload", abortRequest);
    });
};

const getJson = (res: Response) => {
  if (res.ok) {
    // 204 no content, eg. delete
    return res.status === 204 ? Promise.resolve() : res.json();
  } else if (res.status === 440) {
    redirect("/");
  } else if (includes([401, 403, 404], res.status)) {
    store.dispatch({ type: "PAGE_LOAD_ERROR" } as AppAction);
  } else if (res.status === 429) {
    store.dispatch(addFlash("client.too_many_requests", "alert"));
  } else if (res.status === 500) {
    store.dispatch({ type: "ERROR_500" } as AppAction);
  }

  return res
    .json()
    .then(data => {
      return Promise.reject({ data, res });
    })
    .catch(e => {
      return Promise.reject({ data: e.data || e, res });
    });
};

const isForm = (data: any) => window.FormData && data instanceof FormData;

const getPostParams = (data: any) => ({
  body: isForm(data) ? data : JSON.stringify(data),
  headers: isForm(data)
    ? omit(defaults.headers, "Content-Type")
    : defaults.headers,
});

export const pageLoadFailed = ({
  data,
  shouldBeIgnored,
}: {
  data: any;
  shouldBeIgnored: boolean;
}) => {
  if (shouldBeIgnored) return Promise.resolve();
  store.dispatch({ type: "PAGE_LOAD_ERROR" } as AppAction);
  if (process.env.NODE_ENV === "development") {
    console.log(data); // eslint-disable-line no-console
  } else {
    const dataIsError = isError(data);
    Bugsnag?.notify(
      dataIsError ? data : new Error("page load failed"),
      event => {
        event.addMetadata(
          "metaData",
          merge(
            {
              location: window.location.pathname,
            },
            !dataIsError ? { data } : {},
          ),
        );
      },
    );
  }
  // Modal is displayed, refresh forced: halt promise chain.
  return new Promise(() => {}); // eslint-disable-line @typescript-eslint/no-empty-function
};

export const get = <T = any,>(
  endpoint: string,
  ignoreTimeouts = false,
  reqParams = {},
  reqOptions?: RequestOptions,
) =>
  request(
    endpoint,
    Object.assign(
      {},
      { headers: omit(defaults.headers, "Content-Type") },
      reqParams,
    ),
    ignoreTimeouts,
    reqOptions,
  ) as Promise<T>;

export const getWithoutPageLoadError: typeof get = (
  endpoint,
  ignoreTimeouts = false,
  reqParams = {},
  reqOptions: RequestOptions = {},
) =>
  get(endpoint, ignoreTimeouts, reqParams, {
    ...reqOptions,
    throwErrors: true,
  });

export const toFormData = (data: any, formData?: any, key = "") => {
  formData = formData || new FormData();
  if (typeof data === "object" && !(data instanceof File)) {
    forEach(data, function (value, index: number) {
      if (key === "") {
        toFormData(value, formData, index.toString());
      } else {
        // Rails doesn't like if the array items are indexed
        toFormData(value, formData, `${key}[${isNaN(index) ? index : ""}]`);
      }
    });
  } else if (!isNil(data)) {
    formData.append(key, data);
  }
  return formData;
};

// post & put can be called either with an object of key/value pairs or a FormData object
export const post = <T = any,>(
  endpoint: string,
  data?: any,
  reqOptions?: RequestOptions,
) =>
  request(
    endpoint,
    Object.assign(getPostParams(data), { method: "POST" }),
    false,
    reqOptions,
  ) as Promise<T>;

export const postForm = (
  endpoint: string,
  data: any,
  reqOptions?: RequestOptions,
) => {
  if (!window.FormData) return Promise.reject();
  return post(endpoint, toFormData(data), reqOptions);
};

export const put = <T = any,>(
  endpoint: string,
  data?: any,
  reqOptions?: RequestOptions,
) =>
  request(
    endpoint,
    Object.assign(getPostParams(data), { method: "PUT" }),
    false,
    reqOptions,
  ) as Promise<T>;

export const patch = (
  endpoint: string,
  data?: any,
  reqOptions?: RequestOptions,
) =>
  request(
    endpoint,
    Object.assign(getPostParams(data), { method: "PATCH" }),
    false,
    reqOptions,
  );

export const putForm = (
  endpoint: string,
  data: any,
  reqOptions?: RequestOptions,
) => {
  if (!window.FormData) return Promise.reject();
  return put(endpoint, toFormData(data), reqOptions);
};

export const del = (endpoint: string, reqOptions?: RequestOptions) =>
  request(endpoint, { method: "DELETE" }, false, reqOptions);

export const delWithoutPageLoadError: typeof del = (
  endpoint,
  reqOptions = {},
) => del(endpoint, { ...reqOptions, throwErrors: true });

export const delMultiple = (
  endpoint: string,
  data?: any,
  reqOptions?: RequestOptions,
) =>
  request(
    endpoint,
    Object.assign(getPostParams(data), { method: "DELETE" }),
    false,
    reqOptions,
  );

// Tracking events, fire and forget
export const track = (
  name: string,
  data = {},
  db_keys: Record<string, string> | null = null,
) => {
  if (SiteSettings.event_tracking_enabled)
    fetch(
      endpoints.tracking,
      Object.assign(
        { method: "POST" },
        defaults,
        getPostParams({ data, db_keys, event: name }),
      ),
    );
};

export const trackBeacon = (
  name: string,
  data = {},
  db_keys: Record<string, string> | null = null,
) => {
  if (!SiteSettings.event_tracking_enabled) {
    return;
  }
  const params = new FormData();
  params.append("authenticity_token", token);
  params.append("body", JSON.stringify({ event: name, data, db_keys }));
  return navigator.sendBeacon(endpoints.trackingBeacon, params);
};

export const downloadBlob = (blob: Blob, fileName: string): void => {
  const url = URL.createObjectURL(blob);
  const tempLink = document.createElement("a");
  tempLink.href = url;
  tempLink.download = fileName;
  document.body.appendChild(tempLink);
  tempLink.click();
  tempLink.remove();
  URL.revokeObjectURL(url);
};

export { parseJson };

export const withLoadingStatus = (
  request: Promise<any>,
  onLoadingChange: (loading: boolean) => void,
) => {
  onLoadingChange(true);
  return request.then(d => {
    onLoadingChange(false);
    return d;
  });
};

export const buildUrl = (
  url: string,
  params: Record<string, string | number | undefined>,
) => {
  Object.entries(params).forEach((entry, index) => {
    if (index === 0 && entry[1]) {
      url = url + `?${entry[0]}=${entry[1]}`;
    }

    if (index > 0 && entry[1]) {
      url = url + `&${entry[0]}=${entry[1]}`;
    }
  });

  return url;
};
