import React, { useEffect } from "react";
import SharedDocuments from "./ClientDashboardDocumentSection";
import { Document } from "shared/components/DocumentCard/SharedDocumentCard";
import ShareButton from "./ShareButton";
import { DisclaimerText, FileInputContainer } from "./SharedDocuments.styles";
import { Icon } from "@netmedi/design-system";
import { FormattedMessage } from "react-intl";

export type SharedByCareTeamProps = {
  clientId: number;
  documents: Document[];
  sharing_enabled: boolean;
  client_in_domain: boolean;
  getReceivedDocuments: any;
  shareDocument: any;
};

const DocumentsSharedByCareTeam = (props: SharedByCareTeamProps) => {
  useEffect(() => {
    props.getReceivedDocuments(props.clientId);
  }, []);

  const DomainError = () => {
    return (
      <DisclaimerText>
        <Icon name="alert_circle" />
        <FormattedMessage
          data-testid="sharing-not-enabled"
          id={`shared_documents.sharing_documents_disclaimer`}
        />
      </DisclaimerText>
    );
  };

  //Description is hidden until the document library is used to share documents, now shows the name of the sender.
  return (
    <div>
      <SharedDocuments
        type={"shared-to-client"}
        descriptionHidden={true}
        {...props}
      />
      <FileInputContainer>
        {!(props.client_in_domain && props.sharing_enabled) && <DomainError />}
        <ShareButton
          clientId={props.clientId}
          shareDocument={props.shareDocument}
          disabled={!(props.client_in_domain && props.sharing_enabled)}
        />
      </FileInputContainer>
    </div>
  );
};

export default DocumentsSharedByCareTeam;
