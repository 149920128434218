import React from "react";
import { range } from "lodash";
import {
  PaginationList,
  PaginationListItem,
  CurrentListItem,
} from "./Pagination.styles";
/**
  Use the pagination component when content needs to be split over several pages.

  If you have multiple pages, you should provide a search function as well, since
  browsing through several pages to find the relevant item is tedious.
*/

export const FIRST = "FIRST";
export const LAST = "LAST";
type Page = typeof FIRST | typeof LAST | number;

export const calculatePaging = (
  currentPage: number,
  totalPages: number,
  displayedPages: number,
) => {
  if (totalPages < 2) return [];
  let start = Math.max(0, currentPage - Math.floor(displayedPages / 2));
  const end = Math.min(totalPages, start + displayedPages);
  start = Math.max(0, start - (displayedPages - (end - start)));

  const pages: Page[] = range(start, end);

  if (pages[0] !== 0) pages[0] = FIRST;
  if (pages[pages.length - 1] < totalPages - 1) pages[pages.length - 1] = LAST;

  return pages;
};

export const indexFor = (page: Page, totalPages: number) => {
  const fromToken = { [FIRST]: 0, [LAST]: totalPages - 1 }[page];
  return fromToken !== undefined ? fromToken : (page as number);
};

export const textFor = (page: Page) =>
  ({ [FIRST]: "<", [LAST]: ">" })[page] || (page as number) + 1;

// Pages are 1-indexed
type Props = {
  currentPage: number;
  totalPages: number;
  move: (...args: any[]) => any;
  displayedPages?: number;
};

export const SensiblePagination = ({
  currentPage,
  totalPages,
  move,
  displayedPages = 11,
}: Props) => (
  <div>
    <PaginationList data-testid="sensible-pagination-list">
      {calculatePaging(currentPage - 1, totalPages, displayedPages).map(
        (page, idx) => {
          const pageIndex = indexFor(page, totalPages) + 1;
          const current = pageIndex === currentPage;
          if (current) {
            return <CurrentListItem key={idx}>{textFor(page)}</CurrentListItem>;
          }
          return (
            <PaginationListItem key={idx}>
              <button
                name={`page-${pageIndex}`}
                type="button"
                onClick={() => move(pageIndex)}
              >
                {textFor(page)}
              </button>
            </PaginationListItem>
          );
        },
      )}
    </PaginationList>
  </div>
);
