import rtl from "styled-components-rtl";
import styled from "styled-components";
import { mediaQueries } from "@netmedi/design-system/dist/styles/variables";
import Button from "@netmedi/design-system/dist/components/Button";

export const CreateUserForm = styled.form`
  padding-bottom: 2rem;
`;

export const CardContent = styled.div`
  display: flex;
  align-items: center;
`;

export const FieldContainer = styled.div`
  clear: both;
`;

// This is for fields without labels
export const FieldPadding = styled.div`
  margin-top: 0.5rem;
  // Only for desktop
  @media (min-width: ${mediaQueries.small}px) {
    ${rtl`margin-left: 40%;`}
  }
`;

export const FieldInstructions = styled.div`
  // Only for desktop
  @media (min-width: ${mediaQueries.small}px) {
    ${rtl`margin-left: 40%;`}
  }
`;

export const SubmitButton = styled(Button)`
  margin-top: 2rem;

  // Only for desktop
  @media (min-width: ${mediaQueries.small}px) {
    ${rtl`margin-left: 40%;`}
  }
`;
