import React, { useRef, useState } from "react";
import { SiteSettings } from "common/utils/holvikaari";
import { Button } from "@netmedi/design-system";
import { FormattedMessage } from "react-intl";
import { IconName } from "@netmedi/design-system/dist/components/Icon";
import { StyledAlert } from "./FileInputStyles";

export type FileInputProps = {
  onFilesSelected: (files: File[]) => void;
  buttonMessage: string;
  multiple?: boolean;
  buttonIcon?: IconName;
  disabled?: boolean;
};

const FileInput = (props: FileInputProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [alert, setAlert] = useState(false);

  const convertToFiles = () => {
    const ref = inputRef.current;
    const maxSizeMiBs = SiteSettings.attachment_max_size;
    const files = [...(ref?.files ?? [])];
    const tooBig = files.some(
      (file: File) => file.size / 1024 / 1024 > maxSizeMiBs,
    );
    if (tooBig) {
      setAlert(true);
    } else {
      props.onFilesSelected(files);
    }
    ref && (ref.value = ""); // Clears the files from the input to prevent modal from failing to open if same file is picked twice.
  };

  const openFileDialog = () => {
    inputRef?.current?.click();
  };

  const allowedFileTypes = Object.keys(
    SiteSettings.allowed_attachment_types,
  ).join(",");

  return (
    <>
      {alert && (
        <StyledAlert
          type="alert"
          title={
            <FormattedMessage id="mass_message.file_too_big_alert_title" />
          }
          body={<FormattedMessage id="mass_message.file_too_big_alert_body" />}
          dismiss={(): any => setAlert(false)}
        />
      )}
      <input
        type="file"
        hidden
        multiple={props.multiple}
        ref={inputRef}
        accept={allowedFileTypes}
        onChange={convertToFiles}
      />
      <Button
        size="small"
        icon={props.buttonIcon}
        data-testid="file-input-button"
        onClick={openFileDialog}
        disabled={props.disabled}
        type={"primary"}
      >
        <FormattedMessage id={props.buttonMessage} />
      </Button>
    </>
  );
};

export default FileInput;
