import React, { useEffect } from "react";
import CalendarEntries from "client/components/WelcomeClient/CalendarEntries";
import { CalendarEntry } from "client/models/calendarEntries";
import { Container, Header } from "./CalendarEntriesContainer.styles";
import { FormattedMessage } from "react-intl";

type Props = {
  entries: CalendarEntry[];
  getAppointments?: any;
  clientId?: number;
};

const CalendarEntriesContainer = ({
  entries,
  clientId,
  getAppointments,
}: Props) => {
  useEffect(() => {
    clientId && getAppointments(clientId);
  }, [getAppointments]);

  return (
    <Container>
      <Header>
        <FormattedMessage id="client.calendar.next" />
      </Header>
      {entries && <CalendarEntries entries={entries} />}
    </Container>
  );
};

export default CalendarEntriesContainer;
