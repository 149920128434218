import React from "react";
import { FormattedMessage } from "react-intl";

import dayjs from "dayjs";
import timezone from "dayjs/plugin/timezone";
import utc from "dayjs/plugin/utc";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(timezone);
dayjs.extend(utc);
dayjs.extend(localizedFormat);

import { List, ListItem } from "common/components/List";
import { Icon } from "@netmedi/design-system";
import { EventInfo } from "./CalendarEntries.styles";
import { SiteSettings } from "common/utils/holvikaari";
import { CalendarEntry as TCalendarEntry } from "client/models/calendarEntries";

export type Props = {
  entries: TCalendarEntry[];
};

type person = { name: string; title?: string };

const addMargin = (entry: { personnel: string[]; location?: string }) => {
  if (entry.personnel || entry.location) return true;
  return false;
};

const shortTimezone = (longName: string) =>
  String(longName.split("/").pop()?.replace(/_/g, " "));
const timeInTimezone = (
  deviceTimezone: string,
  entryTimezone: string,
  appt_timezone?: string,
) => {
  const useTimezone = () => {
    if (appt_timezone) return appt_timezone;
    if (entryTimezone) return entryTimezone;
    return deviceTimezone;
  };

  return {
    long: useTimezone(),
    short: shortTimezone(useTimezone()),
  };
};

function CalendarEntry({
  entry: {
    begin_at,
    title,
    duration,
    tzinfo,
    personnel,
    location,
    appt_timezone,
  },
  show_all_timezones,
  deviceTimezone,
}: {
  entry: TCalendarEntry;
  deviceTimezone: string;
  show_all_timezones: boolean; // determines whether timezones are shown for all appointments
}) {
  const timezoneInfo = timeInTimezone(deviceTimezone, tzinfo, appt_timezone);
  const beginAt = dayjs.utc(begin_at).tz(timezoneInfo.long);
  const showTimezoneName =
    show_all_timezones || timezoneInfo.long !== deviceTimezone;
  const timeZoneText = (
    <FormattedMessage
      id="timezone"
      values={{
        name: timezoneInfo.short,
      }}
    />
  );

  return (
    <ListItem
      spacing="xs"
      heading={<strong>{title}</strong>}
      icon={<Icon name="calendar" size="medium" />}
      alignItems="flex-start"
    >
      <EventInfo bottomMargin={addMargin({ personnel, location })}>
        {beginAt.format("L")}
        {!!SiteSettings.client_sees_calendar_event_times && (
          <span>
            {` ${beginAt.format("LT")} `}
            {showTimezoneName && timeZoneText}
            {duration && (
              <>
                <br />
                {duration}
              </>
            )}
          </span>
        )}
      </EventInfo>
      {SiteSettings.calendar_extra_fields.includes("practitioner") &&
        personnel &&
        personnelList(personnel)}
      {SiteSettings.calendar_extra_fields.includes("location") && location && (
        <EventInfo>{location}</EventInfo>
      )}
    </ListItem>
  );
}

function CalendarEntries({ entries }: Props) {
  const deviceTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const show_all_timezones = () =>
    !!SiteSettings.always_show_appointment_timezone ||
    !!entries.find(e => {
      if (e.appt_timezone) return e.appt_timezone !== deviceTimezone;
      return e.tzinfo !== deviceTimezone;
    });

  return (
    <List spacing="xs" noPadding>
      {entries.map(entry => (
        <CalendarEntry
          entry={entry}
          key={entry.id}
          deviceTimezone={deviceTimezone}
          show_all_timezones={show_all_timezones()}
        />
      ))}
    </List>
  );
}

const personnelList = (personnel: string[]) =>
  personnel.map((person, id) => {
    const personInfo: person = JSON.parse(person);
    const title = personInfo.title ? `, ${personInfo.title}` : "";
    return (
      <EventInfo key={"person" + id}>{`${personInfo.name}${title}`}</EventInfo>
    );
  });

export default CalendarEntries;
