import React, { useState } from "react";
import { useHistory, useRouteMatch } from "react-router";
import useAbsences, { Absence, useAbsence } from "./useAbsences";
import { Form } from "react-final-form";
import AbsenceFields from "./AbsenceFields";
import { Button, Card, Icon } from "@netmedi/design-system";
import useI18nTranslate from "common/hooks/useI18nTranslate";
import dayjs from "dayjs";
import { absenceRoutes } from "./Absences";
import getApiClient from "common/utils/api2/getApiClient";
import { AbsenceFormStyled, ButtonContainer } from "./Absences.styles";

const defaultValues = {
  absent_person: undefined,
  substitute_person: undefined,
  begin_date: dayjs().format("YYYY-MM-DD"),
  end_date: undefined,
  description: "",
};

const AbsenceForm: React.FC = () => {
  const [saving, setSaving] = useState(false);
  const absenceId = useRouteMatch<{ id: string }>()?.params.id ?? "new";
  const { insertAbsence, updateAbsence } = useAbsences();
  const absence = useAbsence(absenceId);
  const createOrUpdate = absenceId === "new" ? "create" : "update";
  const t = useI18nTranslate();
  const history = useHistory();
  const [errors, setErrors] = useState<string[]>([]);

  const save = async (values: { [key: string]: any }) => {
    setSaving(true);
    try {
      if (createOrUpdate === "create") {
        const createdAbsence = await getApiClient()
          .createAbsence({ absence: values as Absence })
          .post();

        insertAbsence(createdAbsence);
      } else {
        const updatedAbsence = await getApiClient()
          .updateAbsence({
            id: +absenceId,
            absence: values as Absence,
          })
          .put();

        updateAbsence(updatedAbsence as Absence);
      }

      history.push(absenceRoutes.index);
    } catch (error) {
      if ((error as any).res.status === 422) {
        setErrors((error as any).data.errors);
      }
    } finally {
      setSaving(false);
    }
  };

  const initialValues = createOrUpdate === "update" ? absence : defaultValues;

  return (
    <AbsenceFormStyled data-testid="AbsenceForm">
      <h1>{t(`absences.${createOrUpdate}_title`)}</h1>
      <Form onSubmit={save} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <AbsenceFields />
            {errors.length > 0 && (
              <Card icon={<Icon name="info_circle_16px" />} type="warning">
                {errors.map(message => (
                  <p key={message}>{message}</p>
                ))}
              </Card>
            )}

            <ButtonContainer>
              <Button type="primary" submit disabled={saving}>
                {t("absences.save")}
              </Button>
              <Button
                onClick={() => history.push(absenceRoutes.index)}
                type="ghost"
              >
                {t("absences.cancel")}
              </Button>
            </ButtonContainer>
          </form>
        )}
      </Form>
    </AbsenceFormStyled>
  );
};

export default AbsenceForm;
