import { IconExtras } from "@netmedi/design-system";
type IconName = IconExtras.IconName;
import { TaskAction } from "client/actions/tasks";

export type Task = {
  id: string;
  name: string;
  type: string;
  icon?: IconName;
  is_input_form?: boolean;
  is_symptom_form?: boolean;
  is_blueprint?: boolean;
  answer_id?: unknown;
  item_id: string;
  triggered_by?: unknown;
  description: string;
  discard_confirm: null | string;
  discard_localization: string;
  time_window_end?: Date;
};

export type State = {
  urgent: Task[];
  expired: Task[];
  non_urgent: Task[];
  loading: boolean;
};

export default (
  state: State = {
    urgent: [],
    expired: [],
    non_urgent: [],
    loading: true,
  },
  action: TaskAction,
): State => {
  switch (action.type) {
    case "SET_TASKS":
      return {
        ...state,
        urgent: action.data.urgent,
        expired: action.data.expired,
        non_urgent: action.data.non_urgent,
        loading: false,
      };
    default:
      return state;
  }
};
