import styled from "styled-components";
import {
  graphLine,
  graphArea,
} from "@netmedi/design-system/dist/styles/variables";

export const Chart = styled.div.attrs(() => ({
  "data-testid": "simple-line-graph-chart",
}))`
  position: relative;
`;

export const SvgWrapper = styled.div`
  width: 100%;
`;

export const SeriesGraph = styled.g`
  color: ${graphLine[0]};
`;

export const SeriesLine = styled.path`
  opacity: 0.5;
  stroke: ${graphLine[0]};
  stroke-width: 2px;
  fill: transparent;
`;

export const SeriesArea = styled.path`
  opacity: 0.5;
  fill: ${graphArea[0]};
`;

export const SeriesPoint = styled.circle`
  stroke: ${graphLine[0]};
  fill: ${graphLine[0]};
  stroke-width: 2;
`;
