import React from "react";
import { BirthDateInput } from "client/components/Profile/Profile.styles";
import { Field, FieldRenderProps } from "react-final-form";
import { FormattedMessage } from "react-intl";
import { FormField } from "common/components/Form";
import { DOBWrapper } from "common/components/Form/FormField.styles";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { pick } from "lodash";
import localizedFormat from "dayjs/plugin/localizedFormat";
dayjs.extend(localizedFormat);
dayjs.extend(localeData);

type FormProps = {
  formType: "basic" | "invite";
  locale: string;
  birthdateProps: FieldRenderProps<any, HTMLElement>;
  "aria-invalid"?: boolean;
  "aria-describedby"?: string;
  disabled?: boolean;
};

type DOBFieldsType = ["DD", "MM", "YYYY"] | ["MM", "DD", "YYYY"];

const DOBFields = (props: FormProps) => {
  const currentPresentation = dayjs.localeData().longDateFormat("L");
  const currentPunct = (currentPresentation.match(/[^A-Za-z0-9]/) || ["."])[0];
  // some locales (e.g. ar) have D instead of DD in the locale
  const dobFields = currentPresentation.split(currentPunct).map(field => {
    if (field.includes("D")) return "DD";
    if (field.includes("M")) return "MM";
    return "YYYY";
  }) as DOBFieldsType;
  const form = formTypes[props.formType];

  return (
    <FormField {...props.birthdateProps}>
      <DOBWrapper {...pick(props, ["aria-invalid", "aria-describedby"])}>
        {[
          <React.Fragment key="1">{form[dobFields[0]](props)}</React.Fragment>,
          <span key={"2"}>{currentPunct} </span>,
          <React.Fragment key="3">{form[dobFields[1]](props)}</React.Fragment>,
          <span key={"4"}>{currentPunct} </span>,
          <React.Fragment key="5">{form[dobFields[2]](props)}</React.Fragment>,
        ]}
      </DOBWrapper>
    </FormField>
  );
};

const basicInfo = {
  DD: function DD(props: FormProps) {
    return (
      <FormattedMessage id="people.fields.birthdate_day">
        {message => (
          <Field name="bd_d">
            {fieldProps => (
              <BirthDateInput
                pattern="[\d]*"
                placeholder="dd"
                type="number"
                required={!props.disabled}
                disabled={props.disabled}
                aria-label={message as unknown as string}
                {...fieldProps.input}
              />
            )}
          </Field>
        )}
      </FormattedMessage>
    );
  },
  MM: function MM(props: FormProps) {
    return (
      <FormattedMessage id="people.fields.birthdate_month">
        {message => (
          <Field name="bd_m">
            {fieldProps => (
              <BirthDateInput
                pattern="[\d]*"
                placeholder="mm"
                type="number"
                required={!props.disabled}
                disabled={props.disabled}
                aria-label={message as unknown as string}
                {...fieldProps.input}
              />
            )}
          </Field>
        )}
      </FormattedMessage>
    );
  },
  YYYY: function YYYY(props: FormProps) {
    return (
      <FormattedMessage id="people.fields.birthdate_year">
        {message => (
          <Field name="bd_y">
            {fieldProps => (
              <BirthDateInput
                pattern="[\d]*"
                placeholder="yyyy"
                type="number"
                required={!props.disabled}
                disabled={props.disabled}
                aria-label={message as unknown as string}
                {...fieldProps.input}
              />
            )}
          </Field>
        )}
      </FormattedMessage>
    );
  },
};

const invite = {
  DD: function DD(props: FormProps) {
    return (
      <FormattedMessage id="people.fields.birthdate_day">
        {message => (
          <Field name="birthdate[day]" label="attributes.bd_d">
            {fieldProps => (
              <BirthDateInput
                id="birthdate_day"
                pattern="[\d]*"
                placeholder="DD"
                type="number"
                required={!props.disabled}
                disabled={props.disabled}
                aria-label={message as unknown as string}
                {...fieldProps.input}
              />
            )}
          </Field>
        )}
      </FormattedMessage>
    );
  },
  MM: function MM(props: FormProps) {
    return (
      <FormattedMessage id="people.fields.birthdate_month">
        {message => (
          <Field name="birthdate[month]" label="attributes.bd_m">
            {fieldProps => (
              <BirthDateInput
                id="birthdate_month"
                pattern="[\d]*"
                placeholder="MM"
                type="number"
                required={!props.disabled}
                disabled={props.disabled}
                aria-label={message as unknown as string}
                {...fieldProps.input}
              />
            )}
          </Field>
        )}
      </FormattedMessage>
    );
  },
  YYYY: function YYYY(props: FormProps) {
    return (
      <FormattedMessage id="people.fields.birthdate_year">
        {message => (
          <Field name="birthdate[year]" label="attributes.bd_y">
            {fieldProps => (
              <BirthDateInput
                id="birthdate_year"
                pattern="[\d]*"
                placeholder="YYYY"
                type="number"
                required={!props.disabled}
                disabled={props.disabled}
                aria-label={message as unknown as string}
                {...fieldProps.input}
              />
            )}
          </Field>
        )}
      </FormattedMessage>
    );
  },
};

const formTypes = {
  basic: basicInfo,
  invite: invite,
};

export const DOBFieldsHelp = () => (
  <>
    <FormattedMessage
      id="people.birthdate_help"
      values={{
        presentation: dayjs.localeData().longDateFormat("L"),
        example: dayjs("1950-05-20", "YYYY-MM-DD").format("l"),
      }}
    />
  </>
);

export default DOBFields;
