import styled from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import { colors, spacing } from "@netmedi/design-system/dist/styles/variables";
import StyledDataTable from "common/components/DataTable/DataTable.styles";

export const NotificationHeader = styled.p`
  font-weight: 500;
`;

export const NotificationBody = styled.p`
  white-space: pre-line;
`;

export const SummaryView = styled.div`
  h1 {
    font-size: ${rem(24)};
  }
  h3 {
    font-size: ${rem(18)};
  }
  p {
    font-size: ${rem(16)};
  }
`;

export const Attachment = styled.div`
  font-weight: 500;
  font-size: ${rem(16)};
  color: ${colors.blue500};
  svg {
    ${rtl`margin-right: 0.5em;`}
  }

  &:hover {
    color: ${colors.blue900};
  }
`;

export const MassNotificationTable = styled(StyledDataTable)`
  td {
    border-top: none;
    border-bottom: #e7e7e7 1px solid;
    &:last-child {
      text-align: end;
    }
  }
`;

export const MassNotificationTP = styled.span`
  font-weight: 500;
  ${rtl`margin-right: 0.5em;`}
  span {
    ${rtl`margin-left: 0;`}
  }
`;

export const ButtonWrapper = styled.div.attrs(() => ({
  "data-testid": "close-mass-notification-modal",
}))`
  position: absolute;
  right: 2rem;

  button {
    appearance: none;
    border: 0;
    background: no-repeat;
    font-size: ${rem(16)};
    margin: 0;
    padding: 0;
  }
`;

export const StyledBackButton = styled.button.attrs(() => ({
  "data-testid": "back-button",
}))`
  border: none;
  background: none;
  margin-top: 1.5rem;
  color: ${colors.blue500};
`;

export const BackButtonDescription = styled.span.attrs(() => ({
  "data-testid": "button-back-desc",
}))`
  ${rtl`margin-left: ${spacing.xxs}px;`}
  font-weight: 500;
`;

export const HeaderWrapper = styled.div``;

export const StyledForm = styled.form`
  margin-bottom: 5rem;
`;

export const Paragraph = styled.p`
  margin: 1rem auto;
`;

export const RadioStyled = styled.input`
  appearance: auto !important;
  margin: 0 0.3rem;
`;

export const RadioLabel = styled.label`
  display: inline;
`;

export const RadioContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  align-items: baseline;
  gap: 1rem;
`;
