/* eslint-disable max-lines */
/* eslint-disable react/jsx-max-depth */
/* eslint-disable max-statements */
import React, { Component } from "react";
import {
  FormattedDate,
  FormattedMessage,
  injectIntl,
  WrappedComponentProps,
} from "react-intl";
import { useParams } from "react-router";
import {
  get,
  findIndex,
  isEmpty,
  last,
  head,
  max,
  flatMap,
  debounce,
  difference,
  some,
  map,
} from "lodash";
import Fuse from "fuse.js";

import { connect, ConnectedProps } from "react-redux";
import { getIFAs, getValuesComparison } from "client/actions/client";
import { setTooltip, clearTooltip } from "common/actions/tooltip";
import { RootState } from "store";

import { Icon, Spacing, Spinner } from "@netmedi/design-system";
import { Header } from "common/components/Page";
import { isClient } from "common/utils/user";
import { pageLoadFailed } from "common/utils/api";
import { symptomToCompareConverter } from "client/utils/CompareAnswers";
import CompareSection, { Cell } from "./CompareSection";

import {
  StyledPage,
  AnswersTable,
  AnswersTableData,
  AnswersTableRow,
  AnswerDateHeader,
  Score,
  ArrowBack,
  ArrowForward,
  SearchWrapper,
  SearchInput,
  SearchIcon,
  HeaderCell,
  Headers,
  TableHeader,
  Answer,
} from "./CompareAnswers.styles";

import { Question as QuestionType } from "shared/models/inputForm.types";

export type Answer = {
  date: string;
  edited_by: string;
  fields: Field[];
  has_feedback?: boolean;
  id: number;
  lang: string;
};

export type Field = {
  positivity: number;
  value: string;
};

export type Form = {
  name: string;
  scores: { id: string; text: string }[];
  sections: SectionType[];
};

export type SectionType = {
  name: string | null;
  questions: QuestionType[];
};

type IconNames = "ok_circle" | "bell" | "question_circle";

type State = {
  loading: boolean;
  offset: number;
  filteredQuestions: QuestionType[];
};

const mapStateToProps =
  (extras = {}) =>
  (state: RootState) => ({
    user: state.user,
    mobile: !!state.app.mobile,
    compare: state.client.compare,
    ...extras,
  });

const mapDispatchToProps = (data: "values" | "answers") => ({
  getData: (
    id: string,
    form: any,
    dataAdapter: ReturnType<typeof symptomToCompareConverter>,
  ) =>
    data === "answers"
      ? getIFAs(id, form)
      : getValuesComparison(id, form, dataAdapter),
  setTooltip,
  clearTooltip,
});

export type Props = (
  | ConnectedProps<typeof valueConnector>
  | ConnectedProps<typeof answerConnector>
) & {
  params: {
    id: string;
  };
  client_id?: number;
  showCaptionTooltip?: boolean;
  holvikaari?: boolean;
  // if you change this over 4, remember to add corresponding width styles to css
  columns: number;
  newStyles?: boolean;
  intl: any;
};

export const CompareIcon = (props: { icon: IconNames | string }) => {
  const { icon = "" } = props;

  const icons: Record<IconNames | string, JSX.Element | undefined> = {
    ok_circle: <Icon name="ok_circle" color="green" />,
    bell: <Icon name="bell" color="red" />,
    question_circle: <Icon name="question_circle" color="yellow" />,
  };

  const IconComponent = icons[icon];

  if (!IconComponent) {
    return null;
  }

  return (
    <Spacing inline top="0" bottom="0" left="0" right="xxs">
      {IconComponent}
    </Spacing>
  );
};

class CompareData extends Component<Props, State, WrappedComponentProps> {
  static defaultProps = {
    columns: 4,
  };

  state = {
    loading: true,
    offset: 0,
    filteredQuestions: [],
  };

  componentDidMount() {
    const { user, client_id, intl, getData, params } = this.props;

    const user_id = isClient(user) // clients can only view their own data
      ? user.id
      : (client_id as number);

    const tableAdapter = symptomToCompareConverter(
      intl.formatMessage.bind(intl),
    );

    getData(user_id.toString(), params.id, tableAdapter)
      .then(() => this.setState({ loading: false }))
      .catch(pageLoadFailed);
  }

  UNSAFE_componentWillReceiveProps(nextProps: Props) {
    const sections = get(nextProps, "compare.meta.form.sections") || [];
    const questions = flatMap(sections, s => get(s, "questions"));
    const ids = questions.map(q => q.id);

    const fuse = new Fuse(questions, {
      keys: ["text"],
      id: "id",
      threshold: 0.4,
      shouldSort: false,
    });

    this.updateFilteredQuestions = debounce(query => {
      query = query.substring(0, 32);

      if (query.length) {
        this.setState({
          filteredQuestions: difference(ids, fuse.search(query)),
        });
      } else {
        this.setState({ filteredQuestions: [] });
      }
    }, 100) as any;
  }

  // Placeholder for search. Initialized above, no-op until we receive compare data.
  updateFilteredQuestions(..._args: any[]) {} // eslint-disable-line @typescript-eslint/no-empty-function,@typescript-eslint/no-unused-vars

  filterData(answers: Answer[], target: number) {
    const columns = this.props.mobile ? 1 : this.props.columns;

    if (answers.length <= columns) {
      return { visibleAnswers: answers };
    }

    const idx = isNaN(target)
      ? answers.length - 1
      : findIndex(answers, (ans: Answer) => ans.id === target);

    const start = max([
      idx -
        (columns - 1) +
        (columns > 1 && idx <= answers.length ? 1 : 0) +
        this.state.offset,
      this.state.offset,
    ]) as number;

    const visible = answers.slice(0).splice(start, columns);

    return {
      visibleAnswers: visible,
      next: last(visible) !== last(answers),
      previous: head(visible) !== head(answers),
    };
  }

  // move back/fwd in the answer table
  // offset is from the initially selected answer
  move(
    params: { visibleAnswers: Answer[]; next?: boolean; previous?: boolean },
    i: number,
    back: any,
  ) {
    const { visibleAnswers, next, previous } = params;

    if (previous && i === 0 && back) {
      this.setState({ offset: this.state.offset - 1 });
    } else if (next && i === visibleAnswers.length - 1 && !back) {
      this.setState({ offset: this.state.offset + 1 });
    }
  }

  // eslint-disable-next-line max-lines-per-function
  render() {
    const { loading, filteredQuestions } = this.state;
    const { compare, intl, holvikaari, params } = this.props;

    const legend = get(compare, "meta.legend");

    if (loading || isEmpty(compare)) {
      return <Spinner />;
    }

    const activeId = parseInt(params.id);
    const filteredData = this.filterData(
      compare.input_form_answers as Answer[],
      activeId,
    );
    const { visibleAnswers, next, previous } = filteredData;

    const newStyles = !!this.props.newStyles;

    return (
      <StyledPage compact holvikaari={holvikaari}>
        {!holvikaari && (
          <Header
            translated
            linkBack="app.care_information"
            title={compare.meta.form.name}
          />
        )}

        {!isEmpty(legend) && (
          <Spacing spacing="s">
            <div>
              <ul>
                {map(legend, (text, name) => (
                  <li key={name}>
                    <CompareIcon icon={name as IconNames} />
                    {text}
                  </li>
                ))}
              </ul>
            </div>
          </Spacing>
        )}

        <AnswersTable
          newStyles={newStyles}
          aria-label={compare.meta.form.name as string}
        >
          <thead>
            <Headers newStyles={newStyles}>
              <TableHeader>
                <SearchWrapper>
                  <SearchIcon material name="search" size="medium" />
                  <SearchInput
                    placeholder={intl.formatMessage({ id: "generic.search" })}
                    onChange={e => {
                      const q = e.target.value;
                      this.updateFilteredQuestions(q);
                    }}
                  />
                </SearchWrapper>
              </TableHeader>

              {visibleAnswers.map((answer, i) => (
                <TableHeader key={answer.id || i}>
                  <HeaderCell
                    selected={activeId === answer.id}
                    backLink={previous && i === 0}
                    forwardLink={next && i === visibleAnswers.length - 1}
                  >
                    {i === 0 && previous && (
                      <ArrowBack
                        onClick={this.move.bind(this, filteredData, i, true)}
                      >
                        <Icon name="direction_left" />
                      </ArrowBack>
                    )}
                    {i === visibleAnswers.length - 1 && next && (
                      <ArrowForward
                        onClick={this.move.bind(this, filteredData, i, false)}
                      >
                        <Icon name="direction_right" />
                      </ArrowForward>
                    )}
                    <AnswerDateHeader>
                      <FormattedDate value={answer.date} />
                    </AnswerDateHeader>
                  </HeaderCell>
                </TableHeader>
              ))}
            </Headers>
          </thead>

          {some(visibleAnswers, { edited_by: String }) && (
            <tbody>
              <AnswersTableRow newStyles={newStyles}>
                <AnswersTableData>
                  <FormattedMessage tagName="b" id="charts.created_by" />
                </AnswersTableData>
                {visibleAnswers.map(answer => (
                  <AnswersTableData key={answer.id}>
                    {answer.edited_by}
                  </AnswersTableData>
                ))}
              </AnswersTableRow>
            </tbody>
          )}

          <tbody>
            {compare.meta.form.scores.map(score => {
              return (
                <Score key={score.id}>
                  <AnswersTableData>{score.text}</AnswersTableData>
                  {visibleAnswers.map(answer => (
                    <Cell
                      key={answer.id}
                      id={answer.id}
                      activeId={activeId}
                      answer={answer}
                      field={answer.fields[score.id as any]}
                      showCaptionTooltip={!!this.props.showCaptionTooltip}
                      clearTooltip={this.props.clearTooltip}
                      setTooltip={this.props.setTooltip}
                    />
                  ))}
                </Score>
              );
            })}
          </tbody>

          {compare.meta.form.sections.map((section, i) => (
            <CompareSection
              key={i}
              section={section}
              activeId={activeId}
              newStyles={newStyles}
              filteredQuestions={filteredQuestions}
              visibleAnswers={visibleAnswers}
              showCaptionTooltip={!!this.props.showCaptionTooltip}
              clearTooltip={this.props.clearTooltip}
              setTooltip={this.props.setTooltip}
            />
          ))}
        </AnswersTable>
      </StyledPage>
    );
  }
}

function CompareDataWithParams(props: Props) {
  const params = props.params ? props.params : useParams<{ id: string }>();
  return <CompareData {...props} params={params} />;
}

export const CompareDataWithoutParams = injectIntl(CompareData);

const WithIntl = injectIntl(CompareDataWithParams);

const answerConnector = connect(
  mapStateToProps(),
  mapDispatchToProps("answers"),
);

export const CompareAnswers = answerConnector(WithIntl);

const valueConnector = connect(
  mapStateToProps({
    showCaptionTooltip: true,
    columns: 6,
    newStyles: true,
  }),
  mapDispatchToProps("values"),
);

export const CompareValues = valueConnector(WithIntl);

export default CompareAnswers;
