import React, { useState } from "react";
import { FormattedMessage, FormattedDate, FormattedTime } from "react-intl";
import { Button } from "@netmedi/design-system";
import Tagger from "hcp/components/Staff/Tagger";
import { tap } from "common/utils/general";
import { get as _get } from "lodash";
import { Modal, ModalNav } from "./Modal.styles";
import { User } from "common/models/user";

import { assign, markCrcDoneWithSms } from "hcp/actions/staff";
import { connect } from "react-redux";
import { ICrcIssuable } from "hcp/components/Staff/IssueList/IssueList.types";

const mapDispatchToProps = { assign, markCrcDoneWithSms };

type Props = typeof mapDispatchToProps & {
  type: "crc_modal";
  issue: ICrcIssuable;
  dismiss: () => void;
  user: User;
};
export type CrcModalProps = Omit<Props, "assign" | "markCrcDoneWithSms">;

const OkModal = (props: Props) => {
  const [confirmed, setConfirmed] = useState(false);

  function doneAndSendSms() {
    const { markCrcDoneWithSms, issue, dismiss } = props;
    return markCrcDoneWithSms(issue).then(tap(dismiss));
  }

  function assignToSelf() {
    const { assign, user, issue, dismiss } = props;
    return assign(
      issue,
      { assignee_id: user.id },
      { skip_stale_check: true },
    ).then(tap(dismiss));
  }

  function renderConfirmButtons() {
    return (
      <ModalNav>
        {!confirmed && (
          <Button size="small" onClick={() => setConfirmed(true)}>
            <FormattedMessage id="modal.crc_module.accept_values" />
          </Button>
        )}
        <Button
          type={confirmed ? "accent" : undefined}
          size="small"
          onClick={assignToSelf}
        >
          <FormattedMessage id="modal.crc_module.reject_and_take_as_task" />
        </Button>
      </ModalNav>
    );
  }

  const {
    issue: {
      item: { sms_contents },
    },
  } = props;
  return (
    <div>
      <div>
        <FormattedMessage id="modal.crc_module.values_ok.values_ok" />
        <FormattedMessage id="modal.crc_module.values_ok.contact" />
        <ModalNav>{renderConfirmButtons()}</ModalNav>
      </div>
      <hr />
      <div>
        <FormattedMessage
          id="modal.crc_module.values_ok.contents_of_sms_title"
          tagName="h3"
        />
        <span>{sms_contents}</span>
        <ModalNav>
          <Button size="small" disabled={!confirmed} onClick={doneAndSendSms}>
            <FormattedMessage id="modal.crc_module.values_ok.send_sms" />
          </Button>
        </ModalNav>
      </div>
    </div>
  );
};

const NotOkModal = (props: Pick<Props, "issue" | "dismiss">) => {
  function renderPhoneNumber() {
    const phone = _get(props, "issue.issuer.phone");
    return phone ? `(${phone})` : "";
  }

  function renderAppointmentTime() {
    const time = _get(props, "issue.item.calendar_entry.begin_at");
    const dateOnly = _get(props, "issue.item.calendar_entry.date_only");
    if (!time) return null;
    return (
      <span>
        <FormattedDate value={time} />
        {!dateOnly && " "}
        {!dateOnly && <FormattedTime value={time} />}
      </span>
    );
  }

  const { issue, dismiss } = props;
  return (
    <div>
      <FormattedMessage
        id="modal.crc_module.values_not_ok.values_not_ok"
        values={{ time: renderAppointmentTime() }}
      />{" "}
      <strong>
        <FormattedMessage
          id="modal.crc_module.values_not_ok.contact_part_1"
          values={{ phone_number: renderPhoneNumber() }}
        />
      </strong>{" "}
      <FormattedMessage id="modal.crc_module.values_not_ok.contact_part_2" />
      <ModalNav>
        <Tagger issue={issue} onAction={dismiss} />
      </ModalNav>
    </div>
  );
};

function CrcModal(props: Props) {
  function canSendSms() {
    return !props.issue.item.cannot_attend;
  }

  return (
    <Modal onClick={(e: any) => e.stopPropagation()}>
      <FormattedMessage id="modal.crc_module.contact_customer" tagName="h3" />
      {canSendSms() ? <OkModal {...props} /> : <NotOkModal {...props} />}
    </Modal>
  );
}

export default connect(null, mapDispatchToProps)(CrcModal);
