import styled from "styled-components";
import rtl from "styled-components-rtl";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import { Button } from "@netmedi/design-system";
import OriginalPanel from "common/components/Panel";
import {
  colors,
  buttons,
  scaffolding,
  typography,
  linkColor,
  spacing,
  mediaQueries,
} from "@netmedi/design-system/dist/styles/variables";
import {
  mediaMin,
  mediaMax,
} from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";

import {
  FormFieldInput,
  FormFieldLabel,
} from "common/components/Form/FormField.styles";
import { ManageDomainsButton } from "../ManageDomainsModal/ManageDomainsButton";

type AvatarZoomTextProps = {
  disabled?: boolean;
  float?: "left" | "right";
};

type SaveTextProps = {
  failed?: boolean;
};

type FormHelpProps = {
  passwordNeeded?: boolean;
};

export const SubmitButton = styled(Button)`
  ${rtl`
    float: right;
    margin-left: 1em;`}
`;

export const Logout = styled.div`
  ${rtl`float: right;`}
  line-height: 2.5;
  font-size: ${rem(16)};
  font-weight: 500;

  ${mediaMax(mediaQueries.small)} {
    ${rtl`float: left;`}
  }
`;

export const ButtonTextAfterIcon = styled.span`
  ${rtl`margin-left: 5px;`}
`;

export const Panel = styled(OriginalPanel)`
  padding: 0%;
  ${mediaMin(mediaQueries.small)} {
    padding: 0 5%;
  }
  ${mediaMin(mediaQueries.medium)} {
    padding: 0 10%;
  }
`;

export const HiddenPanel = styled(Panel)`
  display: none;
`;

export const HiddenInput = styled.input`
  display: none;
`;

export const AppSettingsOuterWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const AppSettingsInnerWrap = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const AppSettingsGearIcon = styled.div`
  ${rtl`margin-right: 1rem;`}
`;

export const AppSettingsHeading = styled.h3`
  font-size: ${rem(20)};
  font-weight: 400;
  margin: 0;
`;

export const AppSettingsDescription = styled.p`
  color: ${colors.gray700};
  margin-top: 0.2rem;
  margin-bottom: 0.2rem;
`;

export const AppSettingsArrowIcon = styled.div`
  color: ${colors.blue500};
`;

export const IEButton = styled.label`
  display: inline-block;
  appearance: none;
  outline: none;
  cursor: pointer;
  background: ${buttons.primary.background};
  color: ${buttons.primary.text};
  border: 1px ${buttons.primary.background} solid;
  border-radius: ${buttons.borderRadius}px;
  transition: all 0.2s;
  margin: 0.25rem;
  line-height: 1;
  white-space: nowrap;
  font-family: ${scaffolding.fontFamilyBase};
  font-weight: 500;
  font-size: ${typography.fontSizeMedium};

  &:link,
  &:visited {
    color: ${buttons.primary.text};
  }

  &:hover,
  &:active {
    background: ${buttons.primary.hoverBackground};
    border-color: ${buttons.primary.hoverBackground};
    color: ${buttons.primary.text};
  }

  &:disabled,
  &:disabled:hover {
    background: ${colors.gray700};
    border-color: ${colors.gray700};
    color: ${colors.white};
    cursor: not-allowed;
  }

  padding: 1rem;
`;

export const OffscreenInput = styled.input`
  position: absolute;
  ${rtl`left: -9999em;`}
`;

export const AvatarContainer = styled.div`
  text-align: center;

  > canvas {
    cursor: move;
  }
`;

export const AvatarActions = styled.div`
  text-align: center;
  margin: 1rem 0.5rem;

  > * {
    min-width: 200px;
  }
`;

export const AvatarZoom = styled.div`
  text-align: center;
  display: block;

  > div {
    width: 70px;
    margin-top: 1em;
    display: inline-block;
    user-select: none;
    color: ${linkColor};
  }

  .disabled {
    color: ${colors.gray950};
  }
`;

export const FormContainer = styled.form.attrs(() => ({
  "data-testid": "profile-form-container",
}))`
  max-width: 700px;
  clear: both;
  ${FormFieldLabel} {
    ${mediaMin(mediaQueries.small)} {
      /* Limit width of field labels so they
       *  don't go above the input */
      span {
        max-width: 35%;
        display: inline-block;
        line-height: 1.5em;
      }
    }
  }
`;

export const AvatarZoomText = styled.span<AvatarZoomTextProps>`
  ${({ disabled }) => disabled && `color: ${colors.gray950};`}
  ${({ float }) => float && `float: ${float};`}
`;

export const Clear = styled.div`
  clear: both;
`;

export const AvatarInfo = styled.div`
  text-align: center;
  display: block;
  margin-top: 1em;
`;

export const ConsentField = styled.div`
  margin-bottom: 1rem;
`;

export const LongConsentText = styled.div`
  ${rtl`padding-left: 2rem;`}

  span br {
    line-height: 2;
  }
`;

export const ConsentHelpText = styled.div.attrs(() => ({
  "data-testid": "consent-help-text",
}))`
  font-size: ${typography.fontSizeMedium};
  ${rtl`padding-left: 2rem;`}
  margin-top: 0.5rem;
`;

export const SaveText = styled.div<SaveTextProps>`
  animation: fadeIn 0.2s;
  display: block;
  ${rtl`text-align: right;`}
  line-height: 3em;
  color: ${colors.green700};
  ${rtl`float: right;`}
  ${({ failed }) => failed && `color: ${colors.red700};`}
`;

export const NotificationDeviceList = styled.ul`
  ${rtl`margin-left: 1rem;`}
  font-weight: ${scaffolding.bold};

  li {
    list-style-type: disc;
  }
`;

export const FormHelp = styled.div<FormHelpProps>`
  font-size: ${typography.fontSizeMedium};
  margin-bottom: 1em;

  ${mediaMin(mediaQueries.small)} {
    width: 60%;
    ${rtl`float: right;`}
  }

  ${({ passwordNeeded }) =>
    passwordNeeded && `font-weight: ${scaffolding.bold}}`};
`;

export const PasswordFields = styled.div`
  margin-top: ${spacing.m}px;
`;

export const BirthDateInput = styled(FormFieldInput)(rtl`
  display: inline-block;
  width: 25%;
  max-width: 6em;
  margin-right: 0.5em;
`);

export const DomainEditButton = styled(ManageDomainsButton)`
  float: none;
  padding: 10px 0px;
`;

export const SymanticTD = styled.span.attrs(() => ({
  "data-testid": "symantic-table-cell",
}))`
  display: table-cell;
`;
