/* eslint-disable no-irregular-whitespace */
import styled from "styled-components";
import { colors } from "@netmedi/design-system/dist/styles/variables";

export const CheckboxGroupInput = styled.input`
  border: none !important;
  padding: 0;
  outline-offset: 0 !important;
  margin: 0 !important;

  ::before {
    font-size: 0.9rem;
    width: 1.3rem;
    height: 1.3rem;
    border-radius: 0.2rem;
    border: 0.1rem solid #d1d1d1;
    background-color: #fff;
    content: " ";
    display: block;
    position: relative;
    text-align: center;
  }

  :checked::before {
    background-color: #fff;
    border-color: #d1d1d1;
    content: "✓";
  }

  &:active::before,
  &:focus::before,
  &:hover::before {
    border-color: var(--primary-color);
  }

  &:disabled::before {
    background-color: ${colors.gray300};
    border-color: ${colors.gray500};
    color: ${colors.gray700};
  }
`;

export const CheckboxGroupLabel = styled.label`
  display: inline;
  margin: 0 0.5rem;
`;

export const CheckboxGroupOption = styled.div`
  display: flex;
  align-items: baseline;
`;

export const CheckboxGroupContainer = styled.div`
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const CheckboxErrors = styled.div`
  color: ${colors.red700};
  margin-top: 0.5rem;
`;
