import React, { useState } from "react";
import { FormattedMessage, injectIntl, IntlShape } from "react-intl";
import { Button, CheckBox } from "@netmedi/design-system";
import { csrfToken } from "common/utils/api";
import { User } from "common/models/user";
import { ModalNav, TextArea, ModalForm } from "./Modal.styles";

interface IProps {
  type: "mass_message";
  dismiss(event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
  user: User;
  intl: IntlShape;
}
export type MassMessageProps = Omit<IProps, "intl">;

type UserType = "staff" | "client";
interface IState {
  message: string;
  sendTo: UserType[];
  languages: string[];
  domains: string[];
}

type SubComponentProps = IProps &
  IState & {
    onChange: (event: any) => void;
  };

const renderDomains = ({
  user,
  intl,
  domains,
  onChange,
}: SubComponentProps) => {
  return (user.available_domains ?? []).map(domain => {
    const inputId = `message_domain_${domain}`;

    return (
      <CheckBox
        key={inputId}
        id={inputId}
        value={domain}
        name={"message[domains][]"}
        checked={domains.includes(domain)}
        onChange={onChange}
        label={intl.formatMessage({ id: `clients.domains.${domain}` })}
      />
    );
  });
};

const renderLanguages = ({
  user,
  intl,
  languages,
  onChange,
}: SubComponentProps) => {
  return (user.languages ?? []).map(lang => {
    const inputId = `message_lang_${lang}`;

    return (
      <CheckBox
        key={inputId}
        id={inputId}
        value={lang}
        name={"message[lang][]"}
        checked={languages.includes(lang)}
        onChange={onChange}
        label={intl.formatMessage({ id: `languages.${lang}` })}
      />
    );
  });
};

// eslint-disable-next-line max-lines-per-function
const MassMessageModal = (props: IProps) => {
  const [state, setState] = useState<IState>({
    message: "",
    sendTo: ["staff", "client"],
    languages: [props.user.language],
    domains: props.user.available_domains ?? [],
  });

  function renderSendTo() {
    const { intl } = props;
    const { sendTo } = state;

    return [
      <CheckBox
        key={"message_send_to_staff"}
        id={"message_send_to_staff"}
        value={"staff"}
        name={"message[send_to][]"}
        checked={sendTo.includes("staff")}
        onChange={handleCheckboxChange("sendTo")}
        label={intl.formatMessage({
          id: "conversations.mass_message_modal.staff",
        })}
      />,
      <CheckBox
        key={"message_send_to_clients"}
        id={"message_send_to_clients"}
        value={"client"}
        name={"message[send_to][]"}
        checked={sendTo.includes("client")}
        onChange={handleCheckboxChange("sendTo")}
        label={intl.formatMessage({
          id: "conversations.mass_message_modal.clients",
        })}
      />,
    ];
  }

  function renderButtons() {
    return (
      <ModalNav>
        <Button submit disabled={!canBeSubmitted()} size="small">
          <FormattedMessage id="conversations.mass_message_modal.send_message" />
        </Button>
        <Button type="accent" size="small" onClick={props.dismiss}>
          <FormattedMessage id="modal.dismiss" />
        </Button>
      </ModalNav>
    );
  }

  const handleCheckboxChange = (property: keyof IState) => (event: any) => {
    const { value, checked } = event.target;

    const values = new Set(state[property]);
    if (checked) {
      values.add(value);
    } else {
      values.delete(value);
    }

    setState({ ...state, [property]: Array.from(values) });
  };

  function canBeSubmitted() {
    const { message, sendTo, languages, domains } = state;
    return (
      message.length > 0 &&
      sendTo.length > 0 &&
      languages.length > 0 &&
      domains.length > 0
    );
  }

  const subComponentProps = (property: keyof IState) => ({
    ...props,
    ...state,
    onChange: handleCheckboxChange(property),
  });

  return (
    <ModalForm
      action="/admin/conversations/mass_message"
      method="POST"
      encType="multipart/form-data"
      acceptCharset="UTF-8"
    >
      {csrfToken}

      <h5>
        <FormattedMessage id="conversations.mass_message_modal.send_message_to" />
      </h5>
      {renderSendTo()}

      <h5>
        <FormattedMessage id="conversations.mass_message_modal.send_message_for_people_in_domains" />
      </h5>
      {renderDomains(subComponentProps("domains"))}

      <h5>
        <FormattedMessage id="conversations.mass_message_modal.send_message_for_people_with_languages" />
      </h5>
      {renderLanguages(subComponentProps("languages"))}

      <h5>
        <FormattedMessage id="conversations.mass_message_modal.message_content" />
      </h5>

      <TextArea
        name="message[content]"
        id="message_content"
        onChange={({ target: { value } }) =>
          setState({ ...state, message: value })
        }
      />
      <input name="attachment" type="file" />

      {renderButtons()}
    </ModalForm>
  );
};

export default injectIntl(MassMessageModal);
