import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonExtras, Button } from "@netmedi/design-system";
import { ModalNav, ModalAppError } from "./Modal.styles";
const { LinkButton } = ButtonExtras;
import { TitleAndBody, Dismiss } from "./Modal.types";
import { User } from "common/models/user";

export type ErrorProps = Partial<TitleAndBody> & {
  /** Whether the refresh and sign out -buttons should be shown instead of the dismiss-button */
  forceRefresh?: boolean;
  /** Whether to hide or show the buttons */
  hideButtons?: boolean;
} & (
    | {
        type: "error" | "offline";
      }
    | {
        type: "500" | "page_load_error" | "critical_error";
        user?: User;
      }
  );

/** Error modal shows the user an error that can be dismissed. The modal can also have a refresh and sign out -button, the latter in order for the user to get out of a possible refresh-loop. */
const Error = ({
  title,
  body,
  dismiss,
  forceRefresh,
  hideButtons,
}: ErrorProps & Dismiss) => (
  <ModalAppError>
    {title && <h3>{title}</h3>}
    {body}
    {!hideButtons && (
      <ModalNav>
        {forceRefresh ? (
          <span>
            <LinkButton size="small" type="accent" href="/signout">
              <FormattedMessage id="app.sign_out" />
            </LinkButton>
            <Button size="small" onClick={() => location.reload()}>
              <FormattedMessage id="refresh" />
            </Button>
          </span>
        ) : (
          <Button onClick={dismiss}>
            <FormattedMessage id="ok" />
          </Button>
        )}
      </ModalNav>
    )}
  </ModalAppError>
);

export default Error;
