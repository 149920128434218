import styled, { css } from "styled-components";
import {
  colors,
  mediaQueries,
  text,
} from "@netmedi/design-system/dist/styles/variables";
import { Button, Icon } from "@netmedi/design-system";
import rtl from "styled-components-rtl";
import { scrollBarStyles } from "styles/general.styles";
import { inputStyles } from "common/components/Search/Search.styles";
import {
  mediaMax,
  mediaMin,
} from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";

export const DomainSelectionContainer = styled.div`
  margin-bottom: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const DomainCheckboxErrors = styled.div`
  color: ${colors.red700};
`;

export const DomainSelectionLabel = styled.label`
  display: inline;
  margin: 0 1rem;
  font-size: ${text.size.medium};
  max-width: 88%;
  overflow-wrap: break-word;
`;

export const SelectDomainsOption = styled.div`
  display: flex;
  align-items: inherit;
  ${rtl`margin-left: 2px;`}
  margin-top: 6px;
  margin-bottom: 6px;
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

export const CheckboxErrorIcon = styled(Icon)`
  color: ${colors.red700};
  ${rtl`margin-right: 8px;`}
  width: 16px;
  height: 16px;
`;

export const DomainInputContainer = styled.div`
  position: sticky;
  margin-bottom: 40px;
`;

export const DomainCheckboxContainer = styled.div.attrs(() => ({
  "data-testid": "domain-checkbox-container",
}))<{ $fullHeight?: boolean }>`
  overflow-y: auto;
  ${({ $fullHeight }) =>
    !$fullHeight &&
    css`
      ${mediaMin(mediaQueries.medium)} {
        max-height: 35vh;
      }
    `}
  font-size: 19px;
  padding: 2px;

  ${scrollBarStyles}
`;

export const SearchIcon = styled(Icon)`
  position: absolute;
  height: 2.3rem;
  ${rtl`right: 7px;`}
  color: ${colors.gray900};
`;

export const Search = styled.div(rtl`
  padding: 20px 0px;
  ${rtl`padding-left: 0;`}
`);

export const SearchField = styled.input.attrs(() => ({
  "data-testid": "search-input",
}))`
  ${inputStyles}
  font-size: ${text.size.medium};
  color: ${colors.gray900};
  width: 100%;
  input {
    border-color: ${colors.gray50};
  }

  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: ${colors.gray900};
    text-overflow: ellipsis;
  }
  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: ${colors.gray900};
  }
`;

export const Placeholder = styled.div`
  padding: 8px;
`;

export const Header = styled.p`
  width: 100%;
  padding: 0.75em 0;
  font-size: 16px;
  margin: 0px;
  ${mediaMin(mediaQueries.medium)} {
    font-size: 20px;
  }
`;

export const SubmitButton = styled(Button)`
  ${rtl`
    float: right;
    margin-left: 1em;`};
  font-size: ${text.size.medium};
`;

export const CancelButton = styled(Button)`
  font-size: ${text.size.medium};
  padding: 16px 0px;
  ${mediaMin(mediaQueries.medium)} {
    ${rtl`float: right;`}
  }
`;

export const ModalHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
`;

export const CloseModalButton = styled(Button)`
  padding: 10px 0px;
  ${rtl`margin-right: 0px;`}
  ${rtl`float: right;`}
  ${rtl`padding-left: 5px;`}
  font-size: 20px;
`;

export const ManageDomainsButtonStyled = styled(Button)`
  ${rtl`float: right;`}
  font-size: 16px;
  padding: 8px 15px;
  margin: 0px;
  svg {
    ${rtl`margin-right: 8px;`}
  }
`;

export const DomainListSeparator = styled.div`
  border-top: 1px solid ${colors.gray900};
  padding-bottom: 10px;
`;

export const DomainListSelectedHeader = styled.div`
  ${rtl`padding-left: 2px;`}
  padding-bottom: 15px;
  font-size: ${text.size.medium};
`;

export const ButtonContainer = styled.div`
  ${mediaMax(mediaQueries.medium)} {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    border-top: 1px solid ${colors.gray300};
    background-color: white;
    padding: 0.5rem 1.5rem;
  }
`;
