import { stringify } from "qs";
import { Params, EndpointParams } from "../api2.types";
import { SiteSettings } from "common/utils/holvikaari";
import { endpoints, get } from "common/utils/api";
import { parseJsonRelations } from "common/utils/json_api";

const articles = ({ articles_api_url }: Record<string, string>) => {
  const baseUrl = () =>
    articles_api_url && `${articles_api_url}/articles/${SiteSettings.site}`;
  return {
    articles: () => {
      const url = baseUrl() ?? endpoints.articles.index;
      return {
        get: (params: Params["articles"] = {}) =>
          get(`${url}?${stringify(params)}`).then(parseJsonRelations),
      };
    },
    articleCategoryIdMatch: ({ id }: EndpointParams["article"]) => {
      const url = articles_api_url
        ? `${baseUrl()}/${id}`
        : endpoints.articles.articleWithCategoryMatchAllowed(id);
      return { get: () => get(url).then(parseJsonRelations) };
    },
  };
};

export default articles;
