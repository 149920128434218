import React from "react";

import { ValidationErrors } from "final-form";
import { includes, intersection, isEmpty, pull } from "lodash";
import { FormattedMessage } from "react-intl";
import dayjs from "dayjs";

import { padDate } from "common/utils/general";
import { InvitePageParams } from "./index";

const stepValidationFields = [
  ["email", "password"],
  ["first_name", "last_name", "birthdate", "gender"],
  [],
];

const requiredFields = [
  "first_name",
  "last_name",
  "email",
  "birthdate",
  "gender",
  "password",
];

const PASSWORD_LENGTH = 10;
const PASSWORD_RE = /((?=.*\d)(?=.*[a-z])(?=.*[A-Z]))/gm;

// eslint-disable-next-line max-lines-per-function, max-statements
export const validateInviteForm = async (
  values: Record<string, any>,
  params: InvitePageParams,
  step: number | undefined,
): Promise<ValidationErrors> => {
  const { askPhoneNumber, askDomains, anonymised, consents, overridePassword } =
    params;

  if (askPhoneNumber) {
    stepValidationFields[0].push("phone");
    requiredFields.push("phone");
  }

  if (askDomains) {
    stepValidationFields.splice(2, 0, ["domains"]);
  }

  const currentStepFields =
    step !== undefined ? stepValidationFields[step] : [];
  const errors: ValidationErrors = { client: {}, consent: {} };
  const { client } = values;

  const requiredFieldsCopy = intersection(currentStepFields, requiredFields);

  if (anonymised) {
    pull(requiredFieldsCopy, "first_name", "last_name", "birthdate", "gender");
  }

  if (overridePassword) {
    pull(requiredFieldsCopy, "password", "password_confirmation");
  }

  if (!/.+@.+/.test(client.email)) {
    errors.client.email = (
      <FormattedMessage id="sessions.reset.invalid_email" />
    );
  }

  if (includes(currentStepFields, "birthdate") && !anonymised) {
    const {
      birthdate: { year, month, day },
    } = values;
    const isoBirthdate = `${year}-${padDate(month)}-${padDate(day)}`;
    const bday = dayjs(isoBirthdate, "YYYY-MM-DD", true);
    const missing = !isEmpty([year, month, day].filter(field => !field));

    if (!bday.isValid() || bday.diff(dayjs()) > 0) {
      errors.birthdate_fields = (
        <FormattedMessage id="people.birthdate_invalid" />
      );
    }

    if (includes(requiredFieldsCopy, "birthdate")) {
      pull(requiredFieldsCopy, "birthdate");
      if (missing) {
        errors.birthdate_fields = (
          <FormattedMessage id="form_validation.required_field" />
        );
      }
    }
  }

  if (includes(requiredFieldsCopy, "phone")) {
    // Dynamically load the phone number validation
    const { isValidPhoneNumber } = await import("libphonenumber-js/min");

    if (!isValidPhoneNumber(values.client?.phone)) {
      errors.client.phone = (
        <FormattedMessage id="form_validation.invalid_phone_number" />
      );
    }
  }

  const passwordIsRequiredAndIsPresent =
    includes(requiredFieldsCopy, "password") &&
    values.client?.password?.length > 0;

  if (passwordIsRequiredAndIsPresent) {
    const tooShort = values.client?.password?.length < PASSWORD_LENGTH;
    const invalidPasswordFormatting = !PASSWORD_RE.test(
      values.client?.password,
    );
    if (tooShort) {
      errors.client.password = (
        <FormattedMessage
          id="form_validation.minimum_length"
          values={{ count: PASSWORD_LENGTH }}
        />
      );
    } else if (invalidPasswordFormatting) {
      errors.client.password = (
        <FormattedMessage id="form_validation.password_requirements" />
      );
    }
  }

  if (includes(currentStepFields, "domains")) {
    if (askDomains && isEmpty(values.client["domains"])) {
      errors.client["domains"] = (
        <FormattedMessage id="form_validation.required_domains" />
      );
    }
  }

  requiredFieldsCopy.forEach(field => {
    if (!client[field]) {
      errors.client[field] = (
        <FormattedMessage id="form_validation.required_field" />
      );
    }
  });

  const isLastStep = step === stepValidationFields.length - 1;
  if (isLastStep) {
    consents.forEach(consent => {
      const consentAbsent =
        !values.consent ||
        !values.consent[consent.external_id] ||
        isEmpty(values.consent[consent.external_id]);
      if (consent.required && consentAbsent) {
        errors.consent[consent.external_id] = (
          <FormattedMessage id="form_validation.required_consent" />
        );
      }
    });
  }

  return errors;
};
