/* eslint-disable max-lines */
import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import SpeechBubble from "../../../common/components/SpeechBubble";
import {
  colors,
  linkColor,
  linkHoverColor,
  scaffolding,
  spacing,
  typography,
  zIndexes,
} from "@netmedi/design-system/dist/styles/variables";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";
import { Icon, Button } from "@netmedi/design-system";
import { rgba } from "polished";
import NativeSelect from "shared/components/NativeSelect";

const cellSize = 62;
const bubbleWidth = 23;
const bubbleHeight = 18;
const symptomRowHeight = 62;
const headerHeight = symptomRowHeight + 8;
const rowTitleWidth = 200;
const headerDateItemHeight = 26;

export const SpeechBubbleStyled = styled(SpeechBubble)`
  display: block;
  height: ${bubbleHeight}px;
  width: ${bubbleWidth}px;
  z-index: ${zIndexes.tooltip - 1};
  position: absolute;
  top: 0px;
  ${rtl`left: ${(cellSize - bubbleWidth) / 2}px`};
`;

export const NoSelect = styled.div`
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently supported by Chrome and Opera */
`;

export const PeriodTitleStyled = styled.div.attrs(() => ({
  "aria-hidden": true,
}))`
  position: absolute;
  top: -60px;
  ${rtl`left: 32px;`}
  overflow: hidden;
`;

export const SymptomTableStyled = styled.table.attrs(() => ({
  "data-testid": "symptomtable",
  role: "grid",
}))`
  tr {
    height: ${symptomRowHeight}px;
    padding: 0;
  }

  tbody {
    tr:nth-child(odd) {
      background: ${colors.gray50};

      #scoreSeverity {
        background: ${colors.gray50};
      }
    }
  }

  thead {
    tr {
      height: ${headerHeight}px;
    }
  }

  td {
    position: relative;
    width: ${symptomRowHeight}px;
    padding: 0;
  }

  th {
    padding: 0;
    font-weight: 400;
  }

  th:first-child {
    ${rtl`text-align: left`};
    ${rtl`padding-left: 16px`};
  }

  overflow: visible;
  font-size: ${typography.fontSizeMedium};
  margin-bottom: ${spacing.xs}px;
  width: 100%;
`;

export const NoSymptomsReported = styled.div`
  padding: ${spacing.s}px;
  text-align: center;
  color: ${colors.gray700};
`;

type VerticalLineProps = {
  $periodStart?: boolean;
  $top?: boolean;
  $bottom?: boolean;
  $header?: boolean;
  $last?: boolean;
};

const periodStartStyles = css`
  top: ${-1 * symptomRowHeight}px;
  height: 130px;
`;

const topStyles = css`
  top: 0px;
`;

const bottomStyles = css`
  top: ${symptomRowHeight - headerDateItemHeight - 6}px;
`;

export const VerticalLine = styled.div<VerticalLineProps>`
  width: 1px;
  ${rtl`border-left: 1px solid ${colors.gray300};`}
  height: ${symptomRowHeight}px;
  position: absolute;
  ${rtl`left: 50%;`}
  top: 0px;
  z-index: 1;

  ${({ $header }) =>
    $header &&
    css`
      height: ${headerHeight}px;
    `}

  ${({ $last }) =>
    $last &&
    css`
      height: ${symptomRowHeight / 2}px;
    `}

  ${({ $top }) => $top && topStyles}

  ${({ $bottom }) => $bottom && bottomStyles}

  ${({ $periodStart }) => $periodStart && periodStartStyles}
`;

type HeaderDateProps = {
  $overlapping?: boolean;
  $compact?: boolean;
  $top?: boolean;
  $bottom?: boolean;
};

const overlappingStyles = css`
  position: absolute;
  z-index: 2;
  ${rtl`
    left: 50%;
    transform: translateX(-50%)`};
`;

const compactStyles = css`
  white-space: normal;
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${rem(78)};
  height: ${rem(60)};
  ${rtl`
    margin-left: 4px;
    margin-right: 4px;`}
`;

export const HeaderDateStyled = styled.span.attrs(() => ({
  "data-testid": "symptom-table-header-date",
}))<HeaderDateProps>`
  white-space: nowrap;
  padding: 2px 6px;
  border-radius: 4px;
  border: 1px solid ${colors.gray500};
  background: ${colors.white};
  font-size: ${typography.fontSizeSmall};
  font-weight: ${scaffolding.bold};

  ${({ $top }) => $top && topStyles}

  ${({ $bottom }) => $bottom && bottomStyles}

  ${({ $overlapping }) => $overlapping && overlappingStyles}

  ${({ $compact }) => $compact && compactStyles}
`;

type HeaderDateWrapperProps = {
  $relative?: boolean;
};

const relativeStyles = css`
  position: relative;
  top: 0;
  ${rtl`right: 0;`}
  width: ${cellSize}px;
  height: ${cellSize}px;
`;

export const HeaderDateWrapper = styled.div<HeaderDateWrapperProps>`
  ${({ $relative }) => $relative && relativeStyles}
`;

export const IntervalSelect = styled(NativeSelect)`
  width: 75%;
  z-index: ${zIndexes.headerOverlap};
`;

export const DropdownIcon = styled(Icon).attrs(() => ({
  role: "button",
}))<{ open: boolean }>`
  vertical-align: middle;
  cursor: pointer;

  ${({ open }) => css`
    transform: rotate(${open ? 90 : 0}deg);
  `}

  transition: 0.3s;
`;

export const SymptomName = styled.span`
  ${rtl`text-align: left;`}
  overflow: hidden;
  text-overflow: ellipsis;
  width: ${rowTitleWidth}px;
  display: inline-block;
  vertical-align: middle;
`;

export const SubRow = styled.tr<{ show: boolean }>`
  ${css`
    background: ${colors.white} !important;
  `}

  ${({ show }) => !show && "display: none;"}

  ${SymptomName} {
    ${rtl`padding-left: 32px;`}
  }
`;

export const SymptomNameLabel = styled.span`
  display: block;
  font-size: ${rem(14)};
  line-height: ${rem(22)};
  color: ${colors.gray700};
`;

const aboveVerticalLine = css`
  position: relative;
  z-index: 2;
`;

export const SymptomWrapper = styled.span.attrs(() => ({
  "data-testid": "symptom-wrapper",
}))`
  width: 100%;
  text-align: center;
  display: inline-block;
  ${aboveVerticalLine}
`;

export const PaddingCell = styled.td`
  width: 100%;
`;

export const StepsRow = styled.tr.attrs(() => ({
  "data-testid": "symptom-table-steps-row",
}))`
  & > td,
  & > th {
    padding-top: ${rem(12)};
  }

  &&:nth-child(odd) {
    background: ${colors.white};
  }
`;

export const GraphRow = styled.tr`
  &&:nth-child(odd) {
    background: ${colors.white};
  }
`;

export const ToggleShowMore = styled.div.attrs(() => ({
  "data-testid": "toggle-show-more",
  tabIndex: 0,
  role: "button",
}))`
  padding: 12px;
  background-color: ${colors.yellow50};
  border: 1px solid ${colors.gray300};
  text-align: center;
  cursor: pointer;
  width: 50%;
  min-width: 300px;
  margin: 0 auto ${spacing.xs}px;

  a {
    font-weight: ${scaffolding.bold};
    color: ${linkColor};

    &:hover {
      color: ${linkHoverColor};
    }
  }

  &[data-focus-visible-added] {
    outline: 2px solid ${colors.blue700};
  }
`;

export const Controls = styled.div.attrs(() => ({
  "data-testid": "symptom-table-controls",
}))`
  ${rtl`margin-left: ${rowTitleWidth}px;`}
  height: ${headerHeight}px;
  padding: 8px 0px;
  z-index: 10;
`;

type ControlButtonProps = {
  $left?: boolean;
  $right?: boolean;
};

const ControlButtonLeft = css`
  ${rtl`
    float: left;
    background: linear-gradient(to right, ${colors.white} 50px, ${rgba(
      255,
      255,
      255,
      0,
    )});`}

  button {
    ${rtl`margin: 12px 68px 8px 8px;`}
  }
`;

const ControlButtonRight = css`
  ${rtl`
    float: right;
    background: linear-gradient(to left, ${colors.white} 50px, ${rgba(
      255,
      255,
      255,
      0,
    )});`}

  button {
    ${rtl`margin: 12px 8px 8px 68px;`}
  }
`;

export const ControlButton = styled.div.attrs(() => ({
  "data-testid": "symptom-table-control-button",
}))<ControlButtonProps>`
  background-color: ${colors.white};
  position: relative;
  z-index: 2;

  ${({ $left }) => $left && ControlButtonLeft}
  ${({ $right }) => $right && ControlButtonRight}
`;

export const StepsWrapper = styled.td`
  padding-top: ${rem(34)};
  text-align: center;
  font-size: ${typography.fontSizeBase};
  line-height: 1;
`;

export const Steps = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 48px;
`;

export const StepsCount = styled.span.attrs(() => ({
  "data-testid": "symptom-table-steps-count",
}))`
  font-size: ${typography.fontSizeBase};
  line-height: ${rem(22)};
`;

type IconProps = {
  $hidden: boolean;
};

export const UpIcon = styled(Icon)<IconProps>`
  color: #29951b;

  ${({ $hidden }) =>
    $hidden &&
    css`
      visibility: hidden;
    `}
`;

export const DownIcon = styled(Icon)<IconProps>`
  color: #ee1111;

  ${({ $hidden }) =>
    $hidden &&
    css`
      visibility: hidden;
    `}
`;

export const DisclaimerButton = styled(Button)`
  padding: 0;
  ${rtl`margin-left: 0;`}
  margin-top: 1em;
  color: ${colors.gray700};
`;

export const AttachmentBadge = styled(Icon).attrs({
  name: "attachment_16px",
  size: "medium",
})`
  position: absolute;
  top: 0;
  ${rtl`right: 0;`}
  background: #fff;
  padding: 4px;
  z-index: 1000;
  border-radius: 100%;
`;
