import styled, { css } from "styled-components";
import rtl from "styled-components-rtl";
import { NavLink } from "react-router-dom";
import {
  navigation,
  scaffolding,
  choiceActiveBg,
  zIndexes,
  tagsInConversation,
} from "@netmedi/design-system/dist/styles/variables";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";

const activeLink = `
  background-color: ${choiceActiveBg};
  color: ${navigation.sidebar.linkHoverColor};
`;

const navlinkOrMore = `
  display: block;
  height: 100%;
  text-align: center;
  line-height: ${navigation.mobileNavHeight}px;

  &:link, &:visited {
    color: ${navigation.sidebar.linkColor};
  }

  &:focus {
    background-color: ${choiceActiveBg};
    color: ${navigation.sidebar.linkHoverColor};
  }

  &:hover {
    color: ${navigation.sidebar.linkHoverColor};
  }
`;

const fullNavOrMore = rtl`
  position: absolute;
  bottom: 0;
  top: 0;
  right: 0;
`;

type HiddenItem = { hiddenItems: boolean };

type CustomNavLi = { navLink: boolean; itemsLength: number };

type ActiveItem = { activeClassName: string; className: string };

type MoreLinkOpen = { $open: boolean };

export const NavItemLink = styled.a`
  ${navlinkOrMore}
  ${activeLink}
`;

export const MobileMoreLink = styled.a<MoreLinkOpen>`
  ${fullNavOrMore}
  ${navlinkOrMore}
  ${props =>
    props.$open &&
    css`
      background-color: ${choiceActiveBg};
    `}
  padding: 0 1rem;
`;

export const MobileNav = styled.nav`
  position: absolute;
  bottom: 0;
  ${rtl`left: 0;`}
  ${rtl`right: 0;`}
  height: ${navigation.mobileNavHeight}px;
  border-top: 1px solid ${scaffolding.gridBorderColor};
  font-size: ${rem(12)};

  li {
    display: inline-block;
  }

  + #content .user_icon {
    display: inline-block;
  }
`;

export const MobileNavList = styled.ul<HiddenItem>`
  height: 100%;

  li {
    height: 100%;
  }

  ${({ hiddenItems }) => !hiddenItems && rtl`margin-right: 56px;`}
`;

export const MobileNavItem = styled.li<CustomNavLi>`
  ${({ navLink, itemsLength }) =>
    navLink && `width: ${(1 / itemsLength) * 100}%`}
`;

export const StyledNavLink = styled(NavLink)<ActiveItem>`
  ${props => props.className === "navigation" && navlinkOrMore}
  &.${props => props.activeClassName} {
    ${activeLink}
  }
`;

export const MobileOverlay = styled.div`
  position: fixed;
  top: 0;
  ${rtl`left: 0;`}
  ${rtl`right: 0;`}
  bottom: ${navigation.mobileNavHeight};
  z-index: ${zIndexes.overlay};
  background: rgba(
    ${tagsInConversation.overlayColor},
    ${tagsInConversation.overlayOpacity}
  );
  animation: fadeIn 0.2s;
`;

export const MobileFullNav = styled.nav`
  ${fullNavOrMore}
  top: auto;
  ${rtl`left: 0;`}
  bottom: ${navigation.mobileNavHeight};
  z-index: ${zIndexes.modal};
  background: ${scaffolding.sidebarBackground};
  border: 1px solid ${scaffolding.gridBorderColor};
  border-bottom: 0px;
  animation: fadeInUp 0.4s;
  margin-bottom: ${navigation.mobileNavHeight}px;

  li {
    display: block;
    font-size: ${scaffolding.fontSizeBase};
  }

  a {
    display: block;
    padding: 1em;
    margin: 0;

    &:link,
    &:visited,
    &:hover {
      color: ${navigation.sidebar.linkColor};
    }

    &.active {
      background-color: ${choiceActiveBg};
      color: ${navigation.sidebar.linkHoverColor};
    }
  }

  label {
    ${rtl`padding-left: 1em;`}
  }
`;
