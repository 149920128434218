/* eslint-disable react/jsx-max-depth */
import React, { useEffect, Fragment } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@netmedi/design-system";
import { useDispatch, useSelector } from "store";
import { getTreatmentProgramStubs } from "hcp/actions/treatmentPrograms";
import {
  fetchMassNotification,
  resetMassNotification,
} from "common/actions/massNotifications";
import { TreatmentProgram } from "hcp/models/treatmentProgram";
import { MassNotification } from "../Messaging/MassNotification.types";
import { TreatmentProgramLabel } from "common/components/UserInfo/TreatmentProgramLabels";
import {
  NotificationHeader,
  Attachment,
  MassNotificationTable,
  MassNotificationTP,
  HeaderWrapper,
  SummaryView,
  NotificationBody,
} from "./MassNotification.styles";
import { BackButton } from "./BackButton";
import { selectMassNotificationRecipientCounts } from "common/reducers/massNotifications";
import { sum } from "lodash";
import ParseLinks from "common/components/ParseLinks/ParseLinks";

type Props = {
  showableMassMessages?: MassNotification[];
  id?: string;
};

type TableRowProps = {
  notification: MassNotification;
  programStubs: TreatmentProgram[];
};

type TableDataProps = {
  notification: MassNotification;
};

const TableRow = ({ notification, programStubs: programs }: TableRowProps) => {
  const MAX_VISIBLE_PROGRAMS = 5;
  const recipientCounts = useSelector(selectMassNotificationRecipientCounts);
  const recipientCount =
    notification.attributes?.language === "all"
      ? sum(Object.values(recipientCounts))
      : recipientCounts[notification.attributes?.language];
  const programCount = notification.attributes?.treatment_programs?.length ?? 0;
  const excessProgramCount = Math.max(0, programCount - MAX_VISIBLE_PROGRAMS);

  return (
    <tr data-testid="recipient-table">
      <td>
        <FormattedMessage
          id={
            notification.attributes?.user_type === "client"
              ? "mass_message.patients"
              : "mass_message.staff"
          }
        />
      </td>
      <td>
        {notification.attributes.include_clients_with_no_programs && (
          <FormattedMessage id="mass_message.without_treatment_program" />
        )}
        <br />
        {notification.attributes?.treatment_programs.length > 0 && (
          <FormattedMessage id="mass_message.patients_in" />
        )}{" "}
        {programs
          .filter(
            (stub: TreatmentProgram) =>
              stub.id &&
              notification.attributes?.treatment_programs?.includes(
                Number(stub.id),
              ),
          )
          .slice(0, MAX_VISIBLE_PROGRAMS)
          .map((tp: TreatmentProgram) => (
            <MassNotificationTP key={tp.id}>
              <TreatmentProgramLabel
                key={"tplabel" + tp.id}
                treatment_program={tp}
                setTooltip={() => {}}
                clearTooltip={() => {}}
              />
            </MassNotificationTP>
          ))}
        {excessProgramCount > 0 && (
          <FormattedMessage
            id="mass_message.excess_progams"
            values={{ count: excessProgramCount }}
          />
        )}
      </td>
      <td>
        <FormattedMessage
          id={
            notification.attributes?.language === "all"
              ? "mass_message.language_mode.all_languages"
              : `languages.${notification.attributes?.language}`
          }
        />
      </td>
      <td>
        {recipientCount !== undefined && (
          <span data-testid="recipient-count">{recipientCount}</span>
        )}
      </td>
    </tr>
  );
};

const TableData = ({ notification }: TableDataProps) => {
  const intl = useIntl();
  const recipientCounts = useSelector(selectMassNotificationRecipientCounts);
  const language = notification.attributes?.language;
  const recipientCount = recipientCounts[language];
  const languageName = intl.formatMessage({
    id:
      notification.attributes?.language === "all"
        ? "mass_message.language_mode.all_languages"
        : `languages.${notification.attributes?.language}`,
  });

  return (
    <span key={notification.attributes?.subject}>
      <FormattedMessage
        tagName="h3"
        id={
          recipientCount !== undefined
            ? "mass_message.message_title_with_count"
            : "mass_message.message_title"
        }
        values={{
          language: languageName,
          amount: recipientCount,
        }}
      />
      <NotificationHeader>
        {notification.attributes?.subject}
      </NotificationHeader>
      <NotificationBody>
        <ParseLinks>{notification.attributes?.body}</ParseLinks>
      </NotificationBody>
      {notification.attributes.files &&
        notification.attributes.files.length > 0 &&
        notification.attributes.files.map(file => {
          return (
            <a href={file.url} key={file.name} target="_blank">
              <Attachment>
                <Icon size="medium" name="attachment_16px" />
                {file.name}
              </Attachment>
            </a>
          );
        })}
    </span>
  );
};

const MassNotificationSummaryView = ({
  showableMassMessages = [],
  id,
}: Props) => {
  const dispatch = useDispatch();

  const treatmentPrograms = useSelector(({ treatmentPrograms }) => ({
    ...treatmentPrograms,
  }));

  const massNotifications = useSelector(({ massNotifications }) => ({
    ...massNotifications,
  }));

  useEffect(() => {
    dispatch(getTreatmentProgramStubs());
    if (id) {
      // Fetch single mass message details
      const messageId = parseInt(id, 10);
      dispatch(fetchMassNotification(messageId));
    }
    return () => {
      // Reset only if opened from announcement list
      if (id) {
        dispatch(resetMassNotification());
      }
    };
  }, []);

  const { stubs = [] } = treatmentPrograms;
  const { singleMassNotification } = massNotifications;

  return (
    <SummaryView>
      {id && <BackButton />}
      <HeaderWrapper>
        <FormattedMessage tagName="h1" id="mass_message.summary_header" />
      </HeaderWrapper>
      <MassNotificationTable pageLoading={false}>
        <thead>
          <tr>
            <td>
              <strong>
                <FormattedMessage id="mass_message.summary_headers.group" />
              </strong>
            </td>
            <td>
              <strong>
                <FormattedMessage id="mass_message.summary_headers.programs" />
              </strong>
            </td>
            <td>
              <strong>
                <FormattedMessage id="mass_message.summary_headers.language" />
              </strong>
            </td>
            <td>
              <strong>
                <FormattedMessage id="mass_message.summary_headers.recipients" />
              </strong>
            </td>
          </tr>
        </thead>
        <tbody>
          {id && singleMassNotification ? (
            <TableRow
              notification={singleMassNotification}
              programStubs={stubs}
            />
          ) : (
            showableMassMessages.map((notification: MassNotification) => (
              <Fragment key={notification.attributes.language}>
                <TableRow notification={notification} programStubs={stubs} />
              </Fragment>
            ))
          )}
        </tbody>
      </MassNotificationTable>
      {id && singleMassNotification ? (
        <TableData notification={singleMassNotification} />
      ) : (
        showableMassMessages.map((notification: MassNotification) => (
          <Fragment key={notification.attributes.language}>
            <TableData notification={notification} />
          </Fragment>
        ))
      )}
    </SummaryView>
  );
};

export default MassNotificationSummaryView;
