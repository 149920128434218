import styled from "styled-components";
import {
  spacing,
  mediaQueries,
} from "@netmedi/design-system/dist/styles/variables";
import { mediaMin } from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";
import rtl from "styled-components-rtl";

export const Container = styled.div`
  margin-bottom: ${spacing.m}px;

  ${mediaMin(mediaQueries.medium)} {
    display: flex;

    img {
      ${rtl`float: left;`}
      width: 50%;
      ${rtl`padding-right: ${spacing.s}px;`}
      margin-bottom: 10px;
    }
  }
`;
