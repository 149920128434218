import React from "react";
import { FormattedMessage } from "react-intl";
import { ButtonExtras } from "@netmedi/design-system";
const { LinkButton } = ButtonExtras;
import { SiteSettings } from "common/utils/holvikaari";
import { getInfoBoxParams } from "shared/epics/flipperUINotification";

const articlePath = "/articles/44";

const introductionArticle: getInfoBoxParams = (id, dismiss) => [
  {
    icon: "alert_circle_48px",
    title: (
      <FormattedMessage
        id="introduction_flash.info_heading_text"
        values={{ product_name: SiteSettings.product_name }}
      />
    ),
    body: <FormattedMessage id="introduction_flash.info_text" />,
    buttons: (
      <span>
        <LinkButton size="small" to={articlePath} onClick={() => dismiss()}>
          <FormattedMessage id="introduction_flash.info_link_text" />
        </LinkButton>
      </span>
    ),
    id,
    dismiss,
  },
];

export default introductionArticle;
