import styled from "styled-components";
import rtl from "styled-components-rtl";
import {
  colors,
  typography,
  scaffolding,
  mediaQueries,
} from "@netmedi/design-system/dist/styles/variables";
import { mediaMax } from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";

export const SearchBarWrapper = styled.div`
  width: 100%;
  ${mediaMax(767)} {
    width: auto;
    margin: -20px -20px 0 -20px;
  }
`;

export const SearchBarStyled = styled.div`
  background-color: ${colors.gray50};
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-bottom: ${colors.gray300} 1px solid;
  ${rtl`
    padding: 4px 16px 4px 20px; // The invite button has 4px margin
  `}
  ${mediaMax(mediaQueries.small)} {
    ${rtl`padding: 4px 8px 4px 12px;`};
  }
`;

export const InputContainer = styled.div`
  flex-grow: 1;
  // Will always wrap the button to a new row at 460px
  ${mediaMax(460)} {
    flex-basis: 100%;
  }
`;

export const DropDownContainer = styled.div`
  margin: 1rem;
  text-align: center;
  font-size: ${typography.fontSizeMedium};

  a {
    font-weight: ${scaffolding.bold};
    margin-top: 1rem;
  }
`;
