import React from "react";
import DatePicker, { IProps as DatePickerProps } from "./DatePicker";
import FormField, { FormFieldProps } from "./FormField";
import { DatePickerInputContainer, ErrorMsg } from "./FormField.styles";
import { useFormState } from "react-final-form";
import { Button } from "@netmedi/design-system";

export type DatePickerInputProps = Omit<FormFieldProps, "children"> &
  DatePickerProps;

const DatePickerInput: React.FC<DatePickerInputProps> = props => {
  const { errors, touched: touchedFields, submitFailed }: any = useFormState();
  const { actions } = props;
  const error = props.name ? errors[props.name] : null;
  const touched = props.name ? touchedFields?.[props.name] : null;

  return (
    <div data-testid="DatePickerInput">
      <FormField {...props}>
        <DatePickerInputContainer>
          <DatePicker
            {...props}
            error={!!error}
            touched={touched}
            submitFailed={submitFailed}
          />
          {actions?.map(action => (
            <Button
              key={action.title}
              onClick={action.onClick}
              size="small"
              type={action.type}
            >
              {action.title}
            </Button>
          ))}
        </DatePickerInputContainer>
      </FormField>
      {touched && error && <ErrorMsg>{error}</ErrorMsg>}
    </div>
  );
};

export default DatePickerInput;
