import styled from "styled-components";
import {
  text,
  bodyText as bt,
  baseColors,
} from "@netmedi/design-system/dist/styles/variables";

export type FontSize = keyof typeof text.size;
export interface TypographyProps {
  fontSize?: FontSize;
  bodyText?: FontSize;
  fontWeight?: number;
  color?: keyof typeof baseColors;
}

export const Typography = styled.div<TypographyProps>`
  ${({ bodyText }) => bodyText && bt[bodyText]}
  ${({ color }) => color && baseColors[color]}
  ${({ fontWeight }) => fontWeight && `font-weight: ${fontWeight};`}
`;
