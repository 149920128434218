import { get, getWithoutPageLoadError, endpoints } from "common/utils/api";
import {
  ObservationsData,
  StepsQueryProps,
} from "shared/components/SymptomTableCompact/types";
import dayjs from "dayjs";
import localeData from "dayjs/plugin/localeData";
import { ValidInterval } from "shared/components/SymptomTable/SymptomTable";
import { Article, ArticleWithContent } from "shared/models/article";
import { State as ClientState } from "client/reducers/client";
import { Document as SharedDocument } from "shared/components/DocumentCard/SharedDocumentCard";
import { symptomToCompareConverter } from "client/utils/CompareAnswers";
import getApiClient from "common/utils/api2/getApiClient";

// used in client and user reducers
export type SetArticlesAction = { type: "SET_ARTICLES"; data: Article[] };

// used in client reducer
export type ClientAction =
  | {
      type: "SET_BASIC_DETAILS";
      data: { firstName: string; lastName: string };
    }
  | SetArticlesAction
  | {
      type: "SET_ARTICLE";
      data: ArticleWithContent;
    }
  | {
      type: "SET_DOCUMENTS";
      data: SharedDocument[];
    }
  | {
      type: "LOAD_ALL_SYMPTOMS";
      data: any;
    }
  | { type: "SET_GRAPHS"; data: any[] }
  | { type: "SET_COMPARE"; data: ClientState["compare"] }
  | {
      type: "GET_EMBEDDABLE_VIEWS";
      data: ClientState["embeddable_views"];
    }
  | {
      type: "SET_IFAS";
      data: ClientState["answers"];
    }
  | {
      type: "SET_STEPS";
      data: ObservationsData;
      interval: ValidInterval;
    };

export const fetchBasicDetails = (clientId: string): Promise<ClientAction> =>
  get(endpoints.users.user(clientId))
    .then(({ data }) => ({
      firstName: data.attributes.first_name,
      lastName: data.attributes.last_name,
    }))
    .then(data => ({
      type: "SET_BASIC_DETAILS",
      data,
    }));

export const getEmbeddableViews = (): Promise<ClientAction> =>
  get(endpoints.embeddable_views.index).then(data => ({
    type: "GET_EMBEDDABLE_VIEWS",
    data: data,
  }));

export const getIFAs = (
  client: string,
  form = undefined,
  dontHandleErrors = false,
): Promise<ClientAction> =>
  (dontHandleErrors ? getWithoutPageLoadError : get)(
    endpoints.client.answers(client, form),
  ).then(data => ({
    type: form ? "SET_COMPARE" : "SET_IFAS",
    data: data,
  }));

export const getAllSymptoms = (id: string): Promise<ClientAction> =>
  get(endpoints.client.allSymptoms(id)).then(data => ({
    type: "LOAD_ALL_SYMPTOMS",
    data,
  }));

export const getValuesComparison = (
  client: string,
  _: any,
  dataAdapter: ReturnType<typeof symptomToCompareConverter>,
): Promise<ClientAction> =>
  get(endpoints.client.allSymptoms(client, { series: ["values"] }))
    .then(dataAdapter)
    .then(data => ({
      type: "SET_COMPARE",
      data,
    }));

export const getGraphs = (id: string): Promise<ClientAction> =>
  get(endpoints.client.graphs(id)).then(data => ({
    type: "SET_GRAPHS",
    data,
  }));

export const getArticles = (sort?: "order"): Promise<ClientAction> =>
  getApiClient()
    .articles()
    .get({ sort })
    .then((data: any) => ({ type: "SET_ARTICLES", ...data }));

export const getSharedDocuments = (): Promise<ClientAction> =>
  get(endpoints.shared_documents.show()).then(data => ({
    type: "SET_DOCUMENTS",
    data: data.attachments,
  }));

export const getArticleWithCategoryMatchAllowed = (
  id: string,
): Promise<ClientAction> =>
  getApiClient()
    .articleCategoryIdMatch({ id })
    .get()
    .then((data: any) => ({ type: "SET_ARTICLE", ...data }));

export const getSteps = (
  id: string,
  { interval }: StepsQueryProps,
): Promise<ClientAction> => {
  // Use 1 year for demo purposes only.
  // This should probably be the start_date coming
  // from the patient's treatments with some minimum, like a week.
  const ONE_YEAR_AGO = dayjs().subtract(1, "year");
  const browser_time_zone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  dayjs.extend(localeData);
  return get(
    endpoints.client.observations(id, {
      start_date: ONE_YEAR_AGO.toISOString(),
      end_date: dayjs().toISOString(),
      first_day_of_week:
        dayjs.localeData().firstDayOfWeek() === 1 ? "monday" : "sunday",
      value_type: "steps",
      interval,
      browser_time_zone,
    }),
  ).then(data => ({
    type: "SET_STEPS",
    interval,
    data,
  }));
};
