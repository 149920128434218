import { padStart, isNil, groupBy, isPlainObject, capitalize } from "lodash";
import { SiteSettings } from "common/utils/holvikaari";
import dayjs, { Dayjs } from "dayjs";
import timezone from "dayjs/plugin/timezone";
dayjs.extend(timezone);

// Loosely simulates Bluebird finally.
export const lastly = (
  promise_arg: Promise<any>,
  after_fn: (err: any, val: any) => any,
) =>
  promise_arg
    .then(val => [undefined, val])
    .catch(err => [err, undefined])
    .then(([err, val]) => after_fn(err, val));

export const lock = (
  promise_arg: Promise<any>,
  lock_fn: (...args: any[]) => any,
) => {
  lock_fn(true);
  lastly(promise_arg, (err, val) => lock_fn(false, err, val));
};

export const padDate = (num?: number) => padStart(num?.toString(), 2, "0");
// convert from date string to an object so we can show fields for day/month/year
export const birthdate = (date?: string) => {
  if (date === undefined) return undefined;
  const bd = dayjs(date);
  return {
    bd_m: bd.month() + 1,
    bd_d: bd.date(),
    bd_y: bd.year(),
  };
};

export const stringToDayjs =
  (formats?: string | string[]) =>
  (date: string | Dayjs | undefined): Dayjs => {
    if (typeof date !== "string") return date ?? dayjs();
    return dayjs(date, formats, true);
  };

export const parseIsoString = (date: string | Dayjs) => {
  const ex = /\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?(?:[+-]\d{2}:\d{2}|Z)/;
  if (typeof date === "string" && !ex.test(date)) {
    return dayjs("Invalid date");
  } else return stringToDayjs()(date);
};

/**
 * Rails and HTML date input use the "YYYY-MM-DD" format.
 */
export const parseDateString = stringToDayjs("YYYY-MM-DD");

/**
 * Formats dates into "YYYY-MM-DD" format for Rails and HTML date input usage.
 */
export const formatDate = (date: Dayjs): string => {
  return date.format("YYYY-MM-DD");
};

export const sortByDate = (a: string, b: string) =>
  parseIsoString(a).valueOf() - parseIsoString(b).valueOf();

export const defaults = (
  val: string | number | undefined,
  def: string,
): string | number => (isNil(val) ? def : val);

export const isIE = /(MSIE|Trident)/.test(window.navigator.userAgent);

// Used to check whether or not the site has conversations enabled at all on any domain
// Needed for when the user is not yet initialized from the state during loading
export const areConversationsEnabled = () => {
  if (isPlainObject(SiteSettings.conversations)) {
    return Object.values(SiteSettings.conversations).includes(true);
  } else {
    return SiteSettings.conversations;
  }
};

// https://stackoverflow.com/questions/27176983/dispatchevent-not-working-in-ie11/32889608#32889608
export const elDispatchEvent = (el: HTMLElement, ev: string) => {
  let event;
  if (isIE) {
    event = document.createEvent("HTMLEvents");
    event.initEvent(ev, true, true);
  } else {
    event = new Event(ev);
  }
  return el.dispatchEvent(event);
};

// Removes the locale part of the url
// From: /en-US/path/to/somewhere
// to: /path/to/somewhere
export const cleanUrlLocalePrefix = (path: string) => {
  return "/" + path.split("/").slice(2).join("/");
};

export const toCamelCase = (input?: string) =>
  input
    ?.split("")
    .reduce((acc, currVal, currIdx) => {
      if (currVal === currVal.toUpperCase() && currIdx > 0) acc.push("_");
      acc.push(currVal.toLowerCase());
      return acc;
    }, [] as string[])
    .join("");

export const averageTime = (...times: Dayjs[]) =>
  dayjs((1000 * times.reduce((m, i) => m + i.unix(), 0)) / times.length);

export const isToday = (date: Dayjs | string) =>
  parseIsoString(date).isSame(dayjs(), "day");

export const splitBy = <T,>(
  collection: T[],
  predicate: (val: T) => boolean,
) => {
  const { true: truthy, false: falsy } = groupBy(collection, predicate);
  const empty = collection.constructor;
  return [truthy || empty(), falsy || empty()];
};

export const tap =
  <T,>(fn: (arg: T) => unknown) =>
  (arg: T) => {
    fn(arg);
    return arg;
  };

export class Deferred {
  promise: Promise<any>;
  // @ts-expect-error - TS doesn't recognize that these are set in the constructor
  reject: (value: any) => void;
  // @ts-expect-error - TS doesn't recognize that these are set in the constructor
  resolve: (value: any) => void;

  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.reject = reject;
      this.resolve = resolve;
    });
  }
}

export const validationRegexp = {
  // Rails is using this to validate the email
  email:
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  password: /^(?=.*[a-zåäö])(?=.*[A-ZÅÄÖ])(?=.*\d).{10,}$/,
};
export const idToWords = (key?: string) => capitalize(key?.replace(/_/g, " "));

export const filterById = (id: string) => (item: { id: string }) =>
  item.id !== id;
