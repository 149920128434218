import React from "react";
import {
  SelectDomainsOption,
  DomainSelectionLabel,
} from "./ManageDomainsModal.styles";
import { CheckBox } from "@netmedi/design-system";
import { DomainListProps } from "./DomainList";
import { Option } from "common/models/domain";
import { domainDisabled, taskSubscrioptionDomains } from "./helpers";
import { useSelector } from "react-redux";
import { userDomainsWithOpenIssuesSelector } from "common/selectors";

type CheckboxProps = Omit<DomainListProps, "searchText"> & {
  option: Option;
};

export const DomainCheckbox = ({
  input,
  name,
  option,
  user,
}: CheckboxProps) => {
  const domainsWithOpenIssues = useSelector(userDomainsWithOpenIssuesSelector);
  const disabled = domainDisabled(
    option.value,
    input.value,
    taskSubscrioptionDomains(user),
    domainsWithOpenIssues ?? [],
  );

  return (
    <SelectDomainsOption data-testid={`select-domains-option-${option.value}`}>
      <CheckBox
        value={option.value}
        name={`${name}[]`}
        checked={input.value.includes(option.value)}
        id={`${name}-${option.value}`}
        disabled={disabled}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          const { checked, value } = event.target;

          if (checked) {
            input.onChange([...input.value, value]);
          } else {
            input.onChange(input.value.filter((v: string) => v !== value));
          }
        }}
      />
      <DomainSelectionLabel
        data-testid={`domain-selection-label-${option.value}`}
        htmlFor={`${name}-${option.value}`}
      >
        {option.label}
      </DomainSelectionLabel>
    </SelectDomainsOption>
  );
};
