/* eslint-disable max-lines */

import { mapValues, groupBy, pickBy, find } from "lodash";
import { UnionToIntersection } from "common/utils/types";

/** Types that make up the response **/

type Id = string | number;

type Included<I extends Item | undefined> = [I] extends [Item]
  ? I[]
  : undefined;

type ItemCache = CacheEntry[];

type Links = Record<string, string | null | undefined>;

// type and id are used to match with included items
type RelationshipData = { type: string; id: string };
type Relationship = { data: RelationshipData | RelationshipData[] | null };
type RelationshipMap = Record<string, Relationship | undefined>;

// to make defining relationships easier
type RelationshipOfType<T extends string | string[]> = {
  data: T extends string
    ? { id: string; type: T }
    : T extends string[]
      ? { id: string; type: T[number] }[]
      : never;
};
export type Relationships<T extends Record<string, string | string[]>> = {
  [K in keyof T]: RelationshipOfType<T[K]>;
};

export type Item = {
  id: Id;
  type: string;
  links?: Links | Links[];
  attributes?: Record<string, any>;
  relationships?: RelationshipMap;
};

type Data = Item | Item[];

type Meta = Record<string, any>;

/** Types related to the parser **/

type Opts<M extends boolean> = { mapRelations?: M };

type IdOrItem<M extends boolean> = M extends true ? Item : Id;

type CacheEntry = {
  id: Id;
  type: string;
  cachedItem: ParsedData<Item, Item, true>;
};

/** Generics to handle different response types **/

// the format in which our backend responds
export type JsonApiResponseFormat<
  // the "main" data
  D extends Data,
  // items related to main data
  I extends Item | undefined = undefined,
  // request meta, e.g. pagination info
  M extends Meta | undefined = undefined,
> = {
  data: D;
  // often includes several different types of items
  included: Included<I>;
  links?: Links | Links[];
} & (M extends Meta ? { meta: M } : Record<never, never>);

// output of the parseJson function
export type ParsedResponse<
  D extends Data,
  I extends Item | undefined,
  M extends Meta | undefined,
  // the value of the mapRelations option, which
  // determines whether to map relationship ids to
  // items or just leave them as ids
  Map extends boolean = false,
> = {
  data: ParsedDataWrapper<D, I, Map>;
  links?: Links | Links[];
  meta: Meta;
} & UnionToIntersection<ParsedIncluded<I, Map>> &
  (M extends undefined ? Record<never, never> : M);

/*
 * Parsed included items. If maprelations is true,
 * this is empty, otherwise it's a map of types
 * to items of that type. The items are parsed
 * similarly to how the main data is.
 */
type ParsedIncluded<
  I extends Item | undefined,
  Map extends boolean,
> = Map extends false
  ? I extends Item
    ? {
        [Type in I["type"]]: Record<
          string,
          ParsedData<Extract<I, { type: Type }>>
        >;
      }
    : Record<never, never>
  : Record<never, never>;

// makes dealing with the Item | Item[] aspect easier
type ParsedDataWrapper<
  D extends Data,
  I extends Item | undefined,
  Map extends boolean,
> = D extends Item[]
  ? ParsedData<D[number], I, Map>[]
  : D extends Item
    ? ParsedData<D, I, Map>
    : never;

// the parsed main data
export type ParsedData<
  D extends Item,
  I extends Item | undefined = undefined,
  Map extends boolean = false,
> = { id: D["id"]; links?: D["links"] } & D["attributes"] &
  ParsedRelations<D["relationships"], I, Map>;

/*
 * If mapRelations is false, relations will be mapped to ids.
 * Otherwise, try to map to items, but use ids as fallback.
 */
type ParsedRelations<
  R extends RelationshipMap | undefined,
  I extends Item | undefined,
  Map extends boolean,
> = Map extends true
  ? I extends Item
    ? MappedRelations<R, I>
    : RelationsToId<R>
  : RelationsToId<R>;

// Relation to id map, in case MapRelations is false
type RelationsToId<R extends RelationshipMap | undefined> =
  R extends RelationshipMap
    ? {
        [K in keyof R]: R[K] extends Relationship
          ? R[K]["data"] extends Array<any> | null
            ? string[]
            : string
          : undefined;
      }
    : Record<never, never>;

// Relation to parsed item map, in case MapRelations is true
type MappedRelations<
  R extends RelationshipMap | undefined,
  I extends Item,
> = R extends RelationshipMap
  ? {
      [K in keyof R]: R[K] extends Relationship
        ? MapRelationship<R[K], I>
        : undefined;
    }
  : Record<never, never>;

// if data is null, undefined, otherwise extract correct item type
type MapRelationship<
  R extends Relationship,
  I extends Item,
> = R["data"] extends RelationshipData[]
  ? (ExtractItem<R["data"][number], I> | string)[]
  : R["data"] extends RelationshipData
    ? ExtractItem<R["data"], I> | string
    : undefined;

type ExtractItem<
  R extends RelationshipData,
  I extends Item,
  Extracted = Extract<I, { type: R["type"] }>,
> = Extracted extends Item ? ParsedData<Extracted, I, true> : never;

/*
 * Use cache to avoid infinite loops due to cyclical
 * relationships, and to avoid repetitive parsing
 */
const insertToCache = (
  cache: CacheEntry[],
  id: Id,
  type: string,
  item: any,
) => {
  cache.push({
    id: id,
    type: type,
    cachedItem: item,
  });

  return item;
};

/*
 * If mapRelations is true:
 * Using id and type to identify the correct included item,
 * map an individual relationship to the parsed item,
 * or just return the id if no item is found.
 *
 * If mapRelations is false: Map a relation to its id
 */
export const mapRelationship = <
  I extends Item | undefined,
  M extends boolean = false,
>(
  { id, type }: Item,
  included: Included<I>,
  opts: Opts<M>,
  itemCache: ItemCache,
): IdOrItem<M> => {
  const item = find<Item>(included, { id, type });
  return (
    opts.mapRelations && item ? parseData(included, opts, item, itemCache) : id
  ) as IdOrItem<M>;
};

// Same as mapRelationship, but for all relationships
const parseRelationships = <
  R extends RelationshipMap | undefined = undefined,
  I extends Item | undefined = undefined,
  M extends boolean = false,
>(
  data: R,
  included: Included<I>,
  opts: Opts<M>,
  itemCache: ItemCache,
): ParsedRelations<R, I, M> => {
  return mapValues(
    pickBy(data, val => val?.data),
    rel =>
      rel?.data &&
      (Array.isArray(rel.data)
        ? rel.data.map(data => mapRelationship(data, included, opts, itemCache))
        : mapRelationship(rel.data, included, opts, itemCache)),
  ) as ParsedRelations<R, I, M>;
};

const parseData = <
  D extends Item = Item,
  I extends Item | undefined = undefined,
  M extends boolean = false,
  R extends RelationshipMap | undefined = D["relationships"],
>(
  included: Included<I>,
  opts: Opts<M>,
  data: D,
  itemCache: ItemCache,
): ParsedData<D, I, M> => {
  const { id, type } = data;
  const itemFromCache = find(itemCache, { id, type });

  // Prevent infinite loops caused by circular relationships
  // with keeping the parsed objects in cache.
  if (!itemFromCache) {
    const item = {
      id: id,
      links: data.links,
      ...data.attributes,
    };

    insertToCache(itemCache, id, type, item);

    const relationships = parseRelationships<R, I, M>(
      data.relationships as R,
      included,
      opts,
      itemCache,
    );

    Object.assign(item, relationships);

    return item as ParsedData<D, I, M>;
  } else {
    return itemFromCache.cachedItem as any;
  }
};

const parseIncluded = <I extends Item>(
  included: I[],
  cache: any,
): ParsedIncluded<I, true> => {
  const grouped = groupBy(included, "type") as Record<I["type"], I[]>;
  return mapValues(grouped, itemGroup =>
    Object.fromEntries(
      itemGroup.map(item => [item.id, parseData(undefined, {}, item, cache)]),
    ),
  );
};

/**
  Parse JSON-API responses to a usable format.

  Input: {
    data: {
      id,
      type,
      attributes: { e.g. name, age, etc. }
      relationships: { [key]: id and type to identify relationship, possibly list }
      links: { self, next, last, etc. }
    }, possibly list
    included: { related items, same format as data }[]
    links,
    meta, e.g. pagination
  }

  Output without mapRelations: {
    data: {
      id,
      ...attributes,
      ...relationships, with keys mapped to ids
      links
    },
    ...{
      [type of included item]: {included items of type, parsed similarly to data}[]
    },
    links,
    meta,
    ...meta
  }

  Output with mapRelations: {
    data: {
      id,
      ...attributes,
      ...relationships, with keys mapped to parsed items from
        the included list, or ids if not found
    }
    links,
    meta,
    ...meta
  }
*/
export const parseJson = <
  D extends Data = Data,
  I extends Item | undefined = undefined,
  M extends Meta | undefined = undefined,
  Map extends boolean = false,
>(
  response: JsonApiResponseFormat<D, I, M>,
  opts: Opts<Map> = {},
): ParsedResponse<D, I, M, Map> => {
  const { data, included, links, meta } = { meta: undefined, ...response };
  const cache: CacheEntry[] = [];
  const { mapRelations } = opts;
  // FIXME: support empty resolved promises in case of abort
  return {
    data: (Array.isArray(data)
      ? data.map(data => parseData(included as Included<I>, opts, data, cache))
      : parseData(
          included as Included<I>,
          opts,
          data,
          cache,
        )) as ParsedDataWrapper<D, I, Map>,
    ...(meta ?? {}),
    meta: meta as M,
    links,
    ...(!mapRelations && included ? parseIncluded(included, cache) : {}),
  } as ParsedResponse<D, I, M, Map>;
};

// parseJson with mapRelations set to true
export const parseJsonRelations = <
  D extends Data = Data,
  I extends Item = Item,
  M extends Meta | undefined = undefined,
>(
  data: JsonApiResponseFormat<D, I, M>,
) => parseJson<D, I, M, true>(data, { mapRelations: true });
