import styled, { createGlobalStyle } from "styled-components";
import rem, { padding } from "@netmedi/design-system/dist/styles/styleHelpers";
import { Button, Icon } from "@netmedi/design-system";
import ProgressBar from "common/components/ProgressBar";
import rtl from "styled-components-rtl";

import {
  colors,
  mediaQueries,
} from "@netmedi/design-system/dist/styles/variables";

import {
  FormHelp,
  SubmitButton as ProfileSubmitButton,
} from "../Profile/Profile.styles";
import {
  mediaMax,
  mediaMin,
} from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";
import { ErrorMsg } from "common/components/Form/FormField.styles";

export const BodyStyle = createGlobalStyle`
  body {
    font-size: ${rem(16)};
  }
`;

export const InvitePageContainer = styled.div`
  max-width: ${rem(350)};
  margin: 0 auto;
`;

export const FormContainer = styled.form`
  clear: both;
  max-width: 400px;
  margin: 0 auto;
  input {
    margin-bottom: 0;
    padding: 0.5rem;
    border-color: ${colors.gray500};
  }
  label {
    span {
      ${rtl`padding-left: 0;`}
      line-height: 1.5;
    }
  }
  span[data-form-field-label] {
    line-height: 2.4;
  }
  ${ErrorMsg} {
    ${rtl`margin-left: 0;`}
  }
`;

export const InvitePageTitle = styled.h1`
  font-weight: 400;
  font-size: ${rem(24)};
  line-height: ${rem(32)};
  margin: 0;
`;

export const InviteTitle = styled(InvitePageTitle)`
  padding: 0.5rem 0;
`;

export const InviteFormHelp = styled(FormHelp)`
  padding: 0.5em 0;

  ${mediaMin(mediaQueries.small)} {
    width: 100%;
  }
`;

export const RequiredFields = styled.p`
  padding: 0;
  margin-bottom: ${rem(24)};
  color: ${colors.gray700};
`;

export const SubmitButton = styled(ProfileSubmitButton)`
  font-size: ${rem(16)};
`;

export const ConsentPage = styled.div`
  display: flex;
  flex-direction: column;
  ${mediaMax(mediaQueries.small)} {
    flex-grow: 1;
  }
  justify-content: flex-end;

  h1 {
    padding: 0 ${rem(16)};
    font-size: ${rem(24)};
    text-align: center;
  }
`;

export const ConsentInputsContainer = styled.div`
  margin-top: ${rem(24)};
  margin-bottom: ${rem(24)};
  label {
    ${rtl`text-align: left;`}
  }
`;

export const ConsentTitle = styled(InvitePageTitle)`
  margin-bottom: ${rem(24)};
`;

export const ConsentIcon = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: ${rem(16)};
  margin-bottom: ${rem(16)};
`;

export const ConsentError = styled.div`
  font-size: ${rem(14)};
  color: ${colors.red700};
  font-weight: 400;
  line-height: 1.25;
  ${rtl`text-align: left;`}
`;

export const RadioButtonContainer = styled.label<{ disabled?: boolean }>`
  ${({ disabled }) => (disabled ? "cursor: not-allowed;" : "")}
  label {
    font-size: ${rem(16)};
    margin-bottom: 0;
  }
  & + & {
    margin-top: ${rem(16)};
  }
`;

export const WizardProgressBar = styled(ProgressBar)`
  margin-bottom: ${rem(36)};
`;

export const WizardPage = styled.section<{ hidden?: boolean }>`
  display: ${props => (props.hidden ? "none" : "flex")};
  min-height: calc(100vh - 50px - 40px - 20px - 50px - 24px);
  flex-direction: column;
  ${padding("xxs")}
  padding-bottom: ${rem(16)};
`;

export const WizardButtonContainer = styled.div`
  padding-top: ${rem(16)};
  display: flex;
  flex-direction: row;

  button {
    margin: 0;
    flex-grow: 1;
  }
`;

export const ButtonIcon = styled(Icon)`
  position: relative;
  top: -1px;
`;

export const WizardBackButton = styled(Button)<{ visible?: boolean }>`
  visibility: ${props => (props.visible ? "visible" : "hidden")};
  font-size: ${rem(16)};
  line-height: ${rem(16)};
  padding: 0.75em 0;
`;

export const ShowPasswordWrapper = styled.div`
  padding: 0.5em;
`;

export const SelectDomainsDescription = styled.div`
  ${rtl`padding-left: 0.2rem;`}
`;
