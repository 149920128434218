import React, { useEffect, useRef } from "react";
import dayjs from "dayjs";
import { get, toString as _toString, identity } from "lodash";
import { connect } from "react-redux";
import { dropdown } from "common/utils/tooltip";
import { elDispatchEvent, tap } from "common/utils/general";
import { Button } from "@netmedi/design-system";
import { FormattedMessage } from "react-intl";
import AssignDropdown from "./AssignDropdown";
import { assign, markDone } from "hcp/actions/staff";
import {
  TaggerContainer,
  Actions,
  TaggerButtonIcon,
  Status,
  StatusIndicator,
  StatusText,
} from "./Tagger.styles";
import { RootState } from "store";
import { User } from "common/models/user";
import { isStudyMonitor } from "common/utils/user";

// Use the issue given by react-rails or the updated one from store if available
const mapStateToProps = (
  state: RootState,
  props: { user?: User; issue: Props["issue"] | { id: string } },
) => ({
  user: props.user || state.user,
  issue:
    get(state, `feed.updatedIssues[${get(props, "issue.id")}]`) || props.issue,
});

const issueStateText = (issue: Props["issue"]) => {
  switch (issue.state) {
    case "assigned":
      if (issue.assigned_role) {
        return {
          id: `issue.assigned_to_role`,
          values: {
            assignee: issue.assigned_to_text,
            time: dayjs(issue.updated_at).format("LLL"),
          },
        };
      } else if (issue.assigned_team) {
        return {
          id: `issue.assigned_to_team`,
          values: {
            assignee: issue.assigned_to_text,
            time: dayjs(issue.updated_at).format("LLL"),
          },
        };
      }
      return {
        id: `issue.assigned_to_person`,
        values: {
          assignee: issue.assigned_to_text,
          time: dayjs(issue.updated_at).format("LLL"),
        },
      };
    case "unassigned":
      return { id: "issue.noone_assigned" };
    case "done":
      return {
        id: "issue.done_by",
        values: {
          assignee: issue.assigned_to_text,
          time: dayjs(issue.updated_at).format("LLL"),
        },
      };
  }
};

const assigneeIdStringCheck = (assigneeId: any) => {
  if (typeof assigneeId === "number") {
    return assigneeId.toString();
  }
  return assigneeId;
};

const buildButtons = (props: Props, e?: any) => {
  const { assign, markDone, user, issue, onAction = identity } = props;
  const buttonsVisible = !isStudyMonitor(user);
  const dropdownTarget = e && e.currentTarget;

  // Json api renders id only
  const assigneeId = assigneeIdStringCheck(
    _toString(get(issue, "assignee.id")) || issue.assignee,
  );

  const assignedToMe =
    assigneeId === user.id ||
    issue.assigned_role === user.role ||
    (issue.assigned_to_text !== undefined && issue.state === "assigned");

  const buttons = {
    assign: {
      content: "assign",
      visible: buttonsVisible,
      props: {
        onClick: (e: any) => {
          // Internally React actually catches events with a single listener in document;
          // so in order to stop the previous tooltip click listener from firing we need
          // to access the native event. This is done to prevent the tooltip from closing
          // and instead just re-render.
          e.nativeEvent.stopImmediatePropagation();
          dropdown({
            content: <AssignDropdown {...{ issue, onAssign: onAction }} />,
            className: "UserDropdown_userDropdown",
            target: dropdownTarget || e.currentTarget,
          });
        },
      },
    },
    take: {
      content: "take",
      visible: buttonsVisible && (!assignedToMe || issue.state === "done"),
      props: {
        primary: issue.state === "unassigned",
        onClick: () =>
          assign(issue, { assignee_id: user.id }).then(tap(onAction)),
      },
    },
    mark_done: {
      content: "mark_done",
      visible:
        buttonsVisible &&
        (issue.state === "unassigned" ||
          (issue.state !== "done" && assignedToMe)),
      props: {
        primary: assignedToMe && issue.state !== "done",
        onClick: () => markDone(issue).then(tap(onAction)),
      },
    },
  };

  return buttons;
};

export type TaggerIssue = {
  state: "unassigned" | "assigned" | "done";
  assigned_role?: string;
  assigned_team?: string;
  assigned_to_text?: string;
  assignee?: string;
  updated_at: string;
};

type Props = {
  assigned?: string;
  user: User;
  assign: (...args: any[]) => any;
  markDone: (...args: any[]) => any;
  issue: TaggerIssue;
  el?: string;
  compact?: boolean;
  onAction?: (...args: any[]) => any;
};

const Tagger = (props: Props) => {
  const statusRef = useRef<any>(null);

  useEffect(() => {
    return () => {
      if (props.el) {
        const el = document.getElementById(props.el);
        if (el) elDispatchEvent(el, "issueChange");
      }
    };
  }, [props.issue.state]);

  const { issue, compact = false } = props;
  const buttons = buildButtons(props);

  return (
    <TaggerContainer $compact={compact}>
      <Status ref={statusRef}>
        <StatusIndicator $status={issue.state} />
        <StatusText>
          <FormattedMessage {...issueStateText(issue)} />
        </StatusText>
      </Status>
      <Actions $compact={compact}>
        <div>
          {buttons.assign.visible ? (
            <Button
              key={buttons.assign.content}
              type="accent"
              size="small"
              {...buttons.assign.props}
            >
              <FormattedMessage id={`issue.${buttons.assign.content}`} />
              <TaggerButtonIcon name="arrowhead_down_16px" size="small" />
            </Button>
          ) : null}
          {buttons.take.visible ? (
            <Button
              key={buttons.take.content}
              type="accent"
              size="small"
              {...buttons.take.props}
            >
              <FormattedMessage id={`issue.${buttons.take.content}`} />
            </Button>
          ) : null}
        </div>
        <div>
          {buttons.mark_done.visible ? (
            <Button
              key={buttons.mark_done.content}
              type="accent"
              size="small"
              {...buttons.mark_done.props}
            >
              <FormattedMessage id={`issue.${buttons.mark_done.content}`} />
              <TaggerButtonIcon name="done_circle_16px" size="small" />
            </Button>
          ) : null}
        </div>
      </Actions>
    </TaggerContainer>
  );
};

export default connect(mapStateToProps, { assign, markDone })(Tagger);
