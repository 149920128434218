import styled from "styled-components";
import rtl from "styled-components-rtl";
import { mediaQueries } from "@netmedi/design-system/dist/styles/variables";
import { mediaMax } from "@netmedi/design-system/dist/styles/mixins/mediaQueryMixins";
import rem from "@netmedi/design-system/dist/styles/styleHelpers";

export const SlideContent = styled.div`
  margin-top: 2rem;
  height: 400px;
  position: relative;
  overflow: hidden;
  padding: 5px;
`;

export const SlideHeading = styled.div`
  min-height: 50px;

  p {
    font-size: ${rem(18)};
    line-height: ${rem(22)};
  }
`;

export const SlideContainer = styled.div`
  text-align: center;
  animation: fadeIn ease 1s;
`;

export const BackgroundImage = styled.div`
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  background-position: center;
`;

export const SlideImage = styled.img(rtl`
  position: absolute;
  animation: fadeIn ease 4s;
  top: 50%;
  width: 80%;
  transform: translate(-50%, -50%);
  left: 50%;

  ${mediaMax(mediaQueries.small)} {
    width: 50%;
    transform: translate(-50%, -20%);
  }
`);

export const ProgressBarContainer = styled.div`
  margin: auto;
  width: 80%;
  margin-top: 50px;
  margin-bottom: 50px;

  ${mediaMax(mediaQueries.small)} {
    margin-top: 20px;
  }
`;
