import { EndpointParams } from "../api2.types";
import { SiteSettings } from "../../holvikaari";
import { del, endpoints, get, post, put } from "../../api";
import { Absence } from "hcp/components/Absences/useAbsences";

const endpointUrls = (hcp_workflow_api_url: string) => {
  const apiService = hcp_workflow_api_url ? "hcp-workflow" : "holvikaari";
  const baseUrl = () =>
    hcp_workflow_api_url
      ? `${hcp_workflow_api_url}/absences/${SiteSettings.site}`
      : endpoints.absences.index;

  return {
    listAbsences:
      apiService === "hcp-workflow" ? `${baseUrl()}` : endpoints.absences.index,
    singleAbsence: (id: number) =>
      apiService === "hcp-workflow"
        ? `${baseUrl()}/${id}`
        : endpoints.absences.show(id.toString()),
    createAbsence:
      apiService === "hcp-workflow"
        ? `${baseUrl()}`
        : endpoints.absences.create,
    updateAbsence: (id: number) =>
      apiService === "hcp-workflow"
        ? `${baseUrl()}/${id}`
        : endpoints.absences.update(id.toString()),
    deleteAbsence: (id: number) =>
      apiService === "hcp-workflow"
        ? `${baseUrl()}/${id}`
        : endpoints.absences.delete(id.toString()),
  };
};

const absenceClient = ({
  hcp_workflow_api_url,
}: Record<string, string> = {}) => {
  const urls = () => endpointUrls(hcp_workflow_api_url);

  return {
    listAbsences: () => ({ get: () => get<Absence[]>(urls().listAbsences) }),
    singleAbsence: ({ id }: EndpointParams["singleAbsence"]) => ({
      get: () => get<Absence>(urls().singleAbsence(id)),
    }),
    createAbsence: ({ absence }: EndpointParams["createAbsence"]) => ({
      post: () => post<Absence>(urls().createAbsence, absence),
    }),
    updateAbsence: ({ id, absence }: EndpointParams["updateAbsence"]) => ({
      put: () => put<Absence>(urls().updateAbsence(id), absence),
    }),
    deleteAbsence: ({ id }: EndpointParams["deleteAbsence"]) => ({
      delete: () => del(urls().deleteAbsence(id)),
    }),
  };
};

export default absenceClient;
