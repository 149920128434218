import { User } from "common/models/user";
import { reloadPage } from "hcp/actions/utils";
import { endpoints, put } from "common/utils/api";

export const showProfileSelectorToUser = (user: User) =>
  profileSelectorShowable(user) &&
  !wasProfileSelected() &&
  !user.remember_selected_profile;

export const profileSelectorShowable = (user: User) =>
  user.show_profile_selector;

export const profileWasSelected = () =>
  sessionStorage.setItem("profileWasSelected", "");

export const wasProfileSelected = () =>
  sessionStorage.getItem("profileWasSelected") !== null;

const resetProfileWasSelected = () =>
  sessionStorage.removeItem("profileWasSelected");

export const resetProfileSelectorCallback = async () => {
  await put(endpoints.caregivees.resetRememberMe);
  resetProfileWasSelected();
  reloadPage();
};
