import React from "react";
import { zip, flatten, compact } from "lodash";
import { tooltip } from "common/utils/tooltip";
import { primaryColor } from "@netmedi/design-system/dist/styles/variables";
import { Extension } from "./Markdown.types";

export const ANCHOR_REGEX = /<a[^>]+>.+?<\/a>/g;
export const DELIMITER_TAG = "?tooltip-content?";
export const CALLBACK_NAME = "__Serena_tooltipCb";
const CONTENT_DELIMITER = `<p>${DELIMITER_TAG}</p>`;

const b64encode = (str: string) => btoa(unescape(encodeURIComponent(str)));
const b64decode = (str: string) => decodeURIComponent(escape(atob(str)));

declare global {
  interface Window {
    [CALLBACK_NAME]: (event: HTMLAnchorElement, content: string) => void;
  }
}

window[CALLBACK_NAME] = (event, content) => {
  const __html = b64decode(content);
  tooltip({
    content: <div dangerouslySetInnerHTML={{ __html }} />,
    target: event.target,
    position: "bottom",
    type: "auto",
  });
};

const onClickJS = (content: string, cb_name: string) => {
  const b64 = b64encode(content);
  return `event.stopPropagation();${cb_name}(event, '${b64}')`;
};

const constructLink = (
  text: string,
  i: number,
  content: string,
  cb_name: string,
) =>
  [
    '<a tabindex="0" href="#" ',
    `onclick="${onClickJS(content, cb_name)}" `,
    `style="text-decoration:underline;color:${primaryColor}" `,
    ">",
    text,
    "</a>",
  ].join("");

export const renderTooltipLinks = (
  content: string,
  anchor_regex: RegExp,
  content_delimiter: string,
  cb_name: string,
) => {
  const matchFirstLink = content?.match(anchor_regex);

  let contentAfterFirstLink = "";

  if (matchFirstLink) {
    const anchorEndIndex =
      content?.indexOf(matchFirstLink[0]) + matchFirstLink[0].length;
    contentAfterFirstLink = content?.substring(anchorEndIndex);
  }

  const nonLinkContent = content?.split(anchor_regex);

  const linkContent = content
    .match(anchor_regex)
    ?.map(m => m.match(/>(.+?)</)?.[1]?.trim());

  const tooltipContent = contentAfterFirstLink?.split(content_delimiter);

  const linkElements = linkContent?.map((text, i) =>
    constructLink(
      text as string,
      i,
      tooltipContent[i + 1]?.trim() as string,
      cb_name,
    ),
  );

  const __html = compact(flatten(zip(nonLinkContent, linkElements)))
    .join("")
    .split(content_delimiter)[0];

  return <div dangerouslySetInnerHTML={{ __html }} />;
};

const extension: Extension = {
  name: "tooltip",
  render: content =>
    renderTooltipLinks(content, ANCHOR_REGEX, CONTENT_DELIMITER, CALLBACK_NAME),
  enableInnerMarkdown: true,
};

export default extension;
